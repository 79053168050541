import { mergeClassNames } from 'utils/helpers';

const ALERT_CLASS = {
  success: 'border bg-green-100 border-green-600 text-green-600',
  danger: 'border bg-red-100 border-red-600 text-red-600',
  warning: 'border bg-orange-100 border-orange-600 text-orange-600',
  info: 'border bg-gray-100 border-gray-500 text-gray-600'
};

type AlertVariants = 'success' | 'danger' | 'warning' | 'info';

interface IProps {
  variant: AlertVariants;
  children: React.ReactNode;
}

const Alert = ({ variant, children }: IProps) => (
  <div className={mergeClassNames('px-6 py-4 my-4 rounded', ALERT_CLASS[variant])}>
    {children}
  </div>
);

export default Alert;
