import React, { createContext, useState, useEffect, useContext, useRef } from 'react';

export interface IModalContext {
  openModalWithContent: (content: React.ReactNode, options?: IModalOptions) => void;
  closeModal: () => void;
  isModalClosed: boolean;
}

export interface IModalOptions {
  closeable?: boolean;
}

const DEFAULT_MODAL_OPTIONS: Required<IModalOptions> = {
  closeable: true
};

const ModalContext = createContext<IModalContext>({} as IModalContext);
ModalContext.displayName = 'ModalContext';

const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<React.ReactNode>(null);
  const [modalOptions, setModalOptions] = useState<IModalOptions>(DEFAULT_MODAL_OPTIONS);
  const containerRef = useRef<HTMLDivElement>(null);

  const closeModal = () => {
    setOpen(false);
    setModalOptions(DEFAULT_MODAL_OPTIONS);
  };

  const handleOutsideClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    if (event.target === containerRef?.current && modalOptions.closeable) closeModal();
  };

  useEffect(() => {
    const escapeListener = (event: KeyboardEvent) => {
      if (event.key === 'Escape') closeModal();
    };
    if (modalOptions?.closeable) {
      document.addEventListener('keydown', escapeListener);
    }
    return () => document.removeEventListener('keydown', escapeListener);
  }, [modalOptions?.closeable]);

  useEffect(() => {
    if (open) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
      setTimeout(() => setModalContent(null), 300);
    }
  }, [open]);

  const openModalWithContent = (content: React.ReactNode, options?: IModalOptions) => {
    setModalContent(content);
    setModalOptions(options || DEFAULT_MODAL_OPTIONS);
    setOpen(true);
  };

  return (
    <ModalContext.Provider
      value={{
        openModalWithContent,
        closeModal,
        isModalClosed: !open
      }}
    >
      <div
        className={`fixed top-0 left-0 bottom-0 right-0 bg-darkBlue-100 bg-opacity-60 transition-all duration-500 z-10
          ${open ? 'opacity-100 visible' : 'opacity-0 invisible'}
        flex items-center justify-center`}
        onClick={handleOutsideClick}
        ref={containerRef}
      >
        {open && modalContent ? modalContent : null}
      </div>
      <React.Suspense fallback={<p>Loading...</p>}>{children}</React.Suspense>
    </ModalContext.Provider>
  );
};

const useModal = () => {
  const context = useContext(ModalContext);

  if (context === undefined) {
    throw new Error(`useModal must be used within a ModalProvider`);
  }
  return context;
};

export { ModalProvider, useModal };
