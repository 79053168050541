import { IconProp } from 'constants/icons';

const LogoutIcon = (props: IconProp) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18" {...props}>
    <path
      fill="currentColor"
      d="M7.09 12.59 8.5 14l5-5-5-5-1.41 1.41L9.67 8H0v2h9.67l-2.58 2.59ZM16 0H2a2 2 0 0 0-2 2v4h2V2h14v14H2v-4H0v4a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2Z"
    />
  </svg>
);

export default LogoutIcon;
