import { useMutation } from '@tanstack/react-query';
import { API_ROUTES } from 'constants/routes';
import { useClient } from 'contexts/auth-context';

export interface ICikkExportRequest {
  cats: string[];
  rate: number;
  basedOnLast: boolean;
  gross: boolean;
  newOnly: boolean;
  partner: boolean;
}

const useCikkExport = () => {
  const client = useClient();

  return useMutation((request: ICikkExportRequest) =>
    client<Blob, ICikkExportRequest>(API_ROUTES.PRODUCTS_EXPORT, {
      data: request,
      method: 'post',
      responseType: 'blob'
    })
  );
};

const useCikkPrint = () => {
  const client = useClient();

  return useMutation((request: ICikkExportRequest) =>
    client<Blob, ICikkExportRequest>(API_ROUTES.PRODUCTS_PRINT, {
      data: request,
      method: 'post',
      responseType: 'blob'
    })
  );
};

export interface IPartnerExportRequest {
  cats: string[];
}

const usePartnerExport = () => {
  const client = useClient();

  return useMutation((request: IPartnerExportRequest) =>
    client<Blob, IPartnerExportRequest>(API_ROUTES.PARTNERS_EXPORT, {
      data: request,
      method: 'post',
      responseType: 'blob'
    })
  );
};

const usePartnerPrint = () => {
  const client = useClient();

  return useMutation((request: IPartnerExportRequest) =>
    client<Blob, IPartnerExportRequest>(API_ROUTES.PARTNERS_PRINT, {
      data: request,
      method: 'post',
      responseType: 'blob'
    })
  );
};

export { useCikkExport, useCikkPrint, usePartnerExport, usePartnerPrint };
