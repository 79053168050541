import { yupResolver } from '@hookform/resolvers/yup';
import { usePartnerExport, usePartnerPrint } from 'api/export';
import { useGetTags } from 'api/tags';
import { SelectOptions } from 'components/ui/Form/controls/Select/Select';
import { WithFormError } from 'components/ui/Form/Form';
import { VALIDATION_MESSAGES } from 'constants/validation';
import { useOrg } from 'contexts/org-context';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

export interface IPartnerExportForm {
  categories: string[];
}

const validationSchema = Yup.object().shape({
  categories: Yup.array().of(Yup.string()).min(1, VALIDATION_MESSAGES.required)
});

export type ExportVariant = 'export' | 'print';
const usePartnerExportPage = () => {
  const { mutate: partnerExportMutation } = usePartnerExport();
  const { mutate: partnerPrintMutation } = usePartnerPrint();
  const { data: tags } = useGetTags();
  const { activeSubscription } = useOrg();

  const tagOptions = useMemo<SelectOptions<string>[]>(() => {
    const result = [
      {
        value: '',
        label: 'Kategorizálatlan'
      }
    ];
    tags?.forEach(({ id, name }) => {
      result.push({
        label: name,
        value: name
      });
    });
    return result;
  }, [tags]);

  const formMethods = useForm<WithFormError<IPartnerExportForm>>({
    defaultValues: {
      categories: []
    },
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = (variant: ExportVariant) => {
    formMethods.handleSubmit((data) => {
      if (variant === 'export') {
        return partnerExportMutation(
          {
            cats: data.categories
          },
          {
            onSuccess: (response) => {
              if (response && response.size > 0) {
                const today = format(new Date(), 'yyyyMMddHHmmss');
                const url = window.URL.createObjectURL(new Blob([response as Blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${today}_arvalt_partnerlista.xlsx`);
                document.body.appendChild(link);
                link.click();
              }
            }
          }
        );
      }
      return partnerPrintMutation(
        {
          cats: data.categories
        },
        {
          onSuccess: (response) => {
            if (response && response.size > 0) {
              const today = format(new Date(), 'yyyyMMddHHmmss');
              const url = window.URL.createObjectURL(new Blob([response as Blob]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `${today}_arvalt_partnerlista.pdf`);
              document.body.appendChild(link);
              link.click();
            }
          }
        }
      );
    })();
  };

  const susbscribedToExport = activeSubscription?.extra?.split(', ').includes('PARTNERS');

  return {
    tagOptions,
    formMethods,
    onSubmit,
    susbscribedToExport
  };
};

export default usePartnerExportPage;
