import { IconProp } from 'constants/icons';

const ChevronDown = (props: IconProp) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 24" {...props}>
    <path
      fill="currentColor"
      d="m8.406 9.29 3.88 3.88 3.88-3.88a.996.996 0 1 1 1.41 1.41l-4.59 4.59a.996.996 0 0 1-1.41 0l-4.59-4.59a.996.996 0 0 1 0-1.41c.39-.38 1.03-.39 1.42 0Z"
    />
  </svg>
);

export default ChevronDown;
