import { IconProp } from 'constants/icons';

const InvoicesIcon = (props: IconProp) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20" {...props}>
    <path
      fill="currentColor"
      d="M16.5 1.5 15 0l-1.5 1.5L12 0l-1.5 1.5L9 0 7.5 1.5 6 0 4.5 1.5 3 0 1.5 1.5 0 0v20l1.5-1.5L3 20l1.5-1.5L6 20l1.5-1.5L9 20l1.5-1.5L12 20l1.5-1.5L15 20l1.5-1.5L18 20V0l-1.5 1.5ZM16 17.09H2V2.91h14v14.18ZM3 13h12v2H3v-2Zm0-4h12v2H3V9Zm0-4h12v2H3V5Z"
    />
  </svg>
);

export default InvoicesIcon;
