import Box from 'components/ui/Box/Box';
import Button from 'components/ui/Button/Button';
import Switch from 'components/ui/Form/controls/Switch/Switch';
import Form from 'components/ui/Form/Form';
import PackageCard from 'components/ui/PackageSelector/components/PackageCard/PackageCard';
import usePackageSelector from 'components/ui/PackageSelector/usePackageSelector';
import { ExtraType, PackageType, PaymentFrequency } from 'types/subscription';

interface IProps {
  onSubmit: ({
    pckg,
    payFreq,
    extras
  }: {
    pckg: PackageType;
    payFreq: PaymentFrequency;
    extras: ExtraType[];
  }) => void;
}

const PackageSelector = ({ onSubmit }: IProps) => {
  const { packages, extras, formMethods, payFreq, selectedExtras } = usePackageSelector();

  const getSelectedExtrasForPackage = (pckg: PackageType) => {
    const result: ExtraType[] = [];
    Object.keys(selectedExtras[pckg]).forEach((v) => {
      if (selectedExtras[pckg][v as ExtraType] === true) {
        result.push(v as ExtraType);
      }
    });
    return result;
  };

  return (
    <div className="w-full md:w-auto">
      <Form formMethods={formMethods} onSubmit={() => false}>
        <div className="flex flex-col">
          <div className="flex flex-row justify-center mb-6">
            <Switch
              name="payFreq"
              onLabel="Éves előfizetés"
              offLabel="Havi előfizetés"
              onValue="YEAR"
              offValue="MONTH"
              labelClassName="bg-transparent"
            />
          </div>
          <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-2 px-8 md:px-4 xl:px-16">
            {packages?.map((pckg) => (
              <Box key={pckg.id} className="border border-lightBlue-5">
                <PackageCard pckg={pckg} payFreq={payFreq} extras={extras} />
                <Button
                  variant="default"
                  color="lightBlue"
                  onClick={() =>
                    onSubmit({
                      pckg: pckg.code,
                      payFreq,
                      extras: getSelectedExtrasForPackage(pckg.code)
                    })
                  }
                  className="mt-8"
                >
                  Előfizetek
                </Button>
              </Box>
            ))}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default PackageSelector;
