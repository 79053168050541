import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { IDefaultControlProps } from '../../Form';
import BaseControl from '../BaseControl/BaseControl';

export interface INumberInputProps extends IDefaultControlProps {
  format?: string;
  mask?: string;
}

const NumberInput = (props: INumberInputProps) => {
  const { name, label, placeholder, disabled, className, group, format, mask } = props;
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <BaseControl name={name} label={label} className={className} group={group}>
          <NumberFormat
            {...field}
            disabled={disabled}
            placeholder={placeholder}
            format={format}
            mask={mask}
          />
        </BaseControl>
      )}
    />
  );
};

export default NumberInput;
