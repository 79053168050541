import PageTitle from 'components/ui/PageTitle/PageTitle';
import Button from 'components/ui/Button/Button';
import Form from 'components/ui/Form/Form';
import Select from 'components/ui/Form/controls/Select/Select';
import useCikkExportPage, {
  ICikkExportForm
} from 'components/pages/CikkExportPage/useCikkExportPage';
import Checkbox from 'components/ui/Form/controls/Checkbox/Checkbox';
import RadioButton from 'components/ui/Form/controls/RadioButton/RadioButton';
import NumberInput from 'components/ui/Form/controls/NumberInput/NumberInput';
import SectionTitle from 'components/ui/SectionTitle/SectionTitle';
import Box from 'components/ui/Box/Box';
import Alert from 'components/ui/Alert/Alert';

const CikkExportPage = () => {
  const { tagOptions, formMethods, onSubmit, susbscribedToExport } = useCikkExportPage();

  return (
    <>
      <PageTitle>Cikkek exportálása</PageTitle>
      {!susbscribedToExport ? (
        <Alert variant="warning">A cikk export funkcióra nincs előfizetés!</Alert>
      ) : null}
      <Form<ICikkExportForm> formMethods={formMethods} onSubmit={() => {}}>
        <Box>
          <SectionTitle>Igény szerint válasszon kategóriát</SectionTitle>
          <Select
            name="cats"
            label={'Kategóriák'}
            placeholder="Kategóriák.."
            options={tagOptions}
            isMulti
            disabled={!susbscribedToExport}
          />
        </Box>
        <Box>
          <SectionTitle>Mi legyen az árrés?</SectionTitle>
          <div className="flex md:space-x-8 flex-col md:flex-row">
            <NumberInput
              name={'rate'}
              label={'Árrés%'}
              placeholder="pl. 10"
              className="w-80"
              disabled={!susbscribedToExport}
            />
            <RadioButton
              className="md:mt-8"
              name={'basedOnLast'}
              label={'Utolsó beszerzési ár alapján'}
              value={'true'}
              disabled={!susbscribedToExport}
            />
            <RadioButton
              className="md:mt-8"
              name={'basedOnLast'}
              label={'Átlag ár alapján (utolsó 3 hónap)'}
              value={'false'}
              disabled={!susbscribedToExport}
            />
          </div>
        </Box>
        <Box>
          <SectionTitle>Nyomtatás formája</SectionTitle>
          <div className="flex flex-col md:flex-row md:space-x-8 mt-6 mb-4">
            <RadioButton
              name={'gross'}
              value={'false'}
              label={'Nettó áron'}
              disabled={!susbscribedToExport}
            />
            <RadioButton
              name={'gross'}
              value={'true'}
              label={'Bruttó áron'}
              disabled={!susbscribedToExport}
            />
          </div>
          <div className="flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-8 mt-6 mb-4">
            <Checkbox
              name={'newOnly'}
              label={'Csak az árréssel növelt (átadási) árat mutassa'}
              disabled={!susbscribedToExport}
            />
            <Checkbox
              name={'partner'}
              label={'Partnernév feltüntetése'}
              disabled={!susbscribedToExport}
            />
          </div>
          <div className="flex flex-col-reverse md:flex-row md:gap-2.5 mt-6">
            <Button
              color="lightBlue"
              variant="outline"
              onClick={() => onSubmit('print')}
              className="w-42 mt-4"
              disabled={!susbscribedToExport}
            >
              Nyomtatás
            </Button>
            <Button
              color="lightBlue"
              onClick={() => onSubmit('export')}
              className="w-42 mt-4"
              disabled={!susbscribedToExport}
            >
              Exportálás
            </Button>
          </div>
        </Box>
      </Form>
    </>
  );
};

export default CikkExportPage;
