import { useEffect } from 'react';
import { useNotification } from 'contexts/notification-context';
import { useCountdown } from 'hooks/useCountdown';
import { mergeClassNames } from 'utils/helpers';
import CloseIcon from 'components/ui/Icons/CloseIcon/CloseIcon';

export interface INotification {
  message: string;
  type: 'success' | 'error' | 'warning' | 'info';
  id: string;
}

export interface INotificationProps {
  data: INotification;
}

// seconds to show the notification
const TIME_LIMIT = 10;

const NOTIFICATION_CLASS = {
  success: {
    container: 'bg-green-100 border-green-400 text-green-800',
    progressBar: 'bg-green-400'
  },
  error: {
    container: 'bg-red-100 border-red-400 text-red-800',
    progressBar: 'bg-red-400'
  },
  warning: {
    container: 'bg-orange-100 border-orange-400 text-orange-800',
    progressBar: 'bg-orange-400'
  },
  info: {
    container: 'bg-blue-100 border-blue-400 text-blue-800',
    progressBar: 'bg-blue-400'
  }
} as const;

const Notification = ({ data }: INotificationProps) => {
  const { timeLeft, stopCounter } = useCountdown(TIME_LIMIT, true);
  const { removeNotification } = useNotification();

  useEffect(() => {
    if (timeLeft <= 0) {
      removeNotification(data.id);
    }
  }, [timeLeft, removeNotification, data]);

  useEffect(() => () => stopCounter(), [stopCounter]);

  return (
    <div
      className={mergeClassNames(
        'm-2 rounded overflow-hidden border min-w-[400px]',
        NOTIFICATION_CLASS[data.type].container
      )}
    >
      <div className="w-full p-2 pb-1 flex justify-between items-center">
        <p>{data.message}</p>
        <button className="ml-3 w-4" onClick={() => removeNotification(data.id)}>
          <CloseIcon />
        </button>
      </div>
      <div
        className={mergeClassNames(
          'h-1.5 rounded-r-full transition-[width] ease-linear duration-1000',
          NOTIFICATION_CLASS[data.type].progressBar
        )}
        style={{ width: `${(100 / (TIME_LIMIT - 1)) * (timeLeft - 1)}%` }}
      ></div>
    </div>
  );
};

export default Notification;
