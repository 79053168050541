import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API_CACHE } from 'constants/cache';
import { SUCCESS_MESSAGES } from 'constants/messages';
import { API_ROUTES } from 'constants/routes';
import { useClient } from 'contexts/auth-context';
import { useNotification } from 'contexts/notification-context';
import {
  ExtraType,
  PackageType,
  PaymentFrequency,
  PaymentType,
  Subscription
} from 'types/subscription';

const useGetActiveSubscription = (orgId: number, token: string | null) => {
  const client = useClient();

  return useQuery(
    [API_CACHE.ACTIVE_SUBSCRIPTION, { orgId, token }],
    () => client<Subscription>(API_ROUTES.GET_ACTIVE_SUBSCRIPTION, { method: 'get' }),
    {
      enabled: !!token && !!orgId && orgId !== 0
    }
  );
};

export interface IUseUpgradeSubscriptionRequest {
  subType: PackageType;
  extra: ExtraType[];
  // payFreq: PaymentFrequency;
}

const useUpgradeSubscription = () => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation<string, unknown, IUseUpgradeSubscriptionRequest>(
    (request) =>
      client<string, IUseUpgradeSubscriptionRequest>(API_ROUTES.UPGRADE_SUBSCRIPTION, {
        data: request,
        method: 'post'
      }),
    {
      onSuccess: () => queryClient.invalidateQueries([API_CACHE.ACTIVE_SUBSCRIPTION])
    }
  );
};

export interface IUseDeleteSubscriptionRequest {
  password: string;
  immediately: boolean;
}

const useDeleteSubscription = () => {
  const client = useClient();

  return useMutation((request: IUseDeleteSubscriptionRequest) =>
    client(API_ROUTES.DELETE_SUBSCRIPTION, {
      data: request,
      method: 'post'
    })
  );
};

const useRenewSubscription = () => {
  const client = useClient();

  return useMutation(() =>
    client<string>(API_ROUTES.RENEW_SUBSCRIPTION, {
      method: 'post'
    })
  );
};

export interface IUseRegisterSubscriptionRequest {
  subType: PackageType;
  payFreq: PaymentFrequency;
  paymentType: PaymentType;
  extra: ExtraType[];
}

const useRegisterSubscription = () => {
  const client = useClient();

  return useMutation<string, unknown, IUseRegisterSubscriptionRequest>((request) =>
    client(API_ROUTES.REGISTER_SUBSCRIPTION, {
      method: 'post',
      data: request
    })
  );
};

const useRemoveRecurring = () => {
  const client = useClient();
  const queryClient = useQueryClient();
  const { addNotification } = useNotification();

  return useMutation(() => client(API_ROUTES.REMOVE_RECURRING, { method: 'post' }), {
    onSuccess: () => {
      addNotification({ type: 'success', message: SUCCESS_MESSAGES.DELETE_RECURRING });
      queryClient.invalidateQueries([API_CACHE.ORG]);
      queryClient.invalidateQueries([API_CACHE.ACTIVE_SUBSCRIPTION]);
    }
  });
};

export {
  useGetActiveSubscription,
  useUpgradeSubscription,
  useDeleteSubscription,
  useRenewSubscription,
  useRegisterSubscription,
  useRemoveRecurring
};
