import { INotification } from 'components/ui/Notification/Notification';
import NotificationContainer from 'components/ui/Notification/NotificationContainer';
import { ERROR_MESSAGES } from 'constants/messages';
import { createContext, useCallback, useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export interface INotificationContext {
  addNotification: (notification: Omit<INotification, 'id'>) => void;
  removeNotification: (id: string) => void;
  notifications: INotification[];
}

const NotificationContext = createContext<INotificationContext | undefined>(undefined);
NotificationContext.displayName = 'NotificationContext';

const NotificationProvider = ({ children }: { children: React.ReactNode }) => {
  const [notifications, setNotifications] = useState<INotification[]>([]);

  const addNotification = useCallback((notification: Omit<INotification, 'id'>) => {
    const message = ERROR_MESSAGES[notification.message] || notification.message;
    setNotifications((prev) => [...prev, { ...notification, message, id: uuidv4() }]);
  }, []);

  const removeNotification = useCallback((id: string) => {
    setNotifications((prev) => prev.filter((notification) => notification.id !== id));
  }, []);

  return (
    <NotificationContext.Provider
      value={{
        addNotification,
        removeNotification,
        notifications
      }}
    >
      <NotificationContainer notifications={notifications} />
      {children}
    </NotificationContext.Provider>
  );
};

const useNotification = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};

export { NotificationProvider, useNotification };
