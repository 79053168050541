import { IDefaultControlProps } from 'components/ui/Form/Form';
import { useFormContext } from 'react-hook-form';

const Checkbox = (props: IDefaultControlProps) => {
  const { name, label, placeholder, disabled, defaultValue } = props;
  const { register } = useFormContext();

  return (
    <label className="flex items-center px-2">
      <input
        {...register(name)}
        className="h-6 w-6 accent-primary-default align-middle mr-2"
        type="checkbox"
        placeholder={placeholder}
        disabled={disabled}
        defaultChecked={defaultValue && !disabled}
        readOnly={!!defaultValue}
        checked={!!defaultValue && !disabled ? true : undefined}
      />

      <span className="ml-2">{label}</span>
    </label>
  );
};

export default Checkbox;
