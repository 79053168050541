import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { client, IHttpRequestConfig } from 'api/api-client';
import {
  getItemFromStorage,
  LOCAL_STORAGE_KEYS,
  removeItemFromStorage,
  setItemToStorage
} from 'utils/local-storage';
import { IUser } from 'types/user';
import { useQueryClient } from '@tanstack/react-query';
import { decodeToken } from 'utils/jwt';
import { IContextProvider } from './app-providers';

export interface IAuthContext {
  user: IUser | null;
  token: string | null;
  login: (token: string) => void;
  logout: () => void;
}

const AuthContext = React.createContext<IAuthContext | undefined>(undefined);
AuthContext.displayName = 'AuthContext';

const AuthProvider = (props: IContextProvider) => {
  const queryClient = useQueryClient();
  const [user, setUser] = useState<IUser | null>(null);
  const [token, setToken] = useState<string | null>(null);

  const logout = useCallback(() => {
    removeItemFromStorage(LOCAL_STORAGE_KEYS.LOCAL_STORAGE_AUTH_KEY);
    setUser(null);
    setToken(null);
    queryClient.clear();
  }, [queryClient]);

  const login = useCallback((jwtToken: string) => {
    setItemToStorage(LOCAL_STORAGE_KEYS.LOCAL_STORAGE_AUTH_KEY, jwtToken);
    const decoded = decodeToken(jwtToken);
    if (decoded) {
      setUser({
        email: decoded.sub,
        name: decoded.full_name,
        orgId: decoded.orgId,
        orgName: decoded.orgName,
        role: decoded.groups[0],
        userId: decoded.userId
      });
    }
    setToken(jwtToken);
  }, []);

  useEffect(() => {
    const jwt = getItemFromStorage<typeof token>(
      LOCAL_STORAGE_KEYS.LOCAL_STORAGE_AUTH_KEY
    );
    if (jwt !== null) {
      login(jwt);
    }
  }, [login]);

  const values = useMemo(
    () => ({ user, login, token, logout }),
    [user, login, token, logout]
  );

  return <AuthContext.Provider value={values} {...props} />;
};

const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

const useClient = (providedToken?: string | null) => {
  const { token, logout } = useAuth();

  return useCallback(
    <R, T = undefined>(endpoint: string, requestConfig: IHttpRequestConfig<T>) =>
      client<R, T>(
        endpoint,
        {
          ...requestConfig,
          token: (providedToken || token) ?? undefined
        },
        logout
      ),
    [providedToken, token, logout]
  );
};

export { AuthProvider, useAuth, useClient };
