import { Buffer } from 'buffer';
import { Roles } from '../types/user';

interface JwtPayload {
  userId: number;
  sub: string;
  iss: 'parola-kft';
  aud: 'onktg';
  groups: Roles[];
  jti: string;
  iat: number;
  exp: number;
  full_name: string;
  moreOrgs: boolean;
  orgId: number;
  orgName: string;
}

const isValidJwtPayload = (payload: string | JwtPayload | null): payload is JwtPayload =>
  payload !== null &&
  typeof payload !== 'string' &&
  (payload as JwtPayload).groups !== undefined &&
  (payload as JwtPayload).userId !== undefined &&
  (payload as JwtPayload).full_name !== undefined;

const decodeJwt = (token: string): JwtPayload | null => {
  const body = token.split('.')[1];
  const buff = Buffer.from(body, 'base64').toString();
  const decoded = JSON.parse(buff);

  if (isValidJwtPayload(decoded)) {
    return decoded;
  }
  return null;
};

export const decodeToken = (token: string): JwtPayload | null => {
  const decoded = decodeJwt(token);
  if (isValidJwtPayload(decoded)) {
    return decoded;
  }
  return null;
};
