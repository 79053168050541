import Button from 'components/ui/Button/Button';
import AddressForm from 'components/ui/CompanyForm/components/AddressForm/AddressForm';
import BaseForm from 'components/ui/CompanyForm/components/BaseForm/BaseForm';
import useCompanyForm, {
  ICompanyFormValues
} from 'components/ui/CompanyForm/useCompanyForm';
import Form, { FormOnSubmit } from 'components/ui/Form/Form';
import StepIndicator from 'components/ui/StepIndicator/StepIndicator';
import { useMemo } from 'react';
import { PackageType } from 'types/subscription';
import { createMaps } from 'utils/helpers';

interface IProps {
  submitTitle: string;
  onSubmit: FormOnSubmit<ICompanyFormValues>;
  isLoading?: boolean;
  selectedPackage: PackageType;
}

const stepMap = createMaps<Record<number, (props?: any) => JSX.Element>>({
  0: BaseForm,
  1: AddressForm
});

const CompanyForm = ({ submitTitle, onSubmit, isLoading, selectedPackage }: IProps) => {
  const { step, formMethods, handleNext, handlePrev } = useCompanyForm();
  const stepCount = useMemo(() => Object.keys(stepMap).length, []);

  return (
    <Form<ICompanyFormValues> formMethods={formMethods} onSubmit={() => false}>
      <StepIndicator currentStep={step + 1} stepCount={3} />
      {step === 0 ? <BaseForm selectedPackage={selectedPackage} /> : null}
      {step === 1 ? <AddressForm /> : null}
      <div className="mt-8">
        <Form.Group>
          {step !== 0 ? (
            <Button
              color="lightBlue"
              variant="outline"
              className="w-full md:w-1/2 order-2 md:order-1"
              onClick={handlePrev}
            >
              Vissza
            </Button>
          ) : null}
          <Button
            color="lightBlue"
            className="w-full md:w-1/2 order-1 md:order-1 mb-4 md:mb-0"
            onClick={() => handleNext(onSubmit)}
            loading={isLoading}
          >
            {step === stepCount - 1 ? submitTitle : 'Tovább'}
          </Button>
        </Form.Group>
      </div>
    </Form>
  );
};

export default CompanyForm;
