import { API_CACHE } from 'constants/cache';
import { API_ROUTES } from 'constants/routes';
import { useClient } from 'contexts/auth-context';
import { format } from 'date-fns';
import { useQuery } from '@tanstack/react-query';

export interface IPartner {
  id: number;
  deleted: boolean;
  taxNumber: string;
  name: string;
  email: string;
  zip: string;
  city: string;
  street: string;
  streetType: string;
  houseNumber: string;
  building: string;
  stairway: string;
  floor: string;
  door: string;
  lotNumber: string;
  tags: string[];
}

const useGetPartners = () => {
  const client = useClient();

  return useQuery([API_CACHE.PARTNERS], () =>
    client<IPartner[]>(API_ROUTES.GET_PARTNERS_LIST, { method: 'get' })
  );
};

export interface IUseGetPartnerListRangeRequest {
  from: Date | null;
  to: Date | null;
  automaticFetch?: boolean;
}

const useGetPartnersRange = ({ from, to }: IUseGetPartnerListRangeRequest) => {
  const client = useClient();

  const fromString = from ? format(from, 'yyyy-MM-dd') : undefined;
  const toString = to ? format(to, 'yyyy-MM-dd') : undefined;

  return useQuery(
    [API_CACHE.PARTNERS, { fromString, toString }],
    () =>
      client<IPartner[], { from: string; to: string }>(
        API_ROUTES.GET_PARTNERS_LIST_RANGE,
        {
          method: 'get',
          params: {
            from: fromString,
            to: toString
          }
        }
      ),
    {
      enabled: false
    }
  );
};

export { useGetPartners, useGetPartnersRange };
