import { FormOnSubmit, WithFormError } from 'components/ui/Form/Form';
import { useCreateAccount } from 'api/accounts';
import { ICompanyFormValues } from 'components/ui/CompanyForm/useCompanyForm';
import { useEffect, useState } from 'react';
import {
  ExtraType,
  isPackageType,
  isPaymentFrequency,
  PackageType,
  PaymentFrequency
} from 'types/subscription';
import { useWindowSize } from 'hooks/useWindowSize';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { PASSWORD_STRENGTH_VALIDATOR, VALIDATION_MESSAGES } from 'constants/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { useNotification } from 'contexts/notification-context';
import { APP_ROUTES } from 'constants/routes';
import { isTypeUnion } from 'utils/helpers';

export interface ITrialRegistrationFormValues {
  name: string;
  email: string;
  password: string;
  passwordConfirm: string;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required(VALIDATION_MESSAGES.required),
  email: Yup.string()
    .email(VALIDATION_MESSAGES.invalid_email)
    .required(VALIDATION_MESSAGES.required),
  password: Yup.string()
    .required(VALIDATION_MESSAGES.required)
    .matches(PASSWORD_STRENGTH_VALIDATOR, VALIDATION_MESSAGES.weak_password),
  passwordConfirm: Yup.string()
    .required(VALIDATION_MESSAGES.required)
    .oneOf([Yup.ref('password'), null], VALIDATION_MESSAGES.match_password)
});

const useRegisterPage = () => {
  const { mutate: createAccountMutation, isLoading: isCreateLoading } =
    useCreateAccount();
  const { width } = useWindowSize();
  const formMethods = useForm<WithFormError<ITrialRegistrationFormValues>>({
    defaultValues: {
      name: '',
      email: '',
      password: '',
      passwordConfirm: ''
    },
    resolver: yupResolver(validationSchema)
  });
  const [selectedPackage, setSelectedPackage] = useState<PackageType>();
  const [paymentFrequency, setPaymentFrequency] = useState<PaymentFrequency>('MONTH');
  const [selectedExtras, setSelectedExtras] = useState<ExtraType[]>([]);
  const location = useLocation();
  const navigate = useNavigate();
  const { addNotification } = useNotification();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const type = params.get('type');
    const payFreq = params.get('payFreq');
    if (type && type.toUpperCase() === 'FREE') {
      setSelectedPackage('FREE');
    } else if (
      type &&
      payFreq &&
      isTypeUnion(type.toUpperCase(), isPackageType) &&
      isTypeUnion(payFreq.toUpperCase(), isPaymentFrequency)
    ) {
      setPaymentFrequency(payFreq?.toUpperCase() as PaymentFrequency);
      setSelectedPackage(type?.toUpperCase() as PackageType);
    }
  }, [location.search]);

  const onSubmit: FormOnSubmit<ICompanyFormValues> = (data) => {
    createAccountMutation(
      {
        ...data,
        subType: selectedPackage || 'FREE',
        org: data.org ? { ...data.org, lotNumber: null } : undefined,
        payFreq: paymentFrequency,
        extra: selectedExtras
      },
      {
        onSuccess: (response) => {
          if (!selectedPackage || selectedPackage === 'FREE') {
            addNotification({
              type: 'success',
              message:
                'Sikeres regisztráció! Az aktiváló linket elküldtük a megadott email címre.'
            });
            navigate(APP_ROUTES.LOGIN);
          } else {
            window.location.replace(response);
          }
        }
      }
    );
  };

  const handleSelectPackage = ({
    pckg,
    payFreq,
    extras
  }: {
    pckg: PackageType;
    payFreq: PaymentFrequency;
    extras: ExtraType[];
  }) => {
    setSelectedPackage(pckg);
    setPaymentFrequency(payFreq);
    setSelectedExtras(extras);
  };

  return {
    onSubmit,
    isCreateLoading,
    handleSelectPackage,
    selectedPackage,
    width,
    formMethods
  };
};

export default useRegisterPage;
