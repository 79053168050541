import {
  BarChartData,
  IBaseFilters,
  useGetPriceChangesBarChartData
} from 'api/price-changes';
import { ChartData } from 'chart.js';
import { WithFormError } from 'components/ui/Form/Form';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

interface IProps {
  filters: IBaseFilters;
}

const usePriceChangeBarChart = ({ filters }: IProps) => {
  const [data, setData] = useState<BarChartData>([]);
  const formMethods = useForm<WithFormError<{ type: 'value' | 'percent' }>>({
    defaultValues: {
      type: 'value'
    }
  });
  const type = formMethods.watch('type');
  const chartData = useMemo<ChartData<'bar', number[], string>>(
    () =>
      data.reduce<ChartData<'bar', number[], string>>(
        (acc, curr) => {
          const { name, value, percent } = curr;
          if (!acc.labels) acc.labels = [];
          acc.labels.push(name);
          acc.datasets[0].data.push(type === 'percent' ? percent : value);
          (acc.datasets[0].backgroundColor as string[]).push(value > 0 ? 'red' : 'green');
          return acc;
        },
        {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: [],
              barThickness: 6,
              borderSkipped: false,
              borderRadius: 5
            }
          ]
        }
      ),
    [data, type]
  );

  const { mutate: getBarChartData, isLoading } = useGetPriceChangesBarChartData();

  useEffect(() => {
    getBarChartData(filters, {
      onSuccess: (response) => setData(response)
    });
  }, [filters, getBarChartData]);

  return { chartData, isLoading, formMethods, type };
};

export default usePriceChangeBarChart;
