import PageTitle from 'components/ui/PageTitle/PageTitle';
import { useMemo } from 'react';
import TextInput from 'components/ui/Form/controls/TextInput/TextInput';
import Button from 'components/ui/Button/Button';
import Table from 'components/ui/Table/Table';
import { Column } from 'react-table';
import { ISubscriptionsListItem } from 'api/admin';
import Form from 'components/ui/Form/Form';
import DatePicker from 'components/ui/Form/controls/Date/DatePicker';
import Select from 'components/ui/Form/controls/Select/Select';
import { SUB_STATUS_OPTIONS, USER_STATUS_OPTIONS } from 'constants/statuses';
import Box from 'components/ui/Box/Box';
import { PACKAGE_NAMES } from 'constants/packages';
import useAdminPage, { IAdminFilter } from './useAdminPage';

const AdminPage = () => {
  const { data, isLoading, filterFormMethods, tableFormMethods, onSubmit } =
    useAdminPage();

  const columns = useMemo<Column<ISubscriptionsListItem>[]>(
    () => [
      {
        accessor: 'taxnum',
        Header: 'Adószám'
      },
      {
        accessor: 'orgname',
        Header: 'Cégnév'
      },
      {
        accessor: 'package',
        Header: 'Csomag',
        Cell: ({ value }) => <span>{PACKAGE_NAMES[value] || value}</span>
      },
      {
        accessor: 'payfrequency',
        Header: 'Fizetési gyakoriság'
      },
      {
        accessor: 'validity',
        Header: 'Érvényesség vége',
        Cell: ({ row: { index }, column: { id } }) => (
          <DatePicker isClearable={false} name={`data.${index}.${id}`} />
        )
      },
      {
        accessor: 'status',
        Header: 'Státusz',
        Cell: ({ row: { index }, column: { id } }) => (
          <Select
            isClearable={false}
            name={`data.${index}.${id}`}
            options={SUB_STATUS_OPTIONS}
          />
        )
      }
    ],
    []
  );

  return (
    <>
      <PageTitle>Adminisztráció</PageTitle>
      <Box>
        <Form<IAdminFilter> formMethods={filterFormMethods} onSubmit={onSubmit}>
          <Form.Group>
            <TextInput name="name" label="Cégnév" />
            <Select
              name="status"
              className="min-w-[400px]"
              label="Státusz"
              options={USER_STATUS_OPTIONS}
              isMulti
            />
            <DatePicker name="dateFrom" label="Dátumtól" />
            <DatePicker name="dateTo" label="Dátumig" />
          </Form.Group>
        </Form>

        <Form formMethods={tableFormMethods} onSubmit={(values) => console.log(values)}>
          <Table columns={columns} data={data} isLoading={isLoading} />
          <Button color="lightBlue" type="submit" className="w-24 mt-4">
            Mentés
          </Button>
        </Form>
      </Box>
    </>
  );
};

export default AdminPage;
