import PageTitle from 'components/ui/PageTitle/PageTitle';
import useInvoicesPage, {
  IInvoicesFormValues
} from 'components/pages/InvoicesPage/useInvoicesPage';
import Form from 'components/ui/Form/Form';
import Button from 'components/ui/Button/Button';
import SectionTitle from 'components/ui/SectionTitle/SectionTitle';
import Table from 'components/ui/Table/Table';
import { useMemo } from 'react';
import { Column } from 'react-table';
import Box from 'components/ui/Box/Box';
import { IInvoicesListItem } from 'api/nav';
import DatePicker from 'components/ui/Form/controls/Date/DatePicker';
import { formatDate, formatDateTime } from 'utils/dates';
import Alert from 'components/ui/Alert/Alert';
import { endOfMonth } from 'date-fns';

const InvoicesPage = () => {
  const {
    data,
    isLoading,
    formMethods,
    onSubmit,
    hasNavConnection,
    hasValidSubscription
  } = useInvoicesPage();

  const formatStatus = (status: string) => {
    switch (status) {
      case 'DOWNLOADING':
      case 'IMPORTING':
        return 'Folyamatban';
      case 'FAILED':
        return 'Hiányos';
      case 'DONE':
        return 'Sikeres';
      default:
        return 'N/A';
    }
  };

  const columns = useMemo<Column<IInvoicesListItem>[]>(
    () => [
      {
        accessor: 'startTime',
        Header: 'Letöltés kezdő időpontja',
        Cell: ({ value }) => <div>{formatDateTime(value)}</div>
      },
      {
        accessor: 'lastTime',
        Header: 'Letöltés befejező időpontja',
        Cell: ({ value }) => <div>{formatDateTime(value)}</div>
      },
      {
        accessor: 'fromDate',
        Header: 'Dátumtól',
        Cell: ({ value }) => <div>{formatDate(value, true)}</div>
      },
      {
        accessor: 'toDate',
        Header: 'Dátumig',
        Cell: ({ value }) => <div>{formatDate(value, true)}</div>
      },
      {
        accessor: 'status',
        Header: 'Státusz',
        Cell: ({ value }) => <div>{formatStatus(value)}</div>
      },
      {
        accessor: 'countInvoices',
        Header: 'Letöltött számlák száma'
      },
      {
        accessor: 'countErrors',
        Header: 'Le nem töltött számlák száma'
      },
      {
        accessor: 'countPartners',
        Header: 'Új partnerek száma'
      },
      {
        accessor: 'countProducts',
        Header: 'Új termékek száma'
      }
    ],
    []
  );

  const formDisabled = !hasValidSubscription || !hasNavConnection;

  return (
    <>
      <PageTitle>Számlák letöltése</PageTitle>
      {!hasValidSubscription ? (
        <Alert variant="warning">
          A funkció használatához aktív előfizetés szükséges!
        </Alert>
      ) : null}
      {hasValidSubscription && !hasNavConnection ? (
        <Alert variant="warning">
          A funkció használatához aktív NAV kapcsolat szükséges!
        </Alert>
      ) : null}
      <Box>
        <SectionTitle>Letöltés újraindítása</SectionTitle>
        <Form<IInvoicesFormValues> formMethods={formMethods} onSubmit={onSubmit}>
          <div className="w-56">
            <DatePicker
              name={'from'}
              label="Kezdő dátum"
              isClearable={false}
              disabled={formDisabled}
              maxDate={new Date()}
            />
          </div>
          <div className="w-56">
            <DatePicker
              name={'to'}
              label="Vég dátum"
              isClearable={false}
              disabled={formDisabled}
              minDate={new Date(formMethods.getValues('from').getTime())}
              maxDate={endOfMonth(new Date())}
            />
          </div>
          <div className="flex space-x-4">
            <Button
              color="lightBlue"
              type="submit"
              disabled={formDisabled}
              className="mt-7"
            >
              Letöltés
            </Button>
          </div>
        </Form>
      </Box>
      <Box>
        <SectionTitle>Letöltések</SectionTitle>
        <Table columns={columns} data={data} isLoading={isLoading} />
      </Box>
    </>
  );
};

export default InvoicesPage;
