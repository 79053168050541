import { format, isValid } from 'date-fns';
import { hu } from 'date-fns/locale';

const LONG_DATE_FORMAT = 'yyyy. MMMM dd.';
const SHORT_DATE_FORMAT = 'yyyy.MM.dd.';
const DATE_TIME_FORMAT = 'yyyy.MM.dd. HH:mm:ss';
const API_DATE_FORMAT = 'yyyy-MM-dd';

export const formatDate = (date: Date | string, short = false) => {
  if (!(date instanceof Date) && typeof date !== 'string') {
    return `Invalid date: ${JSON.stringify(date)}`;
  }
  const dateObj = new Date(date);
  if (!isValid(dateObj)) {
    return `Invalid date: ${JSON.stringify(date)}`;
  }

  return format(dateObj, short ? SHORT_DATE_FORMAT : LONG_DATE_FORMAT, {
    locale: hu
  });
};

export const formatDateForApi = (date: unknown) => {
  if (!(date instanceof Date) && typeof date !== 'string') {
    throw new Error(`Invalid date: ${date}`);
  }
  const dateObj = new Date(date);
  if (!isValid(dateObj)) {
    throw new Error(`Invalid date: ${date}`);
  }
  return format(dateObj, API_DATE_FORMAT);
};

export const formatDateTime = (date: unknown) => {
  if (!(date instanceof Date) && typeof date !== 'string') {
    return `Invalid date: ${JSON.stringify(date)}`;
  }
  const dateObj = new Date(date);
  if (!isValid(dateObj)) {
    return `Invalid date: ${JSON.stringify(date)}`;
  }

  return format(dateObj, DATE_TIME_FORMAT, {
    locale: hu
  });
};
