import { useGetExtras, useGetPackages } from 'api/packages';
import { WithFormError } from 'components/ui/Form/Form';
import { useForm } from 'react-hook-form';
import { ExtraType, PackageType, PaymentFrequency } from 'types/subscription';

export interface IPackageSelectorForm {
  payFreq: PaymentFrequency;
  extra: Record<PackageType, Record<ExtraType, boolean>>;
}

const usePackageSelector = () => {
  const { data: packages } = useGetPackages();
  const { data: extras } = useGetExtras();

  const formMethods = useForm<WithFormError<IPackageSelectorForm>>({
    defaultValues: {
      payFreq: 'MONTH',
      extra: {
        FREE: {
          PARTNER: false,
          PRODUCT: false
        },
        SMALL: {
          PARTNER: false,
          PRODUCT: false
        },
        MEDIUM: {
          PARTNER: false,
          PRODUCT: false
        },
        LARGE: {
          PARTNER: false,
          PRODUCT: false
        }
      }
    }
  });

  const payFreq = formMethods.watch('payFreq');
  const selectedExtras = formMethods.watch('extra');

  return {
    packages,
    extras: extras || [],
    formMethods,
    payFreq,
    selectedExtras
  };
};

export default usePackageSelector;
