interface IProps {
  tags: string[];
}

const TAG_STYLE =
  'bg-lightBlue-100 text-white rounded p-2 m-1 ml-0 text-xs whitespace-nowrap';

const CategoryTags = ({ tags }: IProps) => (
  <div>
    {tags.length > 2 ? (
      <>
        <span className={TAG_STYLE}>{tags[0]}</span>
        <span className={TAG_STYLE}>{tags[1]}</span>
        <span className={TAG_STYLE}>{`+ ${tags.length - 2}`}</span>
      </>
    ) : (
      tags.map((tag) => (
        <span key={tag} className={TAG_STYLE}>
          {tag}
        </span>
      ))
    )}
  </div>
);

export default CategoryTags;
