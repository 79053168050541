import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import { useFormContext } from 'react-hook-form';
import { IDefaultControlProps } from '../../Form';
import BaseControl from '../BaseControl/BaseControl';
import usePasswordInput from './usePasswordInput';

const PasswordInput = (props: IDefaultControlProps) => {
  const { name, label, placeholder, disabled, autoComplete, className, group } = props;
  const { register } = useFormContext();
  const { show, handleToggleShow } = usePasswordInput();

  return (
    <BaseControl
      name={name}
      label={label}
      className={className}
      group={group}
      endAdornment={
        <button type="button" onClick={handleToggleShow}>
          {show ? <EyeOffIcon className="w-5 h-5" /> : <EyeIcon className="w-5 h-5" />}
        </button>
      }
    >
      <input
        {...register(name)}
        type={show ? 'text' : 'password'}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete={autoComplete}
        className="w-full"
      />
    </BaseControl>
  );
};

export default PasswordInput;
