import { IBaseFilters } from 'api/price-changes';
import usePriceChangeByProducts from 'components/pages/PriceChangesPage/components/PriceChangeByProducts/usePriceChangeByProducts';
import Button from 'components/ui/Button/Button';
import { DateRange } from 'components/ui/DateRangeFilter/useDateRangeFilter';
import TextInput from 'components/ui/Form/controls/TextInput/TextInput';
import Form from 'components/ui/Form/Form';
import LoadingIndicator from 'components/ui/LoadingIndicator/LoadingIndicator';
import SectionTitle from 'components/ui/SectionTitle/SectionTitle';
import Table from 'components/ui/Table/Table';
import { ICON_MAP } from 'constants/icons';
import { useMemo } from 'react';
import { Column } from 'react-table';
import { mergeClassNames } from 'utils/helpers';

interface IProps {
  filters: IBaseFilters;
  dateRange: DateRange;
}

const PriceChangeByProducts = ({ filters, dateRange }: IProps) => {
  const {
    data,
    columnKeys,
    grouping,
    setGrouping,
    allowedButtons,
    isLoading,
    formMethods
  } = usePriceChangeByProducts({
    filters,
    dateRange
  });

  const columns = useMemo<Column<any>[]>(() => {
    if (!columnKeys.length) return [];

    return [
      {
        Header: 'Cikk',
        accessor: 'name'
      },
      ...columnKeys.map((ck) => ({
        Header: () => <div className="text-right">{ck}</div>,
        accessor: ck,
        Cell: ({ row }: { row: any }) => {
          const value = row.original[ck];
          const IconComponent =
            ICON_MAP[value?.movement === 'UP' ? 'chevronUp' : 'chevronDown'];

          if (!value?.movement || value?.movement === 'SAME') {
            return (
              <div className="flex justify-end">
                {value?.value?.map((v: string) => (
                  <div key={v} className="min-w-[80px] text-right">
                    {v}
                  </div>
                ))}{' '}
                <div className="w-4 ml-2 mr-0" />
              </div>
            );
          }

          return (
            <div className="flex justify-end">
              {value?.value?.map((v: string) => (
                <div key={v} className="min-w-[80px] text-right">
                  {v}
                </div>
              ))}{' '}
              <IconComponent
                className={`w-4 ml-2 mr-0 ${
                  value?.movement === 'UP' ? 'text-red-500' : 'text-green-500'
                }`}
              />
            </div>
          );
        }
      }))
    ];
  }, [columnKeys]);

  return (
    <div>
      <SectionTitle>Árváltozás cikkenként</SectionTitle>
      <div className="flex justify-between items-center flex-wrap">
        <div className="w-96">
          <Form formMethods={formMethods} onSubmit={() => false}>
            <TextInput name="search" placeholder="Keresés névre" />
          </Form>
        </div>
        <div className="flex justify-end flex-wrap">
          <Button
            color="info"
            variant="text"
            type="button"
            onClick={() => setGrouping('INVOICE')}
            className={mergeClassNames(
              `mx-2 ${
                grouping === 'INVOICE' ? 'bg-darkBlue-5 bg-opacity-20 rounded-lg' : ''
              }`
            )}
            disabled={!allowedButtons.includes('INVOICE')}
          >
            Számlánként
          </Button>
          <Button
            color="info"
            variant="text"
            type="button"
            onClick={() => setGrouping('DAILY')}
            className={mergeClassNames(
              `mx-2 ${
                grouping === 'DAILY' ? 'bg-darkBlue-5 bg-opacity-20 rounded-lg' : ''
              }`
            )}
            disabled={!allowedButtons.includes('DAILY')}
          >
            Napi
          </Button>
          <Button
            color="info"
            variant="text"
            type="button"
            onClick={() => setGrouping('WEEKLY')}
            className={mergeClassNames(
              `mx-2 ${
                grouping === 'WEEKLY' ? 'bg-darkBlue-5 bg-opacity-20 rounded-lg' : ''
              }`
            )}
            disabled={!allowedButtons.includes('WEEKLY')}
          >
            Heti
          </Button>
          <Button
            color="info"
            variant="text"
            type="button"
            onClick={() => setGrouping('MONTHLY')}
            className={mergeClassNames(
              `mx-2 ${
                grouping === 'MONTHLY' ? 'bg-darkBlue-5 bg-opacity-20 rounded-lg' : ''
              }`
            )}
            disabled={!allowedButtons.includes('MONTHLY')}
          >
            Havi
          </Button>
          <Button
            color="info"
            variant="text"
            type="button"
            onClick={() => setGrouping('QUARTERLY')}
            className={mergeClassNames(
              `mx-2 ${
                grouping === 'QUARTERLY' ? 'bg-darkBlue-5 bg-opacity-20 rounded-lg' : ''
              }`
            )}
            disabled={!allowedButtons.includes('QUARTERLY')}
          >
            Negyedéves
          </Button>
          <Button
            color="info"
            variant="text"
            type="button"
            onClick={() => setGrouping('YEARLY')}
            className={mergeClassNames(
              `mx-2 ${
                grouping === 'YEARLY' ? 'bg-darkBlue-5 bg-opacity-20 rounded-lg' : ''
              }`
            )}
            disabled={!allowedButtons.includes('YEARLY')}
          >
            Éves
          </Button>
        </div>
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center">
          <LoadingIndicator />
          <p>A kimutatás megjelenítése folyamatban.</p>
        </div>
      ) : (
        <Table data={data} columns={columns} isLoading={false} />
      )}
    </div>
  );
};

export default PriceChangeByProducts;
