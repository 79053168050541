import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCreateUser, useGetUserById, useUpdateUser } from 'api/users';
import { FormOnSubmit, WithFormError } from 'components/ui/Form/Form';
import { APP_ROUTES } from 'constants/routes';
import { PASSWORD_STRENGTH_VALIDATOR, VALIDATION_MESSAGES } from 'constants/validation';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Roles } from 'types/user';
import { assertNumeric, generatePassword } from 'utils/helpers';
import { useEffect } from 'react';
import { UserStatus } from 'types/status';
import { useNotification } from 'contexts/notification-context';
import { SUCCESS_MESSAGES } from 'constants/messages';
import { useModal } from 'contexts/modal-context';

export interface IUserFormValues {
  name: string;
  email: string;
  role: Roles;
  password: string;
  status: UserStatus;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required(VALIDATION_MESSAGES.required),
  email: Yup.string()
    .email(VALIDATION_MESSAGES.invalid_email)
    .required(VALIDATION_MESSAGES.required),
  role: Yup.string().required(VALIDATION_MESSAGES.required),
  password: Yup.string().matches(PASSWORD_STRENGTH_VALIDATOR, {
    message: VALIDATION_MESSAGES.weak_password,
    excludeEmptyString: true
  }),
  status: Yup.string()
});

const useUserForm = () => {
  const { userId } = useParams<{ userId: string }>();
  assertNumeric(userId, true);

  const formMethods = useForm<WithFormError<IUserFormValues>>({
    defaultValues: {
      name: '',
      email: '',
      role: undefined,
      password: '',
      status: undefined
    },
    resolver: yupResolver(validationSchema)
  });

  const { mutate: createUser, isLoading: isCreateUserLoading } = useCreateUser();
  const { mutate: updateUser, isLoading: isUpdateUserLoading } = useUpdateUser();
  const { data: user } = useGetUserById(Number(userId));
  const { addNotification } = useNotification();

  const navigate = useNavigate();
  const { openModalWithContent } = useModal();

  useEffect(() => {
    if (user) {
      formMethods.reset(user);
    }
  }, [formMethods, user]);

  const handleCancel = () => {
    navigate(APP_ROUTES.USER_MANAGEMENT);
  };

  const handleSubmit: FormOnSubmit<IUserFormValues> = (data, setError) => {
    if (user) {
      updateUser(
        { ...data, id: user.id, password: data.password || undefined },
        {
          onSuccess: () => {
            navigate(APP_ROUTES.USER_MANAGEMENT);
            addNotification({ type: 'success', message: SUCCESS_MESSAGES.SAVED });
          }
        }
      );
    } else {
      createUser(
        { ...data, status: 'ACTIVE' },
        {
          onSuccess: () => {
            navigate(APP_ROUTES.USER_MANAGEMENT);
            addNotification({ type: 'success', message: SUCCESS_MESSAGES.SAVED });
          }
        }
      );
    }
  };

  const creatAutoPassword = () => {
    const password = generatePassword();
    formMethods.setValue('password', password);
  };

  return {
    formMethods,
    handleCancel,
    handleSubmit,
    isSubmitLoading: isCreateUserLoading || isUpdateUserLoading,
    user,
    creatAutoPassword,
    openModalWithContent,
    userId
  };
};

export default useUserForm;
