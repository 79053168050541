import Button from 'components/ui/Button/Button';
import useDateRangeFilter, {
  RANGES,
  RANGES_ON_UI
} from 'components/ui/DateRangeFilter/useDateRangeFilter';
import DateRangePicker from 'components/ui/Form/controls/Date/DateRangePicker';

interface IProps {
  name: string;
  disabled?: boolean;
  minDate?: Date | null;
}

const DateRangeFilter = ({ name, disabled, minDate }: IProps) => {
  const { setRange } = useDateRangeFilter({ name });
  return (
    <>
      <div className="flex -mx-2 flex-wrap">
        <div className="flex flex-wrap">
          {RANGES_ON_UI.map((r) => (
            <Button
              color="info"
              variant="text"
              type="button"
              onClick={() => setRange(r)}
              className="mx-2"
              disabled={disabled}
              key={r}
            >
              {RANGES[r].label}
            </Button>
          ))}
        </div>
        <DateRangePicker
          name={name}
          className="mx-2"
          disabled={disabled}
          minDate={minDate}
        />
      </div>
    </>
  );
};

export default DateRangeFilter;
