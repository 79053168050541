import { IconProp } from 'constants/icons';

const CheckMarkIcon = (props: IconProp) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 14" {...props}>
    <path
      fill="currentColor"
      d="M6 10.67 2.53 7.2a.996.996 0 1 0-1.41 1.41l4.18 4.18c.39.39 1.02.39 1.41 0L17.29 2.21A.996.996 0 1 0 15.88.8L6 10.67Z"
    />
  </svg>
);

export default CheckMarkIcon;
