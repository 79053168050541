import { IPartner } from 'api/partners';
import CategoryFilters from 'components/pages/CategorizationPage/components/CategoryFilters/CategoryFilters';
import CategoryTags from 'components/pages/CategorizationPage/components/CategoryTags/CategoryTags';
import usePartnerCategorization, {
  PartnerCategories
} from 'components/pages/CategorizationPage/components/PartnerCategorization/usePartnerCategorization';
import Button from 'components/ui/Button/Button';
import Select, { SelectOptions } from 'components/ui/Form/controls/Select/Select';
import Form from 'components/ui/Form/Form';
import StoreFrontIcon from 'components/ui/Icons/StoreFrontIcon/StoreFrontIcon';
import React from 'react';

interface IProps {
  partners: IPartner[] | undefined;
  selectedPartner: IPartner | null;
  handleSelectPartner: (partner: IPartner) => void;
  categoryOptions: SelectOptions<string>[];
  handleCategoryChange: () => void;
  editSelected: boolean;
  toggleEdit: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    partner: IPartner
  ) => void;
}

const PartnerCategorization = ({
  partners = [],
  selectedPartner,
  handleSelectPartner,
  categoryOptions,
  handleCategoryChange,
  editSelected,
  toggleEdit
}: IProps) => {
  const { formMethods, onChangeOptions, filteredValues, setFilteredValues } =
    usePartnerCategorization({ selectedPartner });
  return (
    <div className="overflow-auto">
      {partners.length ? (
        <CategoryFilters
          data={partners}
          onChange={setFilteredValues}
          categoryOptions={categoryOptions}
        />
      ) : null}
      {filteredValues?.map((partner) => (
        <div key={partner.id}>
          <div
            className={`flex justify-between p-1.5 my-1.5 rounded flex-wrap items-center ${
              selectedPartner?.id === partner.id
                ? 'bg-lightBlue-40 bg-opacity-20'
                : 'hover:bg-lightBlue-40 hover:bg-opacity-20 hover:cursor-pointer'
            }`}
            onClick={() => handleSelectPartner(partner)}
          >
            <div className="flex flex-1 mr-2 items-center">
              <span className="text-lightBlue-100">
                <StoreFrontIcon className="h-6 mr-2" />
              </span>
              {partner.name}
            </div>
            <div className="hidden md:block">
              {selectedPartner?.id !== partner.id || !editSelected ? (
                <CategoryTags tags={partner.tags} />
              ) : null}
            </div>
            {editSelected && selectedPartner?.id === partner.id ? (
              <Button
                iconClassName="h-4 w-4"
                buttonClassName="px-1 py-0"
                icon="checkMark"
                variant="text"
                color="info"
                onClick={(e) => toggleEdit(e, partner)}
              />
            ) : (
              <Button
                iconClassName="h-4 w-4"
                buttonClassName="px-1 py-0"
                icon="edit"
                variant="text"
                color="info"
                onClick={(e) => toggleEdit(e, partner)}
              />
            )}
          </div>
          {selectedPartner?.id === partner.id && editSelected ? (
            <Form<PartnerCategories> formMethods={formMethods} onSubmit={() => false}>
              <div className="flex items-center">
                <Select
                  name="tags"
                  options={categoryOptions}
                  creatable
                  isMulti
                  className="flex-1"
                  onChangeOptions={(action) =>
                    onChangeOptions(action, handleCategoryChange)
                  }
                  placeholder="Írj be egy új kategóriát vagy válassz!"
                />
              </div>
            </Form>
          ) : null}
        </div>
      ))}
    </div>
  );
};

export default PartnerCategorization;
