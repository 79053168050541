import { RefObject } from 'react';
import {
  Cell,
  Column,
  TableCellProps,
  useTable,
  TableHeaderProps,
  ColumnInstance,
  UseTableColumnOptions
} from 'react-table';

declare module 'react-table' {
  export interface ColumnInterface<D extends object = {}>
    extends UseTableColumnOptions<D> {
    hide?: boolean;
  }
}

export interface CustomTableProps {
  /**
   * @property {boolean} collapse - if true, the cell doesn't grow in width
   */
  collapse?: boolean;
  /**
   * @property {boolean} wrap - controls wether the text in the cell should wrap or not
   */
  wrap?: boolean;
}

export interface CustomTableHeaderProps extends CustomTableProps {}
export interface CustomTableCellProps extends CustomTableProps {}

interface IProps<T extends {}> {
  data: T[];
  columns: Column<T>[];
  getHeaderProps?: (
    headerInfo: ColumnInstance<T>
  ) => Partial<Omit<TableHeaderProps, 'key'>> & CustomTableHeaderProps;
  getCellProps?: (
    cellInfo: Cell<T>
  ) => Partial<Omit<TableCellProps, 'key'>> & CustomTableCellProps;
  isLoading: boolean;
  small?: boolean;
  className?: string;
  bottomRef?: RefObject<HTMLTableRowElement>;
  rootRef?: RefObject<HTMLTableSectionElement>;
}

const defaultPropsGetter = () => ({});

const Table = <T extends {}>({
  columns,
  data,
  getHeaderProps = defaultPropsGetter,
  getCellProps = defaultPropsGetter,
  isLoading,
  small,
  className,
  bottomRef,
  rootRef
}: IProps<T>): JSX.Element => {
  const table = useTable({
    columns,
    data,
    initialState: {
      hiddenColumns: columns
        .filter((column) => column.hide)
        .map((column) => column.id || column.accessor) as string[]
    }
  });

  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = table;

  return (
    <div className="max-w-full max-h-full overflow-auto flex-1">
      <table {...getTableProps()} className="w-full relative max-h-full overflow-auto">
        <thead className="w-full">
          <tr className={`bg-secondary-default ${isLoading ? 'animate-pulse' : ''}`}>
            {headers
              .filter((column) => !column.hide)
              .map((column) => {
                const { key: headerKey, ...restColumn } = column.getHeaderProps();
                const {
                  collapse,
                  className: headerClassName,
                  ...restProps
                } = getHeaderProps(column);
                return (
                  <th
                    key={headerKey}
                    {...restProps}
                    {...restColumn}
                    className={`m-0 p-3 font-semibold text-left whitespace-nowrap sticky top-0 bg-secondary-default text-darkBlue-100 ${headerClassName}`}
                  >
                    {column.render('Header')}
                  </th>
                );
              })}
          </tr>
        </thead>
        <tbody {...getTableBodyProps} className="w-full">
          {rows.map((row) => {
            prepareRow(row);
            const { key: rowKey, ...restRowProps } = row.getRowProps();
            return (
              <tr
                key={rowKey}
                {...restRowProps}
                className="cursor-default hover:bg-lightBlue-40 hover:bg-opacity-20 border-t h-[40px]"
              >
                {row.cells.map((cell) => {
                  const { key: cellKey, ...restCell } = cell.getCellProps();
                  const {
                    collapse,
                    className: rowClassName,
                    wrap,
                    ...restProps
                  } = getCellProps(cell);
                  return (
                    <td
                      key={cellKey}
                      {...restProps}
                      {...restCell}
                      className={`border-t-1 m-0 px-3 w-auto text-left font-medium ${
                        wrap ? '' : 'whitespace-nowrap'
                      }`}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          <tr ref={bottomRef}></tr>
        </tbody>
      </table>
    </div>
  );
};

export default Table;
