const AppSkeleton = () => (
  <div className="w-1/2 h-full flex flex-col items-center justify-center overflow-clip relative">
    <img
      src="/assets/svg/Skeleton.svg"
      className="w-[1352px] max-w-none absolute left-0 rounded-lg"
    />
  </div>
);

export default AppSkeleton;
