import React from 'react';
import { useFormContext } from 'react-hook-form';
import { getDeepValue, mergeClassNames } from 'utils/helpers';
import ErrorFeedback from '../ErrorFeedback/ErrorFeedback';

export interface ControlProps {
  children: React.ReactElement;
  label?: React.ReactNode;
  name: string;
  onChange?: (e: React.SyntheticEvent) => void;
  endAdornment?: React.ReactElement;
  className?: string;
  group?: boolean;
}

export const BASE_INPUT_CLASS =
  'min-h-[46px] border border-neutral-300 text-darkblue-100 text-md py-1 px-2 placeholder:text-neutral-400 outline-0 focus:border-darkBlue-100 bg-white rounded-lg disabled:text-neutral-400 w-full text-left';
export const INVALID_INPUT_CLASS =
  'bg-red-50 text-red-600 border-red-600 focus:border-red-600';

const BaseControl = (props: ControlProps) => {
  const { label, children, name, endAdornment, group, className = '' } = props;
  const {
    formState: { errors }
  } = useFormContext();
  const error = getDeepValue(errors, name)?.message;

  return (
    <div
      className={mergeClassNames('flex flex-col my-1 sm:mt-1 mx-0', className, {
        relative: !!endAdornment
      })}
    >
      {label ? (
        <label
          htmlFor={name}
          className="text-xs flex items-center text-neutral-500 mb-1 mt-2"
        >
          {label}
        </label>
      ) : null}
      <div className="w-full relative">
        {React.cloneElement(children, {
          name,
          className: mergeClassNames(
            BASE_INPUT_CLASS,
            {
              [INVALID_INPUT_CLASS]: !!error,
              'pl-2 pr-8': !!endAdornment
            },
            children.props.className
          )
        })}
        {endAdornment
          ? React.cloneElement(endAdornment, {
              className: mergeClassNames(
                'absolute top-1/2 -translate-y-1/2 mr-2.5 right-0',
                {
                  'mr-5': !!group
                }
              )
            })
          : null}
      </div>
      {error ? <ErrorFeedback message={error} /> : null}
    </div>
  );
};

export default BaseControl;
