import PageTitle from 'components/ui/PageTitle/PageTitle';
import Button from 'components/ui/Button/Button';
import Form from 'components/ui/Form/Form';
import Select from 'components/ui/Form/controls/Select/Select';
import usePartnerExportPage, {
  IPartnerExportForm
} from 'components/pages/PartnerExportPage/usePartnerExportPage';
import SectionTitle from 'components/ui/SectionTitle/SectionTitle';
import Box from 'components/ui/Box/Box';
import Alert from 'components/ui/Alert/Alert';

const PartnerExportPage = () => {
  const { tagOptions, formMethods, onSubmit, susbscribedToExport } =
    usePartnerExportPage();

  return (
    <>
      <PageTitle>Partnerek exportálása</PageTitle>
      {!susbscribedToExport ? (
        <Alert variant="warning">A partner export funkcióra nincs előfizetés!</Alert>
      ) : null}
      <Box>
        <SectionTitle>Igény szerint válasszon kategóriát</SectionTitle>
        <Form<IPartnerExportForm> formMethods={formMethods} onSubmit={() => {}}>
          <Select
            name="categories"
            label={'Kategóriák'}
            placeholder="Kategóriák.."
            options={tagOptions}
            isMulti
            disabled={!susbscribedToExport}
          />
          <div className="flex flex-col-reverse md:flex-row md:gap-2.5">
            <Button
              color="lightBlue"
              variant="outline"
              onClick={() => onSubmit('print')}
              className="w-42 mt-4"
              disabled={!susbscribedToExport}
            >
              Nyomtatás
            </Button>
            <Button
              color="lightBlue"
              onClick={() => onSubmit('export')}
              className="w-42 mt-4"
              disabled={!susbscribedToExport}
            >
              Exportálás
            </Button>
          </div>
        </Form>
      </Box>
    </>
  );
};

export default PartnerExportPage;
