import BaseControl from 'components/ui/Form/controls/BaseControl/BaseControl';
import { IDefaultControlProps } from 'components/ui/Form/Form';
import { useFormContext } from 'react-hook-form';
import { mergeClassNames } from 'utils/helpers';

interface IProps extends IDefaultControlProps {
  value: string;
}

const RadioButton = (props: IProps) => {
  const { name, label, placeholder, disabled, autoComplete, className, value } = props;
  const { register } = useFormContext();

  return (
    <>
      <label className={mergeClassNames(className, 'flex items-center px-2')}>
        <BaseControl name={name} group={props.group}>
          <input
            {...register(name)}
            className="h-6 w-6 accent-primary-default align-middle"
            type="radio"
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            autoComplete={autoComplete}
          />
        </BaseControl>

        <span className="ml-2">{label}</span>
      </label>
    </>
  );
};

export default RadioButton;
