import { yupResolver } from '@hookform/resolvers/yup';
import { useGetOrgByTaxnum } from 'api/nav';
import { useUpdateOrganization } from 'api/organization';
import { FormOnSubmit, WithFormError } from 'components/ui/Form/Form';
import { SUCCESS_MESSAGES } from 'constants/messages';
import { VALIDATION_MESSAGES } from 'constants/validation';
import { useNotification } from 'contexts/notification-context';
import { useOrg } from 'contexts/org-context';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

export interface IOrgForm {
  taxNumber: string;
  name: string;
  shortName: string;
  zip: string;
  city: string;
  street: string;
  streetType: string;
  houseNumber: string;
  building: string;
  stairway: string;
  floor: string;
  door: string;
  lotNumber: string | null;
  email: string;
  navUser: string;
  navPassword: string;
  navSignKey: string;
  navSharedKey: string;
}

const validationSchema = Yup.object().shape({
  taxNumber: Yup.string()
    .required(VALIDATION_MESSAGES.required)
    .matches(/[0-9]{8}-[1-5]-[0-9]{2}/, VALIDATION_MESSAGES.invalid_tax_number),
  name: Yup.string().required(VALIDATION_MESSAGES.required),
  shortName: Yup.string(),
  zip: Yup.string()
    .required(VALIDATION_MESSAGES.required)
    .matches(/^[0-9]{4}$/, VALIDATION_MESSAGES.invalid_zip),
  city: Yup.string().required(VALIDATION_MESSAGES.required),
  street: Yup.string().required(VALIDATION_MESSAGES.required),
  streetType: Yup.string().required(VALIDATION_MESSAGES.required),
  houseNumber: Yup.string().required(VALIDATION_MESSAGES.required),
  email: Yup.string().required(VALIDATION_MESSAGES.required),
  navUser: Yup.string().required(VALIDATION_MESSAGES.required),
  navPassword: Yup.string().required(VALIDATION_MESSAGES.required),
  navSignKey: Yup.string().required(VALIDATION_MESSAGES.required),
  navSharedKey: Yup.string().required(VALIDATION_MESSAGES.required)
});

const useTrialHomePage = () => {
  const { org } = useOrg();
  const { addNotification } = useNotification();
  const formMethods = useForm<WithFormError<IOrgForm>>({
    defaultValues: {
      ...org
    },
    resolver: yupResolver(validationSchema)
  });

  const { mutate: updateOrganization, isLoading: isUpdateOrganizationLoading } =
    useUpdateOrganization();

  const taxNum = formMethods.watch('taxNumber');
  const { data: orgData, error } = useGetOrgByTaxnum(taxNum);

  useEffect(() => {
    if (org) {
      formMethods.reset(org);
    }
  }, [formMethods, org]);

  useEffect(() => {
    if (error && error.response?.data?.errors?.some((e) => e.match(/^TAX_INVALID.*/g))) {
      formMethods.setError('taxNumber', { message: 'Érvénytelen adószám' });
    }
  }, [error, formMethods]);

  useEffect(() => {
    if (orgData) {
      const { taxNumber, ...rest } = orgData;
      formMethods.reset({
        ...formMethods.getValues(),
        ...rest
      });
    }
  }, [formMethods, orgData]);

  const onSubmit: FormOnSubmit<IOrgForm> = (data) => {
    if (org) {
      updateOrganization(
        {
          ...org,
          ...data
        },
        {
          onSuccess: (response) => {
            if (response.navActive) {
              addNotification({
                message: SUCCESS_MESSAGES.NAV_SUCCESS,
                type: 'success'
              });
            } else {
              addNotification({
                message: SUCCESS_MESSAGES.NAV_UNSUCCESS,
                type: 'error'
              });
            }
          }
        }
      );
    }
  };

  return {
    formMethods,
    onSubmit,
    isLoading: isUpdateOrganizationLoading
  };
};

export default useTrialHomePage;
