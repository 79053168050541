import useChangePasswordPage from 'components/pages/ChangePasswordPage/useChangePasswordPage';
import Box from 'components/ui/Box/Box';
import Button from 'components/ui/Button/Button';
import PasswordInput from 'components/ui/Form/controls/PasswordInput/PasswordInput';
import Form from 'components/ui/Form/Form';
import PageTitle from 'components/ui/PageTitle/PageTitle';

const ChangePasswordPage = () => {
  const { formMethods, handleSubmit, isLoading } = useChangePasswordPage();
  return (
    <Box>
      <PageTitle>Új jelszó beállítása</PageTitle>
      <Form formMethods={formMethods} onSubmit={handleSubmit}>
        <PasswordInput name="password" label="Jelszó" />
        <PasswordInput name="passwordConfirm" label="Jelszó megerősítése" />
        <Button
          variant="default"
          color="lightBlue"
          type="submit"
          className="mt-4"
          loading={isLoading}
        >
          Jelszóváltoztatás
        </Button>
      </Form>
    </Box>
  );
};

export default ChangePasswordPage;
