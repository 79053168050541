import React from 'react';
import { ICON_MAP } from 'constants/icons';
import { mergeClassNames } from 'utils/helpers';

interface IProps {
  children?: React.ReactNode;
  color: ButtonColors;
  variant?: ButtonVariants;
  loading?: boolean;
  type?: 'button' | 'submit';
  fullWidth?: boolean;
  disabled?: boolean;
  icon?: keyof typeof ICON_MAP;
  iconPosition?: 'start' | 'end';
  className?: string;
  buttonClassName?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  iconClassName?: string;
  name?: string;
}

const BUTTON_CLASS = {
  darkBlue: {
    outline:
      'border-2 bg-white border-darkBlue-100 text-darkBlue-100 disabled:text-darkBlue-60 disabled:border-darkBlue-60',
    text: 'bg-transparent border-transparent text-darkBlue-100 disabled:text-darkBlue-60',
    default:
      'border-2 bg-darkBlue-100 border-darkBlue-100 text-white disabled:bg-darkBlue-60 disabled:border-darkBlue-60'
  },
  lightBlue: {
    outline:
      'border-2 bg-white border-lightBlue-100 text-lightBlue-100 disabled:text-lightBlue-60 disabled:border-lightBlue-60',
    text: 'bg-transparent border-transparent text-lightBlue-100 disabled:text-lightBlue-60',
    default:
      'border-2 bg-lightBlue-100 border-lightBlue-100 text-white disabled:bg-lightBlue-60 disabled:border-lightBlue-60'
  },
  success: {
    outline:
      'border-2 bg-white border-green-600 text-green-600 disabled:text-green-400 disabled:border-green-400',
    text: 'bg-transparent border-transparent text-green-600 disabled:text-green-400 hover:text-green-700',
    default:
      'border-2 bg-green-600 border-green-600 text-white disabled:bg-green-400 hover:bg-green-700 disabled:border-green-400'
  },
  danger: {
    outline:
      'border-2 bg-white border-red-600 text-red-600 disabled:text-red-400 disabled:border-red-400',
    text: 'bg-transparent border-transparent text-red-800 disabled:text-red-600 hover:text-red-900',
    default:
      'border-2 bg-red-600 border-red-600 text-white disabled:bg-red-400 hover:bg-red-900 disabled:border-red-400'
  },
  info: {
    outline:
      'border-2 bg-white border-gray-500 text-gray-500 disabled:text-gray-400 disabled:border-gray-400 hover:text-gray-600 hover:border-gray-600',
    text: 'bg-transparent border-transparent text-gray-500 disabled:text-gray-400 hover:text-gray-600',
    default:
      'border-2 bg-gray-500 border-gray-500 text-white disabled:bg-gray-400 hover:bg-gray-600 disabled:border-gray-400'
  }
} as const;

type ButtonVariants = 'default' | 'outline' | 'text';
type ButtonColors = keyof typeof BUTTON_CLASS;

const Button = ({
  children,
  color,
  variant = 'default',
  loading,
  fullWidth,
  disabled,
  type = 'button',
  icon,
  className = '',
  iconPosition = 'start',
  iconClassName,
  buttonClassName,
  onClick,
  name
}: IProps) => {
  const IconComponent = icon ? ICON_MAP[icon] : undefined;
  return (
    <div className={className}>
      <button
        onClick={onClick}
        className={mergeClassNames(
          'border disabled:cursor-not-allowed rounded-lg flex justify-center items-center py-2 px-6 w-full',
          BUTTON_CLASS[color][variant],
          buttonClassName,
          { 'w-full': fullWidth }
        )}
        disabled={disabled || loading}
        type={type}
        name={name}
      >
        {IconComponent && iconPosition === 'start' ? (
          <IconComponent className={mergeClassNames('h-5 w-5 mr-2', iconClassName)} />
        ) : null}
        {children}
        {IconComponent && iconPosition === 'end' ? (
          <IconComponent className={mergeClassNames('h-5 w-5 mr-2', iconClassName)} />
        ) : null}
      </button>
    </div>
  );
};

export default Button;
