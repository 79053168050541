import { ICON_MAP } from 'constants/icons';
import { useModal } from 'contexts/modal-context';

export interface IModalOverlay {
  children: React.ReactNode;
  closeable?: boolean;
}

const ModalOverlay = ({ children, closeable = true }: IModalOverlay) => {
  const { closeModal } = useModal();
  const IconComponent = ICON_MAP.close;

  return (
    <div
      className={`overflow-visible h-5/6 md:h-[600px] relative w-11/12 max-w-2xl rounded-2xl max-h-full`}
    >
      {closeable ? (
        <button
          className={`bg-none border-none text-darkBlue-100 text-3xl absolute top-2 right-2 cursor-pointer z-20`}
          onClick={closeModal}
        >
          <IconComponent className="h-6" />
        </button>
      ) : null}
      <div className="overflow-auto bg-white p-8 rounded-2xl">{children}</div>
    </div>
  );
};

export default ModalOverlay;
