import Checkbox from 'components/ui/Form/controls/Checkbox/Checkbox';
import { ICON_MAP } from 'constants/icons';
import { useOrg } from 'contexts/org-context';
import { Extra, Package, PaymentFrequency } from 'types/subscription';

interface IProps {
  pckg: Package;
  payFreq: PaymentFrequency;
  extras: Extra[];
}

const PackageCard = ({ pckg, payFreq, extras }: IProps) => {
  const { activeSubscription } = useOrg();
  const CheckIcon = ICON_MAP.checkMark;

  const ownedExtras = activeSubscription?.extra?.split(', ') || [];

  const extrasForCurrentPackage = extras.filter((e) =>
    e.packs.find((pckgId) => pckg.id === pckgId)
  );

  const price = pckg.prices.find((p) => p.freq === payFreq);

  if (!price) {
    return null;
  }

  return (
    <div className="flex flex-col justify-between">
      <div>
        <p className="text-darkBlue-60 font-semibold">{`${price.netto} Ft + áfa / hó`}</p>
        <h2 className="text-3xl text-darkBlue-100 font-semibold">{pckg.name}</h2>
        <ul className="mt-4 text-darkBlue-100">
          <li className="flex items-start">
            <div>
              <CheckIcon className="w-4 mt-1 text-green-500 mr-2" />
            </div>
            <div>{!pckg.tags ? 'Korlátlan számú címke' : `${pckg.tags} db címke`}</div>
          </li>
          <li className="flex items-start">
            <div>
              <CheckIcon className="w-4 mt-1 text-green-500 mr-2" />
            </div>
            <div>
              Lekérdezhető időszak: {pckg.months ? `${pckg.months} hónap` : 'korlátlan'}
            </div>
          </li>
        </ul>
        <p
          className={`${
            extrasForCurrentPackage.length ? 'text-darkBlue-60' : 'text-darkBlue-5'
          } font-semibold mt-4`}
        >
          Választható extrák
        </p>
        <ul className="list-none px-6">
          {extras.map((extra) => (
            <li
              key={extra.id}
              className={
                extrasForCurrentPackage.find((e) => e.id === extra.id)
                  ? 'text-darkBlue-100'
                  : 'text-darkBlue-5'
              }
            >
              <Checkbox
                name={`extra.${pckg.code}.${extra.code}`}
                label={
                  <span>
                    {extra.name}
                    <br />
                    (+{extra.prices[0].netto} Ft egyszeri díj)
                  </span>
                }
                disabled={!extrasForCurrentPackage.find((e) => e.id === extra.id)}
                defaultValue={!!ownedExtras?.includes(extra.code)}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PackageCard;
