import { useClient } from 'contexts/auth-context';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API_ROUTES } from 'constants/routes';
import { format } from 'date-fns';
import { API_CACHE } from 'constants/cache';
import { IOrganization } from 'types/organization';
import { AxiosError } from 'axios';
import { ErrorResponse } from 'api/api-client';
import { useNotification } from 'contexts/notification-context';

export interface IInvoicesListItem {
  id: number;
  taxNumber: 'string';
  incoming: boolean;
  startTime: Date;
  lastTime: Date;
  fromDate: Date;
  toDate: Date;
  status: 'DOWNLOADING' | 'IMPORTING' | 'FAILED' | 'DONE';
  countInvoices: number;
  countErrors: number;
  countPartners: number;
  countProducts: number;
}

const useGetInvoices = (orgId: number) => {
  const client = useClient();

  return useQuery([API_CACHE.INVOICES, { orgId }], () =>
    client<IInvoicesListItem[]>(API_ROUTES.GET_NAV_LIST, {
      method: 'get'
    })
  );
};

export interface IUseGetInvoicesRequest {
  from: Date;
  to: Date;
}

export interface IUseGetInvoicesResponse {
  status: IInvoicesListItem['status'];
  transactionId: string;
}

const useImportInvoices = () => {
  const client = useClient();
  const notification = useNotification();
  const queryClient = useQueryClient();

  return useMutation(
    ({ from, to }: IUseGetInvoicesRequest) => {
      const fromString = format(from, 'yyyy-MM-dd');
      const toString = format(to, 'yyyy-MM-dd');

      return client<IUseGetInvoicesResponse, { from: string; to: string }>(
        API_ROUTES.IMPORT_NAV_ASYNC,
        {
          method: 'post',
          data: {
            from: fromString,
            to: toString
          }
        }
      );
    },
    {
      onSuccess: (response) => {
        const wsProtocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
        let wsURI: string;
        if (process.env.NODE_ENV === 'development') {
          wsURI = `wss://onktg.intranet.parola.hu/onktg-web-gw-v1/websocket/${response.transactionId}`;
        } else {
          wsURI = `${wsProtocol}://${window.location.host}${process.env.REACT_APP_WS_URL}/${response.transactionId}`;
        }
        const webSocket = new WebSocket(wsURI);
        webSocket.onmessage = (message: MessageEvent<string>) => {
          const parsed = JSON.parse(message.data) as IUseGetInvoicesResponse;
          if (parsed.status === 'DONE') {
            notification.addNotification({
              type: 'success',
              message: 'A számlaletöltési folyamat sikeresen befejeződött!'
            });
          }
          if (parsed.status === 'FAILED') {
            notification.addNotification({
              type: 'success',
              message:
                'A számlaletöltési folyamat befejeződött! Nem minden számla került feldolgozásra!'
            });
          }
          webSocket.close();
          queryClient.invalidateQueries([API_CACHE.INVOICES]);
        };
      },
      onError: (error: any) => {
        if (error.response.data.errors.includes('INVALID_PARAMS')) {
          notification.addNotification({
            type: 'error',
            message: 'Nem megfelelő időszak!'
          });
        }
      }
    }
  );
};

const useGetOrgByTaxnum = (taxNum: string) => {
  const client = useClient();

  return useQuery<unknown, AxiosError<ErrorResponse>, Partial<IOrganization>>(
    [API_CACHE.ORG_BY_TAXNUM, { taxNum }],
    () =>
      client(`${API_ROUTES.GET_ORG_BY_TAXNUM}/${taxNum}`, {
        method: 'get'
      }),
    {
      onError: () => false,
      enabled: taxNum.match(/[0-9]{8}-[1-5]-[0-9]{2}/) !== null,
      suspense: false
    }
  );
};

export { useImportInvoices, useGetInvoices, useGetOrgByTaxnum };
