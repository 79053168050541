import Button from 'components/ui/Button/Button';
import PageTitle from 'components/ui/PageTitle/PageTitle';
import Table from 'components/ui/Table/Table';
import { useMemo } from 'react';
import { Column } from 'react-table';
import { IUsersListItem } from 'api/users';
import Box from 'components/ui/Box/Box';
import { ROLES_NAMES } from 'constants/roles';
import { USER_STATUS_NAMES } from 'constants/statuses';
import { useWindowSize } from 'hooks/useWindowSize';
import useUsersPage from './useUsersPage';

export type UsersTableListItem = IUsersListItem & { actions?: never };

const UsersPage = () => {
  const { data, isLoading, handleAddNewUser, handleEditUser } = useUsersPage();

  const { width } = useWindowSize();

  const columns = useMemo<Column<UsersTableListItem>[]>(
    () => [
      {
        accessor: 'name',
        Header: 'Név'
      },
      {
        accessor: 'email',
        Header: 'E-mail cím',
        hide: width !== undefined && width < 768
      },
      {
        accessor: 'role',
        Header: 'Szerepkör',
        Cell: ({ value }) => <span>{ROLES_NAMES[value]}</span>,
        hide: width !== undefined && width < 768
      },
      {
        accessor: 'status',
        Header: 'Státusz',
        Cell: ({ value }) => <span>{USER_STATUS_NAMES[value]}</span>,
        hide: width !== undefined && width < 768
      },
      {
        accessor: 'actions',
        Header: '',
        Cell: ({ row }) => (
          <Button
            color="info"
            variant="text"
            onClick={() => handleEditUser(row.original.id)}
            icon="edit"
          />
        )
      }
    ],
    [handleEditUser, width]
  );

  return (
    <div>
      <PageTitle>Felhasználókezelés</PageTitle>
      <Box>
        <Table<UsersTableListItem> columns={columns} data={data} isLoading={isLoading} />
        <Button
          color="lightBlue"
          type="submit"
          className="mt-4 md:w-fit"
          onClick={handleAddNewUser}
        >
          Új felhasználó
        </Button>
      </Box>
    </div>
  );
};

export default UsersPage;
