import useDeleteSubscriptionForm from 'components/pages/SubscriptionPage/components/DeleteSubscriptionForm/useDeleteSubscriptionForm';
import Button from 'components/ui/Button/Button';
import PasswordInput from 'components/ui/Form/controls/PasswordInput/PasswordInput';
import Switch from 'components/ui/Form/controls/Switch/Switch';
import Form from 'components/ui/Form/Form';
import ModalOverlay from 'components/ui/Overlays/ModalOverlay';

const DeleteSubscriptionForm = () => {
  const { formMethods, handleSubmit, isDeleteLoading } = useDeleteSubscriptionForm();
  return (
    <ModalOverlay>
      <div className="flex flex-col items-center">
        <Form formMethods={formMethods} onSubmit={handleSubmit}>
          <PasswordInput name="password" label="Jelszó" />
          <Switch
            name="immediately"
            offLabel="Aktív lejárat után"
            offValue={false}
            onLabel="Azonnal"
            onValue={true}
            label="Törlés ideje"
          />
          <Button
            variant="default"
            color="danger"
            type="submit"
            loading={isDeleteLoading}
          >
            Törlés
          </Button>
        </Form>
      </div>
    </ModalOverlay>
  );
};

export default DeleteSubscriptionForm;
