import PriceChangeBarChart from 'components/pages/PriceChangesPage/components/PriceChangeBarChart/PriceChangeBarChart';
import PriceChangeByProducts from 'components/pages/PriceChangesPage/components/PriceChangeByProducts/PriceChangeByProducts';
import PriceChangePieChart from 'components/pages/PriceChangesPage/components/PriceChangePieChart/PriceChangePieChart';
import usePriceChangesPage, {
  IPriceChangesFormValues
} from 'components/pages/PriceChangesPage/usePriceChangesPage';
import Alert from 'components/ui/Alert/Alert';
import Box from 'components/ui/Box/Box';
import Button from 'components/ui/Button/Button';
import DateRangeFilter from 'components/ui/DateRangeFilter/DateRangeFilter';
import RadioButton from 'components/ui/Form/controls/RadioButton/RadioButton';
import Select from 'components/ui/Form/controls/Select/Select';
import Form from 'components/ui/Form/Form';
import PageTitle from 'components/ui/PageTitle/PageTitle';
import SectionTitle from 'components/ui/SectionTitle/SectionTitle';

const PriceChangesPage = () => {
  const {
    handleSearch,
    formMethods,
    tagOptions,
    filters,
    hasNavConnection,
    hasValidSubscription,
    dateRange,
    firstFetch,
    minDate
  } = usePriceChangesPage();

  const disabled = !hasNavConnection || !hasValidSubscription;

  return (
    <>
      <PageTitle>Árváltozások</PageTitle>
      {!hasValidSubscription ? (
        <Alert variant="warning">
          A funkció használatához aktív előfizetés szükséges!
        </Alert>
      ) : null}
      {hasValidSubscription && !hasNavConnection ? (
        <Alert variant="warning">
          A funkció használatához aktív NAV kapcsolat szükséges!
        </Alert>
      ) : null}
      <Box>
        <Form<IPriceChangesFormValues> formMethods={formMethods} onSubmit={handleSearch}>
          <div className="flex flex-wrap">
            <div className="w-full flex flex-col md:flex-row justify-between">
              <SectionTitle>Szűrés</SectionTitle>
              <DateRangeFilter name="dateRange" minDate={minDate} />
            </div>
            <div className="w-full flex mt-2 flex-col md:flex-row">
              <Select
                name="tags"
                isMulti
                options={tagOptions}
                className="flex-1"
                disabled={disabled}
                placeholder="Válassz kategóriát!"
              />
              <div className="md:ml-auto flex flex-wrap">
                <RadioButton
                  name="price"
                  label="Bruttó áron"
                  value="GROSS"
                  disabled={disabled}
                />
                <RadioButton
                  name="price"
                  label="Nettó áron"
                  value="NET"
                  disabled={disabled}
                />
              </div>
              <Button
                type="submit"
                variant="default"
                color="lightBlue"
                disabled={disabled}
              >
                {firstFetch ? 'Megjelenít' : 'Frissít'}
              </Button>
            </div>
          </div>
        </Form>
      </Box>
      <Box>
        <PriceChangeByProducts filters={filters} dateRange={dateRange} />
      </Box>
      <Box>
        <PriceChangeBarChart filters={filters} />
      </Box>
      <Box>
        <PriceChangePieChart filters={filters} />
      </Box>
    </>
  );
};

export default PriceChangesPage;
