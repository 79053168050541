import {
  sub,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfQuarter,
  endOfQuarter
} from 'date-fns';
import { useFormContext } from 'react-hook-form';

interface IProps {
  name: string;
}

export type DateRangeFilterSubmit = ({
  from,
  to
}: {
  from: Date | null;
  to: Date | null;
}) => void;

export type DateRange = [Date | null, Date | null];

type RangeTypes =
  | 'last_week'
  | 'last_month'
  | 'last_quarter'
  | 'last_3_months'
  | 'last_7_days'
  | 'last_30_days'
  | 'last_90_days'
  | 'last_180_days';

export type Range = {
  from: () => Date;
  to: (from: Date) => Date;
  label: string;
};
export const RANGES: Record<RangeTypes, Range> = {
  last_week: {
    from: () =>
      sub(startOfWeek(new Date(), { weekStartsOn: 1 }), {
        weeks: 1
      }),
    to: (from) => endOfWeek(from, { weekStartsOn: 1 }),
    label: 'Előző hét'
  },
  last_month: {
    from: () => sub(startOfMonth(new Date()), { months: 1 }),
    to: (from) => endOfMonth(from),
    label: 'Előző hónap'
  },
  last_quarter: {
    from: () => sub(startOfQuarter(new Date()), { months: 3 }),
    to: (from) => endOfQuarter(from),
    label: 'Előző negyedév'
  },
  last_3_months: {
    from: () => sub(startOfMonth(new Date()), { months: 3 }),
    to: () => sub(endOfMonth(new Date()), { months: 1 }),
    label: 'Előző 3 hónap'
  },
  last_7_days: {
    from: () => sub(new Date(), { days: 7 }),
    to: () => sub(new Date(), { days: 1 }),
    label: 'Előző 7 nap'
  },
  last_30_days: {
    from: () => sub(new Date(), { days: 30 }),
    to: () => sub(new Date(), { days: 1 }),
    label: 'Előző 30 nap'
  },
  last_90_days: {
    from: () => sub(new Date(), { days: 90 }),
    to: () => sub(new Date(), { days: 1 }),
    label: 'Előző 90 nap'
  },
  last_180_days: {
    from: () => sub(new Date(), { days: 180 }),
    to: () => sub(new Date(), { days: 1 }),
    label: 'Előző 180 nap'
  }
};
export const RANGES_ON_UI: RangeTypes[] = [
  'last_7_days',
  'last_30_days',
  'last_90_days',
  'last_180_days'
];

const useDateRangeFilter = ({ name }: IProps) => {
  const { setValue } = useFormContext();

  const setRange = (rangeType: RangeTypes) => {
    const range = RANGES[rangeType];
    if (!range) throw new Error(`Range not found: ${rangeType}`);
    const from = range.from();
    const to = range.to(from);
    setValue(name, [from, to]);
  };

  return { setRange };
};

export default useDateRangeFilter;
