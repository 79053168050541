import Box from 'components/ui/Box/Box';
import SectionTitle from 'components/ui/SectionTitle/SectionTitle';
import { IconKeys, ICON_MAP } from 'constants/icons';
import { mergeClassNames } from 'utils/helpers';

interface IProps {
  icon: IconKeys;
  className: string;
  title: string;
  text: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const Tile = ({ icon, className, title, text, onClick }: IProps) => {
  const IconComponent = ICON_MAP[icon];
  return (
    <button onClick={onClick} className="w-full md:w-2/3 text-left">
      <Box>
        <div className="flex space-x-6">
          <div className={mergeClassNames(className, 'mt-2 w-10 flex justify-center')}>
            <IconComponent />
          </div>
          <div>
            <SectionTitle>{title}</SectionTitle>
            <p className="text-darkBlue-60">{text}</p>
          </div>
        </div>
      </Box>
    </button>
  );
};

export default Tile;
