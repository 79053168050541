import { useNotification } from 'contexts/notification-context';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { LOCAL_STORAGE_KEYS, removeItemFromStorage } from 'utils/local-storage';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ERROR_MESSAGES } from 'constants/messages';
import { IContextProvider } from './app-providers';

const queryClient = new QueryClient();

const ClientProvider = ({ children }: IContextProvider) => {
  const { addNotification } = useNotification();

  queryClient.setDefaultOptions({
    mutations: {
      onError: async (error: any) => {
        if (error.response.status === 401) {
          removeItemFromStorage(LOCAL_STORAGE_KEYS.LOCAL_STORAGE_AUTH_KEY);
          window.location.reload();
        }
        if (error.response.status === 403) {
          addNotification({ message: ERROR_MESSAGES.INSUFICCIENT_ROLE, type: 'error' });
        }
        if (error.response.data instanceof Blob) {
          const json = JSON.parse(await error.response.data.text());
          return json.errors.forEach((errorMessage: string) => {
            addNotification({ message: errorMessage, type: 'error' });
          });
        }
        return error.response.data.errors.forEach((errorMessage: string) => {
          addNotification({ message: errorMessage, type: 'error' });
        });
      }
    },
    queries: {
      refetchOnWindowFocus: false,
      suspense: true,
      retry: false,
      useErrorBoundary: (error: any) => error.response?.status >= 500,
      // useErrorBoundary: true,
      onError: (error: any) => {
        if (error.response.status === 401) {
          removeItemFromStorage(LOCAL_STORAGE_KEYS.LOCAL_STORAGE_AUTH_KEY);
          window.location.reload();
        }
        if (error.response.status === 403) {
          addNotification({ message: ERROR_MESSAGES.INSUFICCIENT_ROLE, type: 'error' });
        }
        return error.response.data.errors.forEach((errorMessage: string) => {
          addNotification({ message: errorMessage, type: 'error' });
        });
      }
    }
  });

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen />
    </QueryClientProvider>
  );
};

export default ClientProvider;
