import Button from 'components/ui/Button/Button';
import ModalOverlay from 'components/ui/Overlays/ModalOverlay';
import { useModal } from 'contexts/modal-context';

interface IProps {
  title: string;
  content: React.ReactNode;
  onConfirm: () => void;
  confirmText?: string;
}

const ConfirmationModal = ({ title, content, onConfirm, confirmText = 'OK' }: IProps) => {
  const { closeModal } = useModal();

  const handleConfirm = () => {
    onConfirm();
    closeModal();
  };

  return (
    <ModalOverlay>
      <h3 className="text-xl md:text-2xl font-bold mb-4">{title}</h3>
      {content}
      <div className="flex justify-end mt-4">
        <Button color="lightBlue" variant="outline" className="mr-4" onClick={closeModal}>
          Mégsem
        </Button>
        <Button color="lightBlue" onClick={handleConfirm}>
          {confirmText}
        </Button>
      </div>
    </ModalOverlay>
  );
};

export default ConfirmationModal;
