import { useUpdateOrganization } from 'api/organization';
import { FormOnSubmit, WithFormError } from 'components/ui/Form/Form';
import { SUCCESS_MESSAGES } from 'constants/messages';
import { useNotification } from 'contexts/notification-context';
import { useOrg } from 'contexts/org-context';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

export interface IOrgForm {
  email: string;
}

const useOrgPage = () => {
  const { addNotification } = useNotification();
  const { org } = useOrg();
  const formMethods = useForm<WithFormError<IOrgForm>>({
    defaultValues: {
      email: ''
    }
  });

  const { mutate: updateOrganization, isLoading: isUpdateOrganizationLoading } =
    useUpdateOrganization();

  useEffect(() => {
    if (org) {
      formMethods.reset(org);
    }
  }, [formMethods, org]);

  const onSubmit: FormOnSubmit<IOrgForm> = (data) => {
    if (org) {
      updateOrganization(
        {
          ...org,
          ...data
        },
        {
          onSuccess: () =>
            addNotification({
              type: 'success',
              message: SUCCESS_MESSAGES.SAVED
            })
        }
      );
    }
  };

  return {
    formMethods,
    onSubmit,
    isLoading: isUpdateOrganizationLoading
  };
};

export default useOrgPage;
