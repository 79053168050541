import * as Yup from 'yup';
import { useCikkExport, useCikkPrint } from 'api/export';
import { useGetTags } from 'api/tags';
import { SelectOptions } from 'components/ui/Form/controls/Select/Select';
import { WithFormError } from 'components/ui/Form/Form';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { VALIDATION_MESSAGES } from 'constants/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useOrg } from 'contexts/org-context';

export interface ICikkExportForm {
  cats: string[];
  rate: number;
  basedOnLast: string;
  gross: string;
  newOnly: boolean;
  partner: boolean;
}

const validationSchema = Yup.object().shape({
  cats: Yup.array().of(Yup.string()).min(1, VALIDATION_MESSAGES.required),
  rate: Yup.number().required(VALIDATION_MESSAGES.required),
  basedOnLast: Yup.string().required(VALIDATION_MESSAGES.required),
  gross: Yup.string().required(VALIDATION_MESSAGES.required),
  newOnly: Yup.boolean().required(VALIDATION_MESSAGES.required),
  partner: Yup.boolean().required(VALIDATION_MESSAGES.required)
});

export type ExportVariant = 'export' | 'print';

const useCikkExportPage = () => {
  const { mutate: cikkExportMutation } = useCikkExport();
  const { mutate: cikkPrintMutation } = useCikkPrint();
  const { data: tags } = useGetTags();
  const { activeSubscription } = useOrg();

  const tagOptions = useMemo<SelectOptions<string>[]>(() => {
    const result = [
      {
        value: '',
        label: 'Kategorizálatlan'
      }
    ];
    tags?.forEach(({ id, name }) => {
      result.push({
        label: name,
        value: name
      });
    });
    return result;
  }, [tags]);

  const formMethods = useForm<WithFormError<ICikkExportForm>>({
    defaultValues: {
      cats: [],
      rate: undefined,
      basedOnLast: 'true',
      gross: 'true',
      newOnly: true,
      partner: false
    },
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = (variant: ExportVariant) => {
    formMethods.handleSubmit((data) => {
      if (variant === 'export') {
        return cikkExportMutation(
          {
            ...data,
            basedOnLast: data.basedOnLast === 'true',
            gross: data.gross === 'true'
          },
          {
            onSuccess: (response) => {
              if (response && response.size > 0) {
                const today = format(new Date(), 'yyyyMMddHHmmss');
                const url = window.URL.createObjectURL(new Blob([response as Blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${today}_arvalt_cikklista.xlsx`);
                document.body.appendChild(link);
                link.click();
              }
            }
          }
        );
      }
      return cikkPrintMutation(
        {
          ...data,
          basedOnLast: data.basedOnLast === 'true',
          gross: data.gross === 'true'
        },
        {
          onSuccess: (response) => {
            if (response && response.size > 0) {
              const today = format(new Date(), 'yyyyMMddHHmmss');
              const url = window.URL.createObjectURL(new Blob([response as Blob]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `${today}_arvalt_cikklista.pdf`);
              document.body.appendChild(link);
              link.click();
            }
          }
        }
      );
    })();
  };

  const susbscribedToExport = activeSubscription?.extra?.split(', ').includes('PRODUCTS');

  return {
    tagOptions,
    formMethods,
    onSubmit,
    susbscribedToExport
  };
};

export default useCikkExportPage;
