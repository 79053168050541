import { yupResolver } from '@hookform/resolvers/yup';
import { IBaseFilters } from 'api/price-changes';
import { useGetTags } from 'api/tags';
import { DateRange } from 'components/ui/DateRangeFilter/useDateRangeFilter';
import { SelectOptions } from 'components/ui/Form/controls/Select/Select';
import { FormOnSubmit, WithFormError } from 'components/ui/Form/Form';
import { VALIDATION_MESSAGES } from 'constants/validation';
import { useOrg } from 'contexts/org-context';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

export interface IPriceChangesFormValues {
  dateRange: DateRange;
  tags: string[];
  price: 'GROSS' | 'NET';
}

const validationSchema = Yup.object().shape({
  dateRange: Yup.array().of(Yup.date()).min(2, VALIDATION_MESSAGES.required),
  tags: Yup.array().of(Yup.string()).min(1, VALIDATION_MESSAGES.required),
  price: Yup.string().oneOf(['GROSS', 'NET']).required(VALIDATION_MESSAGES.required)
});

const usePriceChangesPage = () => {
  const { org, hasValidSubscription, minDateForOperationsWithCurrentPackage } = useOrg();
  const [filters, setFilters] = useState<IBaseFilters>({
    from: null,
    to: null,
    tags: [],
    price: 'GROSS'
  });
  const [firstFetch, setFirstFetch] = useState<boolean>(true);
  const { data: tags } = useGetTags();
  const formMethods = useForm<WithFormError<IPriceChangesFormValues>>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      dateRange: [null, null],
      tags: [],
      price: 'GROSS'
    }
  });

  const handleSearch: FormOnSubmit<IPriceChangesFormValues> = (values) => {
    setFilters({
      from: values.dateRange[0],
      to: values.dateRange[1],
      tags: values.tags,
      price: values.price
    });
    setFirstFetch(false);
  };

  const tagOptions = useMemo<SelectOptions<string>[]>(
    () =>
      tags?.map(({ id, name }) => ({
        value: name,
        label: name
      })) || [],
    [tags]
  );

  const dateRange = formMethods.watch('dateRange');

  return {
    handleSearch,
    formMethods,
    tagOptions,
    filters,
    hasNavConnection: org.navActive,
    hasValidSubscription,
    dateRange,
    firstFetch,
    minDate: minDateForOperationsWithCurrentPackage
  };
};

export default usePriceChangesPage;
