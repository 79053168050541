import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGetCurrentUser, useUpdateProfile } from 'api/users';
import { FormOnSubmit, WithFormError } from 'components/ui/Form/Form';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { PASSWORD_STRENGTH_VALIDATOR, VALIDATION_MESSAGES } from 'constants/validation';
import { useNotification } from 'contexts/notification-context';
import { SUCCESS_MESSAGES } from 'constants/messages';

export interface IProfileForm {
  name: string;
  email: string;
  password: string;
  passwordConfirm: string;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required(VALIDATION_MESSAGES.required),
  email: Yup.string()
    .email(VALIDATION_MESSAGES.invalid_email)
    .required(VALIDATION_MESSAGES.required),
  password: Yup.string().matches(PASSWORD_STRENGTH_VALIDATOR, {
    message: VALIDATION_MESSAGES.weak_password,
    excludeEmptyString: true
  }),
  passwordConfirm: Yup.string().oneOf(
    [Yup.ref('password'), undefined],
    VALIDATION_MESSAGES.match_password
  )
});

const useProfilePage = () => {
  const formMethods = useForm<WithFormError<IProfileForm>>({
    defaultValues: {
      name: '',
      email: '',
      password: '',
      passwordConfirm: ''
    },
    resolver: yupResolver(validationSchema)
  });
  const { addNotification } = useNotification();

  const { mutate: updateProfile, isLoading: isUpdateProfileLoading } = useUpdateProfile();
  const { data: currentUser } = useGetCurrentUser();

  useEffect(() => {
    if (currentUser) {
      formMethods.reset({ name: currentUser.name, email: currentUser.email });
    }
  }, [formMethods, currentUser]);

  const onSubmit: FormOnSubmit<IProfileForm> = (data, setError) => {
    const { passwordConfirm, password, ...rest } = data;

    if (currentUser) {
      updateProfile(
        {
          ...rest,
          password: password || undefined
        },
        {
          onSuccess: () =>
            addNotification({ type: 'success', message: SUCCESS_MESSAGES.SAVED })
        }
      );
    }
  };

  return {
    formMethods,
    onSubmit,
    isLoading: isUpdateProfileLoading
  };
};

export default useProfilePage;
