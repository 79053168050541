import NumberInput from 'components/ui/Form/controls/NumberInput/NumberInput';
import PasswordInput from 'components/ui/Form/controls/PasswordInput/PasswordInput';
import TextInput from 'components/ui/Form/controls/TextInput/TextInput';
import Form from 'components/ui/Form/Form';
import PageTitle from 'components/ui/PageTitle/PageTitle';
import { PackageType } from 'types/subscription';

interface IProps {
  selectedPackage: PackageType;
}

const BaseForm = ({ selectedPackage }: IProps) => (
  <>
    <PageTitle>Alap adatok</PageTitle>
    <TextInput name="name" label="Név" />
    <TextInput type="email" name="email" label="E-mail cím" />
    <Form.Group>
      <PasswordInput name="password" label="Jelszó" className="w-full md:w-1/2" />
      <PasswordInput
        name="passwordConfirm"
        label="Jelszó megerősítése"
        className="w-full md:w-1/2"
      />
    </Form.Group>
    <Form.Group>
      <NumberInput
        name="org.taxNumber"
        label="Adószám"
        className="w-full md:w-1/2"
        format="########-#-##"
        mask="_"
      />
      <TextInput
        name="org.shortName"
        label="Adózó rövid neve"
        className="w-full md:w-1/2"
      />
    </Form.Group>
    <TextInput name="org.name" label="Adózó teljes neve" />
    <TextInput name="org.email" label="Számlázási email" />
  </>
);

export default BaseForm;
