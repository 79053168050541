import { AppRoutes } from 'constants/routes';
import { useAuth } from 'contexts/auth-context';
import { useOrg } from 'contexts/org-context';
import { useNavigate } from 'react-router-dom';

const useSubscribedHomePage = () => {
  const { user } = useAuth();
  const { org } = useOrg();
  const navigate = useNavigate();

  const handleTileClicked = (route: AppRoutes) => () => navigate(route);

  return {
    user,
    status: org?.navActive,
    handleTileClicked
  };
};

export default useSubscribedHomePage;
