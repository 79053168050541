import PageTitle from 'components/ui/PageTitle/PageTitle';
import Form from 'components/ui/Form/Form';
import TextInput from 'components/ui/Form/controls/TextInput/TextInput';
import Button from 'components/ui/Button/Button';
import Box from 'components/ui/Box/Box';
import useProfilePage from 'components/pages/ProfilePage/useProfilePage';
import PasswordInput from 'components/ui/Form/controls/PasswordInput/PasswordInput';

const ProfilePage = () => {
  const { formMethods, onSubmit, isLoading } = useProfilePage();

  return (
    <>
      <PageTitle>Felhasználói profil</PageTitle>
      <Box>
        <Form formMethods={formMethods} onSubmit={onSubmit}>
          <TextInput name={'name'} label={'Felhasználó neve'} />
          <TextInput name={'email'} label={'E-mail cím'} />
          <PasswordInput name={'password'} label={'Jelszó'} />
          <PasswordInput name={'passwordConfirm'} label={'Jelszó megerősítése'} />
          <Button
            color="lightBlue"
            type="submit"
            className="md:w-24 mt-4"
            loading={isLoading}
          >
            Mentés
          </Button>
        </Form>
      </Box>
    </>
  );
};

export default ProfilePage;
