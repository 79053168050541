import { useGetReferrals } from 'api/referrals';
import { FormOnSubmit, WithFormError } from 'components/ui/Form/Form';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

export interface IAffiliationForm {
  categories?: string[];
}

const useAffiliationPage = () => {
  const [filters, setFilters] = useState<IAffiliationForm>({});

  const { data, isLoading } = useGetReferrals();

  const formMethods = useForm<WithFormError<IAffiliationForm>>();

  const onSubmit: FormOnSubmit<IAffiliationForm> = () => {};

  return {
    data: data || [],
    isLoading,
    formMethods,
    onSubmit,
    setFilters
  };
};

export default useAffiliationPage;
