import { useUpdateOrganization } from 'api/organization';
import { FormOnSubmit, WithFormError } from 'components/ui/Form/Form';
import { SUCCESS_MESSAGES } from 'constants/messages';
import { useNotification } from 'contexts/notification-context';
import { useOrg } from 'contexts/org-context';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

export interface INavForm {
  navUser: string;
  navPassword: string;
  navSignKey: string;
  navSharedKey: string;
}

const useNavPage = () => {
  const { org } = useOrg();
  const { addNotification } = useNotification();
  const formMethods = useForm<WithFormError<INavForm>>({
    defaultValues: {
      navUser: '',
      navPassword: '',
      navSignKey: '',
      navSharedKey: ''
    }
  });

  const { mutate: updateOrganization, isLoading: isUpdateOrganizationLoading } =
    useUpdateOrganization();

  useEffect(() => {
    if (org) {
      formMethods.reset(org);
    }
  }, [formMethods, org]);

  const onSubmit: FormOnSubmit<INavForm> = (data) => {
    if (org) {
      updateOrganization(
        {
          ...org,
          ...data
        },
        {
          onSuccess: (response) => {
            if (response.navActive) {
              addNotification({
                message: SUCCESS_MESSAGES.NAV_SUCCESS,
                type: 'success'
              });
            } else {
              addNotification({
                message: SUCCESS_MESSAGES.NAV_UNSUCCESS,
                type: 'error'
              });
            }
          }
        }
      );
    }
  };

  return {
    formMethods,
    onSubmit,
    isLoading: isUpdateOrganizationLoading
  };
};

export default useNavPage;
