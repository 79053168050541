import Form from 'components/ui/Form/Form';
import TextInput from 'components/ui/Form/controls/TextInput/TextInput';
import Button from 'components/ui/Button/Button';
import PageTitle from 'components/ui/PageTitle/PageTitle';
import PasswordInput from 'components/ui/Form/controls/PasswordInput/PasswordInput';
import Box from 'components/ui/Box/Box';
import AppSkeleton from 'components/ui/AppSkeleton/AppSkeleton';
import useLoginPage, { ILoginForm } from './useLoginPage';

const LoginPage = () => {
  const {
    onSubmit,
    formMethods,
    isLoginLoading,
    width,
    handleLostPassword,
    isLostPassLoading
  } = useLoginPage();

  return (
    <>
      <div
        className={`${
          width && width >= 1000 ? 'w-1/2' : 'w-full'
        } h-full flex flex-col items-center justify-center`}
      >
        <div className="w-full max-w-[440px] text-center p-4">
          <Box>
            <PageTitle>Bejelentkezés</PageTitle>
            <Form<ILoginForm> formMethods={formMethods} onSubmit={onSubmit}>
              <TextInput
                name="email"
                label="E-mail cím"
                placeholder="gipsz.jakab@gmail.com"
              />
              <PasswordInput name="password" label="Jelszó" placeholder="······" />
              <div className="flex flex-wrap justify-between">
                <Button
                  color="lightBlue"
                  type="submit"
                  className="w-full mt-4"
                  icon="login"
                  loading={isLoginLoading}
                >
                  Bejelentkezés
                </Button>
              </div>
            </Form>
          </Box>
          <button
            className="text-darkBlue-40"
            onClick={handleLostPassword}
            disabled={isLostPassLoading}
          >
            Elfelejtettem a jelszavam
          </button>
        </div>
      </div>
      {width && width >= 1000 ? <AppSkeleton /> : null}
    </>
  );
};

export default LoginPage;
