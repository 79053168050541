import { useAuth } from 'contexts/auth-context';
import { useOrg } from 'contexts/org-context';

const useHomePage = () => {
  const { user } = useAuth();
  const { org, activeSubscription } = useOrg();

  const hasNoCompanyAndSubscription = !!(
    (!activeSubscription || activeSubscription.subType === 'FREE') &&
    (!org?.taxNumber || !org.taxNumber.match(/[0-9]{8}-[1-5]-[0-9]{2}/))
  );

  return {
    hasNoCompanyAndSubscription,
    user
  };
};

export default useHomePage;
