import { Link } from 'react-router-dom';
import { APP_ROUTES } from 'constants/routes';
import CompanyForm from 'components/ui/CompanyForm/CompanyForm';
import Box from 'components/ui/Box/Box';
import AppSkeleton from 'components/ui/AppSkeleton/AppSkeleton';
import PackageSelector from 'components/ui/PackageSelector/PackageSelector';
import Form, { FormOnSubmit } from 'components/ui/Form/Form';
import TextInput from 'components/ui/Form/controls/TextInput/TextInput';
import PasswordInput from 'components/ui/Form/controls/PasswordInput/PasswordInput';
import Button from 'components/ui/Button/Button';
import PageTitle from 'components/ui/PageTitle/PageTitle';
import useRegisterPage, { ITrialRegistrationFormValues } from './useRegisterPage';

const RegisterPage = () => {
  const {
    onSubmit,
    isCreateLoading,
    handleSelectPackage,
    selectedPackage,
    width,
    formMethods
  } = useRegisterPage();

  if (!selectedPackage) {
    return <PackageSelector onSubmit={handleSelectPackage} />;
  }

  return (
    <>
      <div
        className={`${
          width && width >= 1000 ? 'w-1/2' : 'w-full'
        } h-full flex flex-col items-center justify-start md:justify-center`}
      >
        <div className="w-full max-w-[700px] text-center p-4">
          <Box>
            {selectedPackage === 'FREE' ? (
              <Form<ITrialRegistrationFormValues>
                formMethods={formMethods}
                onSubmit={
                  onSubmit as unknown as FormOnSubmit<ITrialRegistrationFormValues>
                }
              >
                <PageTitle>Regisztráció (Próba verzió)</PageTitle>
                <TextInput name="name" label="Név" />
                <TextInput type="email" name="email" label="E-mail cím" />
                <Form.Group>
                  <PasswordInput
                    name="password"
                    label="Jelszó"
                    className="w-full md:w-1/2"
                  />
                  <PasswordInput
                    name="passwordConfirm"
                    label="Jelszó megerősítése"
                    className="w-full md:w-1/2"
                  />
                </Form.Group>
                <Button color="lightBlue" className="mt-8 flex-1" type="submit">
                  Regisztráció
                </Button>
              </Form>
            ) : (
              <CompanyForm
                onSubmit={onSubmit}
                isLoading={isCreateLoading}
                submitTitle="Regisztráció"
                selectedPackage={selectedPackage}
              />
            )}
          </Box>
          <Link className="text-darkBlue-40" to={APP_ROUTES.LOGIN}>
            Van már fiókod? Bejelentkezés.
          </Link>
        </div>
      </div>
      {width && width >= 1000 ? <AppSkeleton /> : null}
    </>
  );
};

export default RegisterPage;
