import { yupResolver } from '@hookform/resolvers/yup';
import { useDeleteSubscription } from 'api/subscriptions';
import { FormOnSubmit, WithFormError } from 'components/ui/Form/Form';
import { VALIDATION_MESSAGES } from 'constants/validation';
import { useAuth } from 'contexts/auth-context';
import { useModal } from 'contexts/modal-context';
import { useNotification } from 'contexts/notification-context';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

export interface IDeleteSubscriptionFormValues {
  password: string;
  immediately: boolean;
}

const validationSchema = Yup.object().shape({
  password: Yup.string().required(VALIDATION_MESSAGES.required)
});

const useDeleteSubscriptionForm = () => {
  const { closeModal } = useModal();
  const formMethods = useForm<WithFormError<IDeleteSubscriptionFormValues>>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      password: '',
      immediately: false
    }
  });
  const { mutate: deleteSubscription, isLoading: isDeleteLoading } =
    useDeleteSubscription();
  const { addNotification } = useNotification();
  const { logout } = useAuth();

  const handleSubmit: FormOnSubmit<IDeleteSubscriptionFormValues> = (data, setError) => {
    deleteSubscription(data, {
      onError: (error) => {
        setError('formError', {
          type: 'manual',
          message: (error as any).errors?.[0]
        });
      },
      onSuccess: () => {
        if (data.immediately) {
          logout();
        } else {
          addNotification({
            type: 'success',
            message: 'A törlési igény bejegyzésre került!'
          });
        }
        closeModal();
      }
    });
  };

  return { formMethods, handleSubmit, isDeleteLoading };
};

export default useDeleteSubscriptionForm;
