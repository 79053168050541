import classNamesOriginal, { Argument } from 'classnames';
import { overrideTailwindClasses } from 'tailwind-override';

// create a function which takes 2 arguments, and replaces the part in the first argument after : with the second argument
export const createUrlWithParam = (baseUrl: string, param: string | number) =>
  baseUrl.replace(/:.*/, param.toString());

export const mergeClassNames = (...args: Argument[]) =>
  overrideTailwindClasses(classNamesOriginal(...args));

/** CIF (Constraint Identity Function) helper */
export const createMaps = <ObjectMapType = Record<string, string>>(obj: ObjectMapType) =>
  obj;

// TODO - better typing (eg. return type)
/**
 * Function to look up with a string path the value of an object key N levels down.
 * @param obj any object
 * @param path string path with . separator, eg: 'billing.zip'
 * @returns the value if found the path in {@link obj}, undefined otherwise
 */
export const getDeepValue = <T extends Record<string, any>, K extends keyof T>(
  obj: T,
  path: string
): T[K] =>
  path
    .split('.')
    .reduce<any>(
      (acc, val) =>
        typeof acc === 'object' && acc !== null && Object.hasOwnProperty.call(acc, val)
          ? acc[val]
          : undefined,
      obj
    );

/**
 * Asserts is passed value is either number, or numeric string.
 * undefined can be allowed optionally.
 * @param value unknown value
 * @param allowUndefined if true, undefined is allowed
 */
export function assertNumeric(
  value: unknown,
  allowUndefined?: boolean
): asserts value is number {
  if (allowUndefined && value === undefined) return;
  if (typeof value === 'number') return;
  if (typeof value === 'string' && !Number.isNaN(Number(value))) return;
  throw new Error(`Value is not numeric: ${value}`);
}

/**
 * Generates a random password of length 8, with at least one lowercase letter, one uppercase letter and one number.
 */
export const generatePassword = () => {
  const CHARACTERS = 'abcdefghijklmnopqrstuvwxyz';
  const LENGTH = 8;

  let password = '';
  for (let i = 0; i < LENGTH; i++) {
    password += CHARACTERS.charAt(Math.random() * CHARACTERS.length);
  }

  const uc = Math.ceil(Math.random() * (LENGTH - 2));
  for (let j = 0; j < uc; j++) {
    const idx1 = Math.floor(Math.random() * (LENGTH - 1));
    password =
      password.substring(0, idx1) +
      password.charAt(idx1).toUpperCase() +
      password.substring(idx1 + 1);
  }

  const un = Math.ceil(Math.random() * (LENGTH - uc - 1));
  for (let k = 0; k < un; k++) {
    const idx2 = Math.floor(Math.random() * (LENGTH - 1));
    if (password.charAt(idx2) === password.charAt(idx2).toLowerCase()) {
      password =
        password.substring(0, idx2) +
        String(Math.floor(Math.random() * 10)) +
        password.substring(idx2 + 1);
    } else --k;
  }

  return password;
};

/**
 * Creates different random colors for an array of items
 * @param i index of the item in the array
 * @param length length of the array
 * @returns hsl color string
 */
export const generateColor = (i: number, length: number) => {
  const hue = Math.floor((i / length) * 341); // between 0 and 340
  let saturation = 100;
  let lightness = 50;

  if (hue > 215 && hue < 265) {
    const gain = 20;
    const blueness = 1 - Math.abs(hue - 240) / 25;
    const change = Math.floor(gain * blueness);
    lightness += change;
    saturation -= change;
  }

  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};

export const formatNumberToCurrency = (value: string | number) => {
  try {
    assertNumeric(value);
    return Intl.NumberFormat('hu-HU', {
      style: 'currency',
      currency: 'HUF',
      maximumFractionDigits: 0
    })
      .format(Math.round(value))
      .replaceAll('\0xa0', ' ');
  } catch (err) {
    return `${value}`;
  }
};

export const isTypeUnion = <T>(
  string: string,
  guard: (target: unknown) => boolean
): T | null => {
  if (guard(string)) {
    return string as unknown as T;
  }
  const errorMsg = `Invalid type union value: ${string}`;
  if (process.env.NODE_ENV === 'development') {
    throw new Error(errorMsg);
  }
  console.error(errorMsg);
  return null;
};
