import { IPartner, useGetPartnersRange } from 'api/partners';
import { IProduct, useGetPartnerRangeProducts } from 'api/products';
import { useGetTags } from 'api/tags';
import { DateRange } from 'components/ui/DateRangeFilter/useDateRangeFilter';
import { SelectOptions } from 'components/ui/Form/controls/Select/Select';
import { FormOnSubmit, WithFormError } from 'components/ui/Form/Form';
import { useOrg } from 'contexts/org-context';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

const useCategorizationPage = () => {
  const formMethods = useForm<WithFormError<{ dateRange: DateRange }>>({
    defaultValues: {
      dateRange: [null, null]
    }
  });
  const { hasValidSubscription, org, minDateForOperationsWithCurrentPackage } = useOrg();
  const [dateRange, setDateRage] = useState<DateRange>([null, null]);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [selectedPartner, setSelectedPartner] = useState<IPartner | null>(null);
  const [editSelected, setEditSelected] = useState<boolean>(false);
  const [options, setOptions] = useState<SelectOptions<string>[]>([]);
  const { data: tags } = useGetTags();
  const { data: partners, refetch: fetchPartnersRange } = useGetPartnersRange({
    from: dateRange[0],
    to: dateRange[1]
  });
  const { mutate: getProducts } = useGetPartnerRangeProducts();

  const fetchPartnerProducts = useCallback(() => {
    if (!selectedPartner || !dateRange[0] || !dateRange[1]) return;
    getProducts(
      { from: dateRange[0], to: dateRange[1], partnerId: selectedPartner.id },
      { onSuccess: (response) => setProducts(response) }
    );
  }, [dateRange, getProducts, selectedPartner]);

  useEffect(() => {
    setOptions(tags?.map((tag) => ({ label: tag.name, value: tag.name })) ?? []);
  }, [tags]);

  useEffect(() => {
    fetchPartnerProducts();
  }, [fetchPartnerProducts, selectedPartner]);

  const handleSearch: FormOnSubmit<{ dateRange: DateRange }> = (values) => {
    const [from, to] = values.dateRange;
    setDateRage([from, to]);
    setTimeout(() => {
      fetchPartnersRange();
    }, 0);
  };

  const toggleEdit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    partner: IPartner
  ) => {
    e.stopPropagation();
    setSelectedPartner(partner);
    if (partner === selectedPartner) {
      setEditSelected((prev) => !prev);
    } else {
      setEditSelected(true);
    }
  };

  const handleSelectPartner = (partner: IPartner) => {
    setSelectedPartner(partner);
    setEditSelected(false);
  };

  return {
    tags,
    partners,
    products,
    fetchPartnersRange,
    fetchPartnerProducts,
    handleSearch,
    handleSelectPartner,
    selectedPartner,
    options,
    formMethods,
    editSelected,
    toggleEdit,
    hasValidSubscription,
    hasNavConnection: org.navActive,
    minDate: minDateForOperationsWithCurrentPackage
  };
};

export default useCategorizationPage;
