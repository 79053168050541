import { useClient } from 'contexts/auth-context';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API_ROUTES } from 'constants/routes';
import { UserStatus } from 'types/status';
import { createUrlWithParam } from 'utils/helpers';
import { API_CACHE } from '../constants/cache';
import { IUser, Roles } from '../types/user';

export interface IUsersListItem {
  id: number;
  name: string;
  email: string;
  role: Roles;
  status: UserStatus;
}

const useGetUsers = () => {
  const client = useClient();

  return useQuery([API_CACHE.USERS], () =>
    client<IUsersListItem[]>(API_ROUTES.GET_USERS_LIST, { method: 'get' })
  );
};

const useGetUserById = (userId: number) => {
  const client = useClient();

  return useQuery(
    [API_CACHE.USER, { userId }],
    () =>
      client<IUsersListItem>(createUrlWithParam(API_ROUTES.GET_USER_BY_ID, userId), {
        method: 'get'
      }),
    {
      enabled: !!userId
    }
  );
};

const useGetCurrentUser = () => {
  const client = useClient();

  return useQuery([API_CACHE.CURRENT_USER], () =>
    client<IUser>(API_ROUTES.GET_CURRENT_USER, {
      method: 'get'
    })
  );
};

export type CreateUserDto = Pick<IUsersListItem, 'email' | 'name' | 'role' | 'status'> & {
  password: string;
};
const useCreateUser = () => {
  const client = useClient();

  return useMutation([API_CACHE.USERS], (request: CreateUserDto) =>
    client(API_ROUTES.CREATE_USERS, { data: request, method: 'post' })
  );
};

const useDeleteUser = () => {
  const client = useClient();

  return useMutation([API_CACHE.USERS], (id: number) =>
    client(API_ROUTES.DELETE_USERS + { id }, { method: 'delete' })
  );
};

export type UpdateUserDto = Pick<IUsersListItem, 'email' | 'name' | 'role' | 'id'> & {
  password?: string;
};
const useUpdateUser = () => {
  const client = useClient();

  return useMutation([API_CACHE.USERS], (request: UpdateUserDto) =>
    client(API_ROUTES.UPDATE_USERS, { data: request, method: 'patch' })
  );
};

export type UpdateProfileDto = Pick<IUsersListItem, 'email' | 'name'> & {
  password?: string;
};

const useUpdateProfile = () => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    (request: UpdateProfileDto) =>
      client(API_ROUTES.UPDATE_PROFILE, { data: request, method: 'patch' }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([API_CACHE.CURRENT_USER]);
      }
    }
  );
};

export {
  useGetUsers,
  useGetUserById,
  useCreateUser,
  useDeleteUser,
  useUpdateUser,
  useUpdateProfile,
  useGetCurrentUser
};
