interface IProps {
  children: React.ReactNode;
}

const PageTitle = ({ children }: IProps) => (
  <h1 className="text-xl md:text-3xl font-semibold text-darkBlue-100 mt-4 mb-8">
    {children}
  </h1>
);

export default PageTitle;
