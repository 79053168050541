import { ISubscriptionsListItem } from 'api/admin';
import { FormOnSubmit, WithFormError } from 'components/ui/Form/Form';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

export interface IAdminFilter {
  orgName?: string;
  status?: string[];
  dateFrom?: Date;
  dateTo?: Date;
}

const useAdminPage = () => {
  const [filters, setFilters] = useState<IAdminFilter>({});
  // const { data: subscriptions, isLoading } = useGetSubscriptions();
  const tableData: ISubscriptionsListItem[] = [];

  const filterFormMethods = useForm<WithFormError<IAdminFilter>>({
    defaultValues: {}
  });
  const tableFormMethods = useForm<WithFormError<{ data: ISubscriptionsListItem[] }>>({
    defaultValues: {
      data: tableData
    }
  });

  const onSubmit: FormOnSubmit<IAdminFilter> = (data) => {
    console.log(data);
  };

  return {
    data: tableData,
    isLoading: false,
    filterFormMethods,
    tableFormMethods,
    onSubmit,
    setFilters
  };
};

export default useAdminPage;
