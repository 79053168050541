import { useActivateAccount } from 'api/accounts';
import { useAuth } from 'contexts/auth-context';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const useActivationPage = () => {
  const { token } = useParams<string>();
  const { login } = useAuth();
  const { mutate: activate, isLoading, isError } = useActivateAccount();

  useEffect(() => {
    if (!token) return;
    activate(
      { token },
      {
        onSuccess: (response) => login(response.token)
      }
    );
  }, [activate, login, token]);

  return { isLoading, isError };
};

export default useActivationPage;
