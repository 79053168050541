import DeleteSubscriptionForm from 'components/pages/SubscriptionPage/components/DeleteSubscriptionForm/DeleteSubscriptionForm';
import useSubscriptionPage from 'components/pages/SubscriptionPage/useSubscriptionPage';
import Alert from 'components/ui/Alert/Alert';
import Box from 'components/ui/Box/Box';
import Button from 'components/ui/Button/Button';
import Switch from 'components/ui/Form/controls/Switch/Switch';
import Form from 'components/ui/Form/Form';
import ConfirmationModal from 'components/ui/Overlays/ConfirmationModal';
import PackageCard from 'components/ui/PackageSelector/components/PackageCard/PackageCard';
import PageTitle from 'components/ui/PageTitle/PageTitle';
import SectionTitle from 'components/ui/SectionTitle/SectionTitle';
import { formatDate } from 'utils/dates';
import { mergeClassNames } from 'utils/helpers';

const SubscriptionPage = () => {
  const {
    handleSelectPackage,
    selectedPackage,
    packages,
    formMethods,
    activeSubscription,
    hasActiveSubscription,
    allowedPackages,
    handleSubmit,
    isSubmitLoading,
    openModalWithContent,
    payFreq,
    handleRenew,
    isRenewLoading,
    hasNoCompany,
    formDisabled,
    extras,
    isRecurring,
    handleRemoveRecurring,
    isRemoveLoading
  } = useSubscriptionPage();

  const handleRemoveRecurringClick = () => {
    openModalWithContent(
      <ConfirmationModal
        content="Valóban törli a kártyaregisztrációját és az automatikus kiegyenlítés lehetőségét?"
        title="Figyelem!"
        onConfirm={handleRemoveRecurring}
      />
    );
  };

  return (
    <>
      <PageTitle>Előfizetés</PageTitle>
      {hasNoCompany ? (
        <Alert variant="warning">Érvényes cégadatok megadása szükséges!</Alert>
      ) : null}
      {activeSubscription?.status === 'UNPAID' ? (
        <Alert variant="warning">
          Be nem fejezett fizetési tranzakcióval rendelkezik!
        </Alert>
      ) : null}
      {hasActiveSubscription ? (
        <Box>
          <div className="flex flex-col md:flex-row justify-between">
            <div>
              <SectionTitle>Érvényesség ideje</SectionTitle>
              <p className="text-darkBlue-60">
                {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  formatDate(activeSubscription!.validity)
                }
              </p>
            </div>
            <div>
              {isRecurring ? (
                <div className="flex flex-col items-end">
                  <p>
                    Ismétlődő fizetés beállítva{' '}
                    <span className="font-bold">
                      {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        formatDate(activeSubscription!.cardExpiry)
                      }
                    </span>
                    -ig. Kártyaszám:{' '}
                    <span className="font-bold">
                      {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        activeSubscription!.cardNumber
                      }
                    </span>
                    .
                  </p>
                  <Button
                    variant="default"
                    color="lightBlue"
                    onClick={handleRemoveRecurringClick}
                    loading={isRemoveLoading}
                  >
                    Ismétlődő fizetés törlése
                  </Button>
                </div>
              ) : (
                <Button
                  variant="default"
                  color="lightBlue"
                  onClick={handleRenew}
                  loading={isRenewLoading}
                  disabled={formDisabled}
                >
                  Hosszabbítás
                </Button>
              )}
            </div>
          </div>
        </Box>
      ) : null}
      <Form formMethods={formMethods} onSubmit={() => false}>
        <Box>
          <SectionTitle>Csomagjaink</SectionTitle>
          <div className="flex justify-end mb-2">
            {!hasActiveSubscription ? (
              <Switch
                name="payFreq"
                onLabel="Éves előfizetés"
                offLabel="Havi előfizetés"
                onValue="YEAR"
                offValue="MONTH"
                disabled={formDisabled}
              />
            ) : null}
          </div>
          <div className="grid grid-rows-3 md:grid-rows-1 md:grid-cols-3 gap-4">
            {packages?.map((pckg) => {
              const disabled = !allowedPackages.includes(pckg.code);

              return (
                <button
                  className={mergeClassNames(
                    'border-2  p-8 rounded-xl text-left border-gray-200 relative',
                    selectedPackage?.code === pckg.code && 'border-darkBlue-100',
                    disabled && 'cursor-not-allowed'
                  )}
                  key={pckg.name}
                  onClick={() => handleSelectPackage(pckg)}
                  disabled={disabled || formDisabled}
                >
                  {activeSubscription?.subType === pckg.code ? (
                    <div className="bg-lightBlue-100 text-white absolute -top-0.5 text-center rounded-b-md px-4 py-0.5 left-1/2 -translate-x-1/2 whitespace-nowrap">
                      Jelenlegi csomag
                    </div>
                  ) : null}
                  <div className={disabled ? 'opacity-40' : ''}>
                    <PackageCard pckg={pckg} payFreq={payFreq} extras={extras} />
                  </div>
                </button>
              );
            })}
          </div>
          <div className="flex flex-col-reverse md:flex-row gap-4 mt-4">
            <Button
              variant="outline"
              color="danger"
              onClick={() => {
                openModalWithContent(<DeleteSubscriptionForm />);
              }}
            >
              Regisztráció törlése
            </Button>
            <Button
              variant="default"
              color="lightBlue"
              onClick={handleSubmit}
              loading={isSubmitLoading}
              disabled={formDisabled}
            >
              {hasActiveSubscription ? 'Módosítás' : 'Előfizetés'}
            </Button>
          </div>
        </Box>
      </Form>
    </>
  );
};

export default SubscriptionPage;
