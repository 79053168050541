import useUserForm from 'components/pages/UsersPage/UserForm/useUserForm';
import Box from 'components/ui/Box/Box';
import Button from 'components/ui/Button/Button';
import PasswordInput from 'components/ui/Form/controls/PasswordInput/PasswordInput';
import Select from 'components/ui/Form/controls/Select/Select';
import TextInput from 'components/ui/Form/controls/TextInput/TextInput';
import Form from 'components/ui/Form/Form';
import ConfirmationModal from 'components/ui/Overlays/ConfirmationModal';
import PageTitle from 'components/ui/PageTitle/PageTitle';
import { ROLES_OPTIONS } from 'constants/roles';
import { USER_STATUS_OPTIONS } from 'constants/statuses';

const UserForm = () => {
  const {
    formMethods,
    handleCancel,
    handleSubmit,
    isSubmitLoading,
    user,
    creatAutoPassword,
    openModalWithContent,
    userId
  } = useUserForm();

  const confirmCancel = () => {
    openModalWithContent(
      <ConfirmationModal
        title="Megerősítés szükséges!"
        content={`Biztosan megszakítja ${userId ? 'a' : 'az új'} felhasználó ${
          userId ? 'szerkesztését' : 'felvételét'
        }?`}
        onConfirm={handleCancel}
        confirmText="Megerősít"
      />
    );
  };

  return (
    <>
      <PageTitle>Felhasználókezelés</PageTitle>
      <Box>
        <div className="max-w-xl">
          <Form formMethods={formMethods} onSubmit={handleSubmit}>
            <TextInput name="name" label="Név" />
            <TextInput name="email" label="Email" />
            <Select
              name="role"
              label="Szerepkör"
              options={ROLES_OPTIONS}
              disabled={user?.role === 'ADMIN'}
            />
            {user && user.status !== 'PENDING' ? (
              <Select
                name="status"
                label="Státusz"
                options={USER_STATUS_OPTIONS}
                isClearable={false}
              />
            ) : null}
            <Form.Group>
              <PasswordInput name="password" label="Jelszó" className="flex-1" />
              <Button
                color="lightBlue"
                variant="default"
                type="button"
                className="mt-8"
                onClick={creatAutoPassword}
              >
                Generálás
              </Button>
            </Form.Group>
            <div className="mt-8">
              <Form.Group>
                <Button
                  color="lightBlue"
                  variant="outline"
                  type="button"
                  onClick={confirmCancel}
                >
                  Mégsem
                </Button>
                <Button
                  color="lightBlue"
                  variant="default"
                  type="submit"
                  loading={isSubmitLoading}
                >
                  Mentés
                </Button>
              </Form.Group>
            </div>
          </Form>
        </div>
      </Box>
    </>
  );
};

export default UserForm;
