import { PartnerOrProduct } from 'components/pages/CategorizationPage/components/CategoryFilters/CategoryFilters';
import { WithFormError } from 'components/ui/Form/Form';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';

export interface ICategoryFilterFormValues {
  tags: string[] | null;
  name: string;
}

interface IProps<T> {
  data: T;
  onChange: (filteredData: T) => void;
}

// Return elements of array a that are also in b in linear time:
function intersect(a: string[], b: string[]) {
  return a.filter(Set.prototype.has, new Set(b));
}

export const UNCATEGORIZED_VALUE = 'UNCATEGORIZED_VALUE';

export const useCategoryFilter = <T extends PartnerOrProduct[]>({
  data,
  onChange
}: IProps<T>) => {
  const formMethods = useForm<WithFormError<ICategoryFilterFormValues>>({
    defaultValues: {
      tags: null,
      name: ''
    }
  });

  useEffect(() => {
    formMethods.reset();
  }, [data, formMethods]);

  const { name, tags } = formMethods.watch();

  const handleFilterChange = useDebouncedCallback(
    (values: ICategoryFilterFormValues) => {
      const filtered = data
        .filter(
          (row) =>
            !values.name || row.name.toLowerCase().includes(values.name.toLowerCase())
        )
        .filter((row) => {
          if (!values.tags?.length) return true;
          if (values.tags.includes(UNCATEGORIZED_VALUE) && row.tags.length === 0)
            return true;
          return intersect(values.tags, row.tags).length;
        });

      onChange(filtered as T);
    },
    300,
    { leading: false, trailing: true }
  );

  useEffect(() => {
    handleFilterChange({
      name,
      tags
    });
  }, [handleFilterChange, name, tags, data]);

  return { formMethods, handleFilterChange };
};

export default useCategoryFilter;
