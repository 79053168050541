import { ArrowRightIcon, LoginIcon } from '@heroicons/react/outline';
import HomeIcon from 'components/ui/Icons/HomeIcon/HomeIcon';
import InvoicesIcon from 'components/ui/Icons/InvoicesIcon/InvoicesIcon';
import SettingsIcon from 'components/ui/Icons/SettingsIcon/SettingsIcon';
import AdminIcon from 'components/ui/Icons/AdminIcon/AdminIcon';
import ExportIcon from 'components/ui/Icons/ExportIcon/ExportIcon';
import AffiliationIcon from 'components/ui/Icons/AffiliationIcon/AffiliationIcon';
import CloseIcon from 'components/ui/Icons/CloseIcon/CloseIcon';
import LogoutIcon from 'components/ui/Icons/Logout/Logout';
import CategoriesIcon from 'components/ui/Icons/CategoriesIcon/CategoriesIcon';
import PriceChangesIcon from 'components/ui/Icons/PriceChangesIcon/PriceChangesIcon';
import CheckMarkIcon from 'components/ui/Icons/CheckMarkIcon/CheckMarkIcon';
import EditIcon from 'components/ui/Icons/EditIcon/EditIcon';
import NavOkIcon from 'components/ui/Icons/NavOkIcon/NavOkIcon';
import ChevronUp from 'components/ui/Icons/ChevronUp/ChevronUp';
import ChevronDown from 'components/ui/Icons/ChevronDown/ChevronDown';
import StoreFrontIcon from 'components/ui/Icons/StoreFrontIcon/StoreFrontIcon';

export type IconProp = {
  className?: string;
};

export const ICON_MAP = {
  login: LoginIcon,
  demo_register: ArrowRightIcon,
  home: HomeIcon,
  invoices: InvoicesIcon,
  categories: CategoriesIcon,
  pricechanges: PriceChangesIcon,
  settings: SettingsIcon,
  export: ExportIcon,
  affiliation: AffiliationIcon,
  admin: AdminIcon,
  close: CloseIcon,
  logout: LogoutIcon,
  checkMark: CheckMarkIcon,
  edit: EditIcon,
  navok: NavOkIcon,
  chevronUp: ChevronUp,
  chevronDown: ChevronDown,
  storeFront: StoreFrontIcon
} as const;
export type IconKeys = keyof typeof ICON_MAP;
