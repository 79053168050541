import { IconProp } from 'constants/icons';

const ExportIcon = (props: IconProp) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 18" {...props}>
    <path
      fill="currentColor"
      d="M4 0L0 3.99H3V11H5V3.99H8L4 0ZM11 14.01V7H9V14.01H6L10 18L14 14.01H11Z"
    />
  </svg>
);

export default ExportIcon;
