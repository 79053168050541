import * as Yup from 'yup';
import { FormOnSubmit, WithFormError } from 'components/ui/Form/Form';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { VALIDATION_MESSAGES, PASSWORD_STRENGTH_VALIDATOR } from 'constants/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { APP_ROUTES } from 'constants/routes';
import { useChangePassword } from 'api/accounts';
import { useNotification } from 'contexts/notification-context';

export interface IChangePasswordFormValues {
  password: string;
  passwordConfirm: string;
}

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required(VALIDATION_MESSAGES.required)
    .matches(PASSWORD_STRENGTH_VALIDATOR, VALIDATION_MESSAGES.weak_password),
  passwordConfirm: Yup.string()
    .required(VALIDATION_MESSAGES.required)
    .oneOf([Yup.ref('password'), null], VALIDATION_MESSAGES.match_password)
});

const useChangePasswordPage = () => {
  const [code, setCode] = useState<string>('');
  const location = useLocation();
  const navigate = useNavigate();
  const formMethods = useForm<WithFormError<IChangePasswordFormValues>>({
    defaultValues: {
      password: '',
      passwordConfirm: ''
    },
    resolver: yupResolver(validationSchema)
  });
  const { mutate: setNewPassword, isLoading } = useChangePassword();
  const { addNotification } = useNotification();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tokenFromParams = params.get('token');
    if (!tokenFromParams) {
      navigate(APP_ROUTES.LOGIN);
      return;
    }
    setCode(tokenFromParams);
  }, [location.search, navigate]);

  const handleSubmit: FormOnSubmit<IChangePasswordFormValues> = (data, setError) => {
    setNewPassword(
      {
        code,
        password: data.password
      },
      {
        onSuccess: () => {
          addNotification({
            type: 'success',
            message: 'Jelszó sikeresen megváltoztatva'
          });
          navigate(APP_ROUTES.LOGIN);
        }
      }
    );
  };

  return { formMethods, handleSubmit, isLoading };
};

export default useChangePasswordPage;
