import { useRef, useState, useCallback, useEffect } from 'react';

export function useCountdown(timeout: number, autoStart = true) {
  const intervalId = useRef<NodeJS.Timer | null>(null);
  const [timeLeft, setTimeLeft] = useState<number>(timeout);

  const stopCounter = useCallback(() => {
    if (intervalId?.current) clearInterval(intervalId.current);
  }, [intervalId]);

  const startCounter = useCallback(() => {
    intervalId.current = setInterval(() => {
      setTimeLeft((currentTimeLeft) => {
        if (currentTimeLeft > 1) {
          return currentTimeLeft - 1;
        }

        stopCounter();
        return 0;
      });
    }, 1000);
  }, [stopCounter]);

  const restartCounter = () => {
    stopCounter();
    setTimeLeft(timeout);
    startCounter();
  };

  useEffect(() => {
    if (autoStart) {
      startCounter();
    }

    return () => {
      if (autoStart) {
        stopCounter();
      }
    };
  }, [autoStart, startCounter, stopCounter]);

  return {
    stopCounter,
    startCounter,
    restartCounter,
    timeLeft
  };
}
