import { useQuery } from '@tanstack/react-query';
import { client } from 'api/api-client';
import { API_CACHE } from 'constants/cache';
import { API_ROUTES } from 'constants/routes';
import { Extra, Package } from 'types/subscription';

const useGetPackages = () =>
  useQuery([API_CACHE.PACKAGES], () =>
    client<Package[]>(API_ROUTES.GET_PACKAGES, { method: 'get' }).then((packages) =>
      packages.sort((a, b) => {
        const x = a.prices.find((p) => p.freq === 'MONTH');
        const y = b.prices.find((p) => p.freq === 'MONTH');

        if (!x || !y) return 0;

        return x?.gross > y?.gross ? 1 : -1;
      })
    )
  );

const useGetExtras = () =>
  useQuery([API_CACHE.EXTRAS], () =>
    client<Extra[]>(API_ROUTES.GET_EXTRAS, { method: 'get' })
  );

export { useGetPackages, useGetExtras };
