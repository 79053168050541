import { useClient } from 'contexts/auth-context';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API_ROUTES } from 'constants/routes';
import { API_CACHE } from 'constants/cache';

export interface ITagListItem {
  id: number;
  name: string;
}

const useGetTags = () => {
  const client = useClient();

  return useQuery([API_CACHE.TAGS], () =>
    client<ITagListItem[]>(API_ROUTES.GET_TAGS_LIST, { method: 'get' })
  );
};

const useAddPartnerTag = () => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, { id: number; name: string }>(
    ({ id, name }) =>
      client(API_ROUTES.ADD_PARTNER_TAG, {
        method: 'post',
        data: {
          id,
          name
        }
      }),
    {
      onSuccess: () => queryClient.invalidateQueries([API_CACHE.TAGS])
    }
  );
};

const useAddProductTag = () => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, { id: number; name: string }>(
    ({ id, name }) =>
      client(API_ROUTES.ADD_PRODUCT_TAG, {
        method: 'post',
        data: {
          id,
          name
        }
      }),
    {
      onSuccess: () => queryClient.invalidateQueries([API_CACHE.TAGS])
    }
  );
};

const useRemovePartnerTag = () => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, { id: number; name: string }>(
    ({ id, name }) =>
      client(API_ROUTES.REMOVE_PARTNER_TAG, {
        method: 'post',
        data: {
          id,
          name
        }
      }),
    {
      onSuccess: () => queryClient.invalidateQueries([API_CACHE.TAGS])
    }
  );
};

const useRemoveProductTag = () => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, { id: number; name: string }>(
    ({ id, name }) =>
      client(API_ROUTES.REMOVE_PRODUCT_TAG, {
        method: 'post',
        data: {
          id,
          name
        }
      }),
    {
      onSuccess: () => queryClient.invalidateQueries([API_CACHE.TAGS])
    }
  );
};

const useRemoveAllPartnerTag = () => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, { id: number }>(
    ({ id }) =>
      client(API_ROUTES.REMOVE_ALL_PARTNER_TAG, {
        method: 'post',
        data: {
          id
        }
      }),
    {
      onSuccess: () => queryClient.invalidateQueries([API_CACHE.TAGS])
    }
  );
};
const useRemoveAllProductTag = () => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, { id: number }>(
    ({ id }) =>
      client(API_ROUTES.REMOVE_ALL_PRODUCT_TAG, {
        method: 'post',
        data: {
          id
        }
      }),
    {
      onSuccess: () => queryClient.invalidateQueries([API_CACHE.TAGS])
    }
  );
};

export {
  useGetTags,
  useAddPartnerTag,
  useRemovePartnerTag,
  useAddProductTag,
  useRemoveProductTag,
  useRemoveAllPartnerTag,
  useRemoveAllProductTag
};
