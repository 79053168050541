import ReactDatePicker from 'react-datepicker';
import { Controller, useFormContext } from 'react-hook-form';
import hu from 'date-fns/locale/hu';
import 'react-datepicker/dist/react-datepicker.css';
import { IDefaultControlProps } from 'components/ui/Form/Form';
import BaseControl, {
  BASE_INPUT_CLASS,
  INVALID_INPUT_CLASS
} from 'components/ui/Form/controls/BaseControl/BaseControl';
import CalendarIcon from 'components/ui/Icons/CalendarIcon/CalendarIcon';

interface IDatePickerProps extends IDefaultControlProps {
  isClearable?: boolean;
  minDate?: Date | null | undefined;
  maxDate?: Date | null | undefined;
}

const DatePicker = (props: IDatePickerProps) => {
  const {
    name,
    label,
    placeholder,
    disabled,
    className,
    isClearable = true,
    minDate,
    maxDate
  } = props;
  const { control, formState, setValue } = useFormContext();

  const handleChange = (date: Date | null) => {
    let newDate = date;
    if (date === null && !isClearable) {
      newDate = new Date();
    }
    setValue(name, newDate, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    });
  };

  return (
    <BaseControl name={name} label={label} className={className}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const { value, ...restRenderProps } = field;
          return (
            <div className="relative">
              <ReactDatePicker
                {...restRenderProps}
                dateFormat={'yyyy. MMMM dd.'}
                value={value}
                selected={value}
                locale={hu}
                onChange={handleChange}
                placeholderText={placeholder}
                disabled={disabled}
                className={`w-full pl-9 ${BASE_INPUT_CLASS}${
                  formState.errors[name] ? INVALID_INPUT_CLASS : ''
                }`}
                wrapperClassName="parola-calendar__wrapper"
                calendarClassName="parola-calendar"
                dropdownMode="select"
                isClearable={isClearable}
                showMonthDropdown
                showYearDropdown
                minDate={minDate}
                maxDate={maxDate}
              />
              <CalendarIcon className="h-5 absolute left-2 top-1/2 -translate-y-1/2 text-lightBlue-100" />
            </div>
          );
        }}
      />
    </BaseControl>
  );
};

export default DatePicker;
