import { useFormContext } from 'react-hook-form';
import { IDefaultControlProps } from '../../Form';
import BaseControl from '../BaseControl/BaseControl';

interface ITextInputProps extends IDefaultControlProps {
  type?: 'text' | 'email' | 'password';
}

const TextInput = (props: ITextInputProps) => {
  const {
    name,
    label,
    placeholder,
    disabled,
    type = 'text',
    autoComplete,
    className,
    group
  } = props;
  const { register } = useFormContext();

  return (
    <BaseControl name={name} label={label} className={className} group={group}>
      <input
        {...register(name)}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete={autoComplete}
      />
    </BaseControl>
  );
};

export default TextInput;
