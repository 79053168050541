import ReactDatePicker from 'react-datepicker';
import { Controller, useFormContext } from 'react-hook-form';
import hu from 'date-fns/locale/hu';
import 'react-datepicker/dist/react-datepicker.css';
import { IDefaultControlProps } from 'components/ui/Form/Form';
import BaseControl, {
  BASE_INPUT_CLASS,
  INVALID_INPUT_CLASS
} from 'components/ui/Form/controls/BaseControl/BaseControl';
import { parse } from 'date-fns';
import { useDebouncedCallback } from 'use-debounce';
import CalendarIcon from 'components/ui/Icons/CalendarIcon/CalendarIcon';

interface IDateRangePickerProps extends IDefaultControlProps {
  isClearable?: boolean;
  dateRange?: boolean;
  minDate?: Date | null;
}

const IDateRangePicker = (props: IDateRangePickerProps) => {
  const { name, label, disabled, className, isClearable = true, minDate = null } = props;

  const { control, formState, setValue, getValues } = useFormContext();

  const handleChange = (date: Date | null, type: 'start' | 'end') => {
    const newDate = [...getValues(name)];
    if (type === 'start') {
      newDate[0] = date;
    } else {
      newDate[1] = date;
    }

    setValue(name, newDate, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    });
  };

  const handleParseDateText = useDebouncedCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      if (e.target.value) {
        const [from, to] = e.target.value.split(' - ');

        const parsedFrom = parse(from, 'yyyy. MMMM dd.', new Date(), { locale: hu });
        const parsedTo = parse(to, 'yyyy. MMMM dd.', new Date(), { locale: hu });

        if (
          parsedFrom.toString() !== 'Invalid Date' &&
          parsedTo.toString() !== 'Invalid Date'
        ) {
          handleChange(parsedFrom, 'start');
          handleChange(parsedTo, 'end');
        }
      }
    },
    300,
    { trailing: true }
  );

  return (
    <BaseControl name={name} label={label} className={className}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const { value, ...restRenderProps } = field;
          const [startDate, endDate] = value;
          return (
            <div className="flex flex-col md:flex-row gap-4">
              <div className="relative">
                <ReactDatePicker
                  {...restRenderProps}
                  dateFormat={'yyyy. MMMM dd.'}
                  value={startDate}
                  selected={startDate}
                  locale={hu}
                  onChange={(e) => handleChange(e, 'start')}
                  placeholderText={'Kezdő dátum'}
                  disabled={disabled}
                  className={`w-full pl-9 ${BASE_INPUT_CLASS}${
                    formState.errors[name] ? INVALID_INPUT_CLASS : ''
                  }`}
                  startDate={startDate}
                  endDate={endDate}
                  wrapperClassName="parola-calendar__wrapper"
                  calendarClassName="parola-calendar"
                  dropdownMode="select"
                  isClearable={isClearable}
                  showMonthDropdown
                  showYearDropdown
                  selectsStart
                  onChangeRaw={handleParseDateText}
                  minDate={minDate}
                />
                <CalendarIcon className="h-5 absolute left-2 top-1/2 -translate-y-1/2 text-lightBlue-100" />
              </div>
              <div className="relative">
                <ReactDatePicker
                  {...restRenderProps}
                  dateFormat={'yyyy. MMMM dd.'}
                  value={endDate}
                  selected={endDate}
                  locale={hu}
                  onChange={(e) => handleChange(e, 'end')}
                  placeholderText={'Vég dátum'}
                  disabled={disabled}
                  className={`w-full pl-9 ${BASE_INPUT_CLASS}${
                    formState.errors[name] ? INVALID_INPUT_CLASS : ''
                  }`}
                  startDate={startDate}
                  endDate={endDate}
                  wrapperClassName="parola-calendar__wrapper"
                  calendarClassName="parola-calendar"
                  dropdownMode="select"
                  isClearable={isClearable}
                  showMonthDropdown
                  showYearDropdown
                  selectsEnd
                  onChangeRaw={handleParseDateText}
                  minDate={startDate ?? minDate}
                />
                <CalendarIcon className="h-5 absolute left-2 top-1/2 -translate-y-1/2 text-lightBlue-100" />
              </div>
            </div>
          );
        }}
      />
    </BaseControl>
  );
};

export default IDateRangePicker;
