export const ERROR_MESSAGES: Record<string, string> = {
  INVALID_TAX: 'Az adószám nem megfelelő!',
  EXISTS_ORG: 'Az adott adószámmal már létezik cég!',
  EXISTS_USER: 'Már létezik a felhasználó!',
  INSUFICCIENT_ROLE: 'Nincs megfelelő jogosultság!',
  INVALID_AUTHENTICATION:
    'A bejelentkezés nem sikerült, mert hibás a felhasználói név vagy jelszó!',
  NO_INVOICES: 'A megadott időszakra nincs számlaadat!',
  INVALID_DATES: 'A megadott időszak nem jeleníthető meg!',
  TECHNICAL: 'Valami hiba történt!',
  TOO_MANY_TAGS: 'Több címke rögzítése nem lehetséges!',
  PENDING_USER:
    'Nincs aktiválva a felhasználó. Kérem végezze el a felhasználó aktiválását és utána jelentkezzen be a programba!',
  NO_ACTIVE_SUBSCRIPTION: 'Nem rendelkezik aktív előfizetéssel!',
  EMPTY_PASSWORD: 'Jelszó megadása szükséges!',
  NA: 'Exportálandó adat nem található!',
  INVALID_PACKAGE: 'A csomagválasztás nem megfelelő!'
};

export const SUCCESS_MESSAGES = {
  SAVED: 'A tárolás sikeresen megtörtént!',
  NAV_SUCCESS: 'A NAV kapcsolat beállítása sikeres!',
  NAV_UNSUCCESS: 'A NAV kapcsolat beállítása sikertelen!',
  DELETE_RECURRING: 'Ismétlődő fizetés sikeresen törölve!'
} as const;
