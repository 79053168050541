import { useGetExtras, useGetPackages } from 'api/packages';
import {
  useRegisterSubscription,
  useRemoveRecurring,
  useRenewSubscription,
  useUpgradeSubscription
} from 'api/subscriptions';
import { WithFormError } from 'components/ui/Form/Form';
import { useModal } from 'contexts/modal-context';
import { useOrg } from 'contexts/org-context';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  ExtraType,
  Package,
  PackageType,
  PACKAGE_ORDER,
  PaymentFrequency
} from 'types/subscription';

export interface ISubscriptionFormValues {
  payFreq: PaymentFrequency;
  extra: Record<PackageType, Record<ExtraType, boolean>>;
}

type UpgradeablePackages = Exclude<PackageType, 'FREE'>;

const EXTRA_DEFAULT_VALUES = {
  FREE: {
    PARTNER: false,
    PRODUCT: false
  },
  SMALL: {
    PARTNER: false,
    PRODUCT: false
  },
  MEDIUM: {
    PARTNER: false,
    PRODUCT: false
  },
  LARGE: {
    PARTNER: false,
    PRODUCT: false
  }
};

const useSubscriptionPage = () => {
  const { org, activeSubscription } = useOrg();
  const [selectedPackage, setSelectedPackage] = useState<Package>();
  const formMethods = useForm<WithFormError<ISubscriptionFormValues>>({
    defaultValues: {
      payFreq: activeSubscription?.payFreq || 'MONTH',
      extra: EXTRA_DEFAULT_VALUES
    }
  });

  const payFreq = formMethods.watch('payFreq');
  const selectedExtras = formMethods.watch('extra');

  const { data: packages } = useGetPackages();
  const { data: extras } = useGetExtras();
  const { mutate: upgradeSubscription, isLoading: isUpgradeLoading } =
    useUpgradeSubscription();
  const { mutate: renewSubscription, isLoading: isRenewLoading } = useRenewSubscription();
  const { mutate: registerSubscription, isLoading: isRegisterLoading } =
    useRegisterSubscription();
  const { mutate: removeRecurring, isLoading: isRemoveLoading } = useRemoveRecurring();
  const { openModalWithContent } = useModal();

  const allowedPackages: PackageType[] = useMemo(() => {
    const result: UpgradeablePackages[] = [];
    if (!activeSubscription) {
      result.push('SMALL', 'MEDIUM', 'LARGE');
    } else {
      const order = PACKAGE_ORDER[activeSubscription.subType];
      for (let i = order; i < Object.keys(PACKAGE_ORDER).length; i++) {
        result.push(Object.keys(PACKAGE_ORDER)[i] as UpgradeablePackages);
      }
    }
    return result;
  }, [activeSubscription]);

  const handleSelectPackage = (pckg: Package) => {
    setSelectedPackage(pckg);
  };

  const handleUpgrade = () => {
    if (!selectedPackage) return;

    upgradeSubscription(
      {
        subType: selectedPackage.code,
        extra: Object.keys(selectedExtras[selectedPackage.code]).filter(
          (v) => selectedExtras[selectedPackage.code][v as ExtraType] === true
        ) as ExtraType[]
      },
      {
        onSuccess: (response) => window.location.replace(response)
      }
    );
  };

  const handleRegister = () => {
    if (!selectedPackage) return;
    registerSubscription(
      {
        paymentType: 'SIMPLE',
        subType: selectedPackage.code,
        payFreq: formMethods.getValues('payFreq'),
        extra: Object.keys(selectedExtras[selectedPackage.code]).filter(
          (v) => selectedExtras[selectedPackage.code][v as ExtraType] === true
        ) as ExtraType[]
      },
      {
        onSuccess: (response) => window.location.replace(response)
      }
    );
  };

  const handleRenew = () => {
    renewSubscription(undefined, {
      onSuccess: (response) => window.location.replace(response)
    });
  };

  const handleRemoveRecurring = () => {
    removeRecurring();
  };

  const hasActiveSubscription = useMemo(
    () =>
      activeSubscription &&
      activeSubscription.subType !== 'FREE' &&
      new Date(activeSubscription.validity).getTime() > new Date().getTime(),
    [activeSubscription]
  );

  const hasNoCompany = !!(
    !org?.taxNumber || !org.taxNumber.match(/[0-9]{8}-[1-5]-[0-9]{2}/)
  );

  const formDisabled = hasNoCompany || activeSubscription?.status === 'UNPAID';

  const isRecurring = !!activeSubscription?.cardExpiry && !!activeSubscription.cardNumber;

  return {
    handleSelectPackage,
    selectedPackage,
    packages,
    formMethods,
    activeSubscription,
    allowedPackages,
    handleSubmit: hasActiveSubscription ? handleUpgrade : handleRegister,
    isSubmitLoading: isUpgradeLoading || isRegisterLoading,
    hasActiveSubscription,
    openModalWithContent,
    payFreq,
    handleRenew,
    isRenewLoading,
    hasNoCompany,
    formDisabled,
    extras: extras || [],
    isRecurring,
    handleRemoveRecurring,
    isRemoveLoading
  };
};

export default useSubscriptionPage;
