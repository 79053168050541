import { FormOnSubmit, WithFormError } from 'components/ui/Form/Form';
import { PASSWORD_STRENGTH_VALIDATOR, VALIDATION_MESSAGES } from 'constants/validation';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ExtraType } from 'types/subscription';
import { useGetOrgByTaxnum } from 'api/nav';

export interface ICompanyFormValues {
  name: string;
  email: string;
  password: string;
  passwordConfirm: string;
  org: {
    taxNumber: string;
    name: string;
    shortName: string;
    zip: string;
    city: string;
    street: string;
    streetType: string;
    houseNumber: string;
    building: string;
    stairway: string;
    floor: string;
    door: string;
    lotNumber: string | null;
    email: string;
  };
  extra: ExtraType[];
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required(VALIDATION_MESSAGES.required),
  email: Yup.string()
    .email(VALIDATION_MESSAGES.invalid_email)
    .required(VALIDATION_MESSAGES.required),
  password: Yup.string()
    .required(VALIDATION_MESSAGES.required)
    .matches(PASSWORD_STRENGTH_VALIDATOR, VALIDATION_MESSAGES.weak_password),
  passwordConfirm: Yup.string()
    .required(VALIDATION_MESSAGES.required)
    .oneOf([Yup.ref('password'), null], VALIDATION_MESSAGES.match_password),
  org: Yup.object().shape({
    taxNumber: Yup.string()
      .required(VALIDATION_MESSAGES.required)
      .matches(/[0-9]{8}-[1-5]-[0-9]{2}/, VALIDATION_MESSAGES.invalid_tax_number),
    name: Yup.string().required(VALIDATION_MESSAGES.required),
    shortName: Yup.string(),
    zip: Yup.string()
      .required(VALIDATION_MESSAGES.required)
      .matches(/^[0-9]{4}$/, VALIDATION_MESSAGES.invalid_zip),
    city: Yup.string().required(VALIDATION_MESSAGES.required),
    street: Yup.string().required(VALIDATION_MESSAGES.required),
    streetType: Yup.string().required(VALIDATION_MESSAGES.required),
    houseNumber: Yup.string().required(VALIDATION_MESSAGES.required),
    email: Yup.string().required(VALIDATION_MESSAGES.required)
  })
});

const useCompanyForm = () => {
  const [step, setStep] = useState<number>(0);
  const formMethods = useForm<WithFormError<ICompanyFormValues>>({
    defaultValues: {
      name: '',
      email: '',
      password: '',
      passwordConfirm: '',
      org: {
        taxNumber: '',
        name: '',
        shortName: '',
        zip: undefined,
        city: '',
        street: '',
        streetType: '',
        houseNumber: '',
        building: '',
        stairway: '',
        floor: '',
        door: '',
        lotNumber: '',
        email: ''
      },
      extra: []
    },
    resolver: yupResolver(validationSchema)
  });
  const taxNum = formMethods.watch('org.taxNumber');
  const { data: orgData, error } = useGetOrgByTaxnum(taxNum);

  useEffect(() => {
    if (error && error.response?.data?.errors?.some((e) => e.match(/^TAX_INVALID.*/g))) {
      formMethods.setError('org.taxNumber', { message: 'Érvénytelen adószám' });
    }
  }, [error, formMethods]);

  useEffect(() => {
    if (orgData) {
      const { taxNumber, ...rest } = orgData;
      formMethods.reset({
        ...formMethods.getValues(),
        org: { ...formMethods.getValues('org'), ...rest }
      });
    }
  }, [formMethods, orgData]);

  const handleNext = async (onSubmit: FormOnSubmit<ICompanyFormValues>) => {
    switch (step) {
      case 0: {
        const isValid = await formMethods.trigger([
          'email',
          'name',
          'org.email',
          'org.name',
          'org.shortName',
          'org.taxNumber',
          'password',
          'passwordConfirm'
        ]);
        if (isValid) {
          setStep(1);
        }
        break;
      }
      case 1: {
        const isValid = await formMethods.trigger([
          'org.building',
          'org.city',
          'org.door',
          'org.floor',
          'org.houseNumber',
          'org.lotNumber',
          'org.stairway',
          'org.street',
          'org.streetType',
          'org.zip'
        ]);
        if (isValid) {
          formMethods.handleSubmit((data) => onSubmit(data, formMethods.setError))();
        }
        break;
      }
      default:
        break;
    }
  };

  const handlePrev = () => {
    setStep((prev) => (prev === 0 ? 0 : prev - 1));
  };

  return { step, formMethods, handleNext, handlePrev };
};

export default useCompanyForm;
