import CheckMarkIcon from 'components/ui/Icons/CheckMarkIcon/CheckMarkIcon';
import { useMemo } from 'react';
import { mergeClassNames } from 'utils/helpers';
import { v4 as uuidv4 } from 'uuid';

interface IProps {
  stepCount: number;
  currentStep: number;
  status?: 'DONE' | 'ERROR';
}

type Dot = {
  index: number;
  id: string;
};

const StepIndicator = ({ stepCount, currentStep, status }: IProps) => {
  const dots = useMemo(() => {
    const renderSteps = [];

    for (let i = 1; i <= stepCount; i++) {
      renderSteps.push({
        index: i,
        id: uuidv4()
      });
    }

    return renderSteps;
  }, [stepCount]);

  const stepDone = (dot: Dot) =>
    currentStep > dot.index || (currentStep === dot.index && status === 'DONE');
  const stepError = (dot: Dot) => currentStep === dot.index && status === 'ERROR';

  return (
    <div className="flex justify-center">
      {dots.map((dot) => (
        <div
          key={dot.id}
          className={mergeClassNames(
            'w-5 h-5 border-2 rounded-full mx-2 border-darkBlue-5 flex justify-center items-center font-bold',
            {
              'border-lightBlue-100': currentStep >= dot.index,
              'bg-lightBlue-100': stepDone(dot),
              'bg-lightRed-100 border-lightRed-100': stepError(dot)
            }
          )}
        >
          {stepDone(dot) ? <CheckMarkIcon className="w-4 h-4 text-white" /> : null}
          {stepError(dot) ? <span className="text-white">!</span> : null}
        </div>
      ))}
    </div>
  );
};

export default StepIndicator;
