import BaseControl from 'components/ui/Form/controls/BaseControl/BaseControl';
import { IDefaultControlProps } from 'components/ui/Form/Form';
import { useFormContext } from 'react-hook-form';
import { mergeClassNames } from 'utils/helpers';

interface IProps extends IDefaultControlProps {
  onLabel: string;
  offLabel: string;
  onValue: unknown;
  offValue: unknown;
  label?: string;
  labelClassName?: string;
}

const Switch = ({
  name,
  onLabel,
  offLabel,
  onValue,
  offValue,
  label,
  className,
  labelClassName
}: IProps) => {
  const { register, watch, setValue } = useFormContext();
  const value = watch(name);
  const { onChange, ...rest } = register(name);

  return (
    <BaseControl name={name} label={label} className={className}>
      <label
        htmlFor={name}
        className={mergeClassNames(
          'flex items-center cursor-pointer select-none border-none',
          labelClassName
        )}
      >
        <span
          className={`mr-2 font-semibold ${
            value === offValue ? 'text-darkBlue-100' : 'text-darkBlue-40'
          }`}
        >
          {offLabel}
        </span>
        <div className="relative">
          <input
            type="checkbox"
            id={name}
            {...rest}
            onChange={(e) => {
              onChange(e);
              setValue(name, value === onValue ? offValue : onValue);
            }}
            // checked={value === onValue}
            value={value}
            className="sr-only"
          />
          <div className="block bg-darkBlue-5 bg-opacity-40 border-2 border-darkBlue-10 w-16 h-8 rounded-full" />
          <div
            className={mergeClassNames(
              `dot absolute top-0 bg-darkBlue-100 w-8 h-8 rounded-full`,
              value === onValue ? 'right-0' : 'left-0'
            )}
          />
        </div>
        <span
          className={`ml-2 font-semibold ${
            value === onValue ? 'text-darkBlue-100' : 'text-darkBlue-40'
          }`}
        >
          {onLabel}
        </span>
      </label>
    </BaseControl>
  );
};

export default Switch;
