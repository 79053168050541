import {
  IBaseFilters,
  IProductsFilters,
  useGetPriceChangesByProduct
} from 'api/price-changes';
import { DateRange } from 'components/ui/DateRangeFilter/useDateRangeFilter';
import { WithFormError } from 'components/ui/Form/Form';
import {
  differenceInCalendarDays,
  differenceInCalendarWeeks,
  differenceInCalendarMonths
} from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';
import { formatNumberToCurrency } from 'utils/helpers';

export interface IPriceChangeByProductData {
  header: string;
  value: string;
  value2: string | undefined;
  movement: 'UP' | 'DOWN' | 'SAME';
  type: 'CURRENCY' | 'PERCENTAGE ';
}
export interface IPriceChangeByProducts {
  name: string;
  data: IPriceChangeByProductData[];
}

interface IProps {
  filters: IBaseFilters;
  dateRange: DateRange;
}

const usePriceChangeByProducts = ({ filters, dateRange }: IProps) => {
  const formMethods = useForm<WithFormError<{ search: string }>>();
  const [grouping, setGrouping] = useState<IProductsFilters['type']>('QUARTERLY');
  const [data, setData] = useState<IPriceChangeByProducts[]>([]);
  const [filteredData, setFilteredData] = useState<IPriceChangeByProducts[]>([]);
  const [tableData, columnKeys] = useMemo(() => {
    if (!filteredData.length) return [[], []];
    const ck = filteredData[0].data.map(({ header }) => header);
    const d = filteredData.reduce<
      Record<string, { value: string; movement: 'UP' | 'DOWN' | 'SAME' }>[]
    >((acc, curr) => {
      const obj: Record<string, any> = {
        name: curr.name
      };

      ck.forEach((key) => {
        const current = curr.data.find(({ header }) => header === key);
        if (current) {
          const { value, value2, movement, type } = current;
          const rowValue = () => {
            const res = [];
            if (type === 'CURRENCY') {
              res.push(formatNumberToCurrency(value));
            } else {
              if (value2) {
                res.push(formatNumberToCurrency(value2));
              }
              res.push(`${value}%`);
            }

            return res;
          };

          obj[key] = {
            value: rowValue(),
            movement
          };
        }
      });

      return [...acc, obj];
    }, []);
    return [d, ck];
  }, [filteredData]);

  const { mutate: getPriceChangesData, isLoading } = useGetPriceChangesByProduct();

  const search = formMethods.watch('search');

  useEffect(() => {
    getPriceChangesData(
      { ...filters, type: grouping },
      {
        onSuccess: (response) => setData(response)
      }
    );
  }, [filters, getPriceChangesData, grouping]);

  const filterData = useDebouncedCallback(
    (filter: string, list: IPriceChangeByProducts[]) => {
      const result = data.filter((item) =>
        item.name.toLowerCase().includes(filter.toLowerCase())
      );

      setFilteredData(result);
    },
    300,
    { leading: false, trailing: true }
  );

  useEffect(() => {
    filterData(search, data);
  }, [search, data, filterData]);

  const allowedButtons = useMemo<IProductsFilters['type'][]>(() => {
    const [from, to] = dateRange;
    const allowed: IProductsFilters['type'][] = ['QUARTERLY', 'YEARLY', 'INVOICE'];
    if (!from || !to) return allowed;
    if (Math.abs(differenceInCalendarDays(from, to)) < 7) allowed.push('DAILY');
    if (Math.abs(differenceInCalendarWeeks(from, to, { weekStartsOn: 1 })) <= 7)
      allowed.push('WEEKLY');
    if (Math.abs(differenceInCalendarMonths(from, to)) <= 7) allowed.push('MONTHLY');
    return allowed;
  }, [dateRange]);

  useEffect(() => {
    if (!allowedButtons.includes(grouping)) {
      setGrouping(allowedButtons[0]);
    }
  }, [filters, allowedButtons, grouping]);

  return {
    data: tableData,
    columnKeys,
    grouping,
    setGrouping,
    allowedButtons,
    isLoading,
    formMethods
  };
};

export default usePriceChangeByProducts;
