import { IProduct } from 'api/products';
import { useAddProductTag, useRemoveAllProductTag, useRemoveProductTag } from 'api/tags';
import { OptionChangeAction } from 'components/ui/Form/controls/Select/Select';
import { WithFormError } from 'components/ui/Form/Form';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

export type ProductCategories = {
  tags: string[];
};

const useProductCategorization = () => {
  const [filteredValues, setFilteredValues] = useState<IProduct[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<IProduct | null>(null);
  const [editSelected, setEditSelected] = useState<boolean>(false);
  const { mutate: addProductTag } = useAddProductTag();
  const { mutate: removeProductTag } = useRemoveProductTag();
  const { mutate: removeAllProductTag } = useRemoveAllProductTag();
  const formMethods = useForm<WithFormError<ProductCategories>>({
    defaultValues: {
      tags: []
    }
  });

  useEffect(() => {
    formMethods.setValue('tags', selectedProduct?.tags ?? []);
  }, [formMethods, selectedProduct]);

  const onChangeOptions = (
    action: OptionChangeAction<string>,
    onSuccess?: () => void
  ) => {
    const tagName = action.option?.value || action.removedValue?.value;
    if (!selectedProduct) throw new Error('Invalid parameters');
    if (action.action === 'create-option' || action.action === 'select-option') {
      if (!tagName) throw new Error('Invalid parameters');
      addProductTag(
        { id: selectedProduct?.id, name: tagName },
        {
          onSuccess: () => onSuccess && onSuccess()
        }
      );
    }
    if (action.action === 'remove-value') {
      if (!tagName) throw new Error('Invalid parameters');
      removeProductTag(
        { id: selectedProduct?.id, name: tagName },
        {
          onSuccess: () => onSuccess && onSuccess()
        }
      );
    }
    if (action.action === 'clear') {
      removeAllProductTag(
        {
          id: selectedProduct?.id
        },
        { onSuccess: () => onSuccess && onSuccess() }
      );
    }
  };

  const toggleEdit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    product: IProduct
  ) => {
    e.stopPropagation();
    setSelectedProduct(product);
    if (product === selectedProduct) {
      setEditSelected((prev) => !prev);
    } else {
      setEditSelected(true);
    }
  };

  return {
    formMethods,
    onChangeOptions,
    setSelectedProduct,
    selectedProduct,
    toggleEdit,
    editSelected,
    filteredValues,
    setFilteredValues
  };
};

export default useProductCategorization;
