import { ExclamationIcon } from '@heroicons/react/solid';

interface IErrorFeedbackProps {
  message: string;
}

const ErrorFeedback = (props: IErrorFeedbackProps) => {
  const { message } = props;

  return (
    <p className="text-xs text-red-700 m-0 -ml-0.5 mt-0.5 flex">
      <ExclamationIcon className="h-4 w-4 mr-1" /> {message}
    </p>
  );
};

export default ErrorFeedback;
