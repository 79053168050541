import { SelectOptions } from 'components/ui/Form/controls/Select/Select';
import { Roles } from 'types/user';

export const ROLES_NAMES: Record<Roles, string> = {
  ADMIN: 'Admin',
  EDITOR: 'Szerkesztő',
  VIEWER: 'Megtekintő',
  DEVELOPER: 'Fejlesztő'
};

export const ROLES_OPTIONS: SelectOptions<Roles>[] = [
  { value: 'ADMIN', label: ROLES_NAMES.ADMIN, disabled: true },
  { value: 'EDITOR', label: ROLES_NAMES.EDITOR },
  { value: 'VIEWER', label: ROLES_NAMES.VIEWER }
];
