import { useState } from 'react';

const usePasswordInput = () => {
  const [show, setShow] = useState<boolean>(false);

  const handleToggleShow = () => {
    setShow((prev) => !prev);
  };

  return { show, handleToggleShow };
};

export default usePasswordInput;
