export const VALIDATION_MESSAGES = {
  required: 'Kötelező mező',
  invalid_email: 'Érvénytelen email cím',
  weak_password: 'Legalább 8 karakter, kis és nagy betű, szám',
  match_password: 'A két jelszó nem egyezik',
  invalid_tax_number: 'Érvénytelen adószám',
  invalid_zip: 'Érvénytelen irszám'
} as const;

export const PASSWORD_STRENGTH_VALIDATOR = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
