import { SubStatus } from 'types/status';

const packageTypes = ['FREE', 'SMALL', 'MEDIUM', 'LARGE'] as const;
export type PackageType = typeof packageTypes[number];
export const isPackageType = (
  maybePackageType: unknown
): maybePackageType is PackageType =>
  typeof maybePackageType === 'string' &&
  packageTypes.includes(maybePackageType as PackageType);

export const PACKAGE_ORDER: Record<PackageType, number> = {
  FREE: 0,
  SMALL: 1,
  MEDIUM: 2,
  LARGE: 3
};

export type Limits = 'multipleCompanies' | 'timeRange' | 'itemCount';

export type ExtraType = 'PRODUCT' | 'PARTNER';

export type PaymentType = 'SIMPLE' | 'PAYPAL';

export type Subscription = {
  affiliateCode: null | string;
  id: number;
  paymentType: PaymentType;
  status: SubStatus;
  subType: PackageType;
  validity: string;
  payFreq: PaymentFrequency;
  extra: string;
  cardExpiry: string;
  cardNumber: number;
};

const paymentFreq = ['MONTH', 'YEAR'] as const;
export type PaymentFrequency = typeof paymentFreq[number];
export const isPaymentFrequency = (
  maybePaymentFreq: unknown
): maybePaymentFreq is PaymentFrequency =>
  typeof maybePaymentFreq === 'string' &&
  paymentFreq.includes(maybePaymentFreq as PaymentFrequency);

export type Price = {
  freq: PaymentFrequency;
  from: string | null;
  gross: number;
  id: number;
  to: string | null;
  netto: number;
  vat: number;
  vatRate: number;
};

export type Package = {
  code: PackageType;
  id: number;
  months: number | null;
  multi: boolean;
  name: string;
  packs: null; // TODO
  prices: Price[];
  tags: number | null;
  type: 'PACK';
};

export type Extra = {
  id: number;
  type: string;
  code: ExtraType;
  name: string;
  multi: boolean;
  months: number;
  tags: number;
  packs: number[];
  prices: Price[];
};
