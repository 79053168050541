import { createContext, useContext, useEffect, useState } from 'react';
import { createContextualCan } from '@casl/react';
import { AppAbilityType, buildAbilityFor } from 'constants/ability';
import { IContextProvider } from './app-providers';
import { useAuth } from './auth-context';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const AbilityContext = createContext<AppAbilityType>(undefined!);
AbilityContext.displayName = 'AbilityContext';

const Can = createContextualCan(AbilityContext.Consumer);

const AbilityProvider = ({ children }: IContextProvider) => {
  const { user } = useAuth();
  const [ability, setAbility] = useState<AppAbilityType>(buildAbilityFor(user?.role));

  useEffect(() => {
    setAbility(buildAbilityFor(user?.role));
  }, [user]);

  return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
};

const useAbility = () => {
  const context = useContext(AbilityContext);
  if (context === undefined) {
    throw new Error('useAbility must be used within an AbilityProvider');
  }
  return context;
};

export { Can, AbilityProvider, useAbility };
