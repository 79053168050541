import { SelectOptions } from 'components/ui/Form/controls/Select/Select';
import { MembershipStatus, SubStatus, UserStatus } from 'types/status';

export const USER_STATUS_NAMES: Record<UserStatus, string> = {
  ACTIVE: 'Aktív',
  DISABLED: 'Letiltott',
  PENDING: 'Nem megerősített'
};

export const USER_STATUS_OPTIONS: SelectOptions<UserStatus>[] = [
  { value: 'ACTIVE', label: USER_STATUS_NAMES.ACTIVE },
  { value: 'DISABLED', label: USER_STATUS_NAMES.DISABLED }
];

export const SUB_STATUS_NAMES: Record<SubStatus, string> = {
  ACTIVE: 'Aktív',
  CLOSED: 'Letiltott',
  UNPAID: 'Nem fizetett'
};

export const SUB_STATUS_OPTIONS: SelectOptions<SubStatus>[] = [
  { value: 'ACTIVE', label: SUB_STATUS_NAMES.ACTIVE },
  { value: 'CLOSED', label: SUB_STATUS_NAMES.CLOSED },
  { value: 'UNPAID', label: SUB_STATUS_NAMES.UNPAID }
];

export const MEMBERSHIP_STATUS_NAMES: Record<MembershipStatus, string> = {
  ACTIVE: 'Aktív',
  DISABLED: 'Letiltott'
};

export const MEMBERSHIP_STATUS_OPTIONS: SelectOptions<MembershipStatus>[] = [
  { value: 'ACTIVE', label: MEMBERSHIP_STATUS_NAMES.ACTIVE },
  { value: 'DISABLED', label: MEMBERSHIP_STATUS_NAMES.DISABLED }
];
