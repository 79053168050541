import axios, { AxiosRequestConfig } from 'axios';
import { decodeToken } from 'utils/jwt';

export type ISuccessResponse<T> = T & {
  status: 'OK';
};

export type ErrorResponse = {
  errors: string[];
};

export type HttpMethods = 'get' | 'post' | 'patch' | 'put' | 'delete';
export interface IHttpRequestConfig<T> extends AxiosRequestConfig<T> {
  method: HttpMethods;
  headers?: Record<string, string>;
  data?: T;
  token?: string;
}

/**
 * API request with 2 generic types: R is the response type, T is the request data object type.
 * @param endpoint - URL that comes after the baseurl
 * @param config - the request object see {@link IHttpRequestConfig}
 * @returns Promise<R>
 */
const client = async <R = unknown, T = undefined>(
  endpoint: string,
  {
    data,
    method,
    headers = {
      'Content-Type': 'application/json'
    },
    token,
    ...rest
  }: IHttpRequestConfig<T>,
  logout?: () => void
): Promise<R> => {
  const config: AxiosRequestConfig<T> = {
    method,
    data,
    baseURL: process.env.REACT_APP_API_URL,
    url: endpoint,
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
      ...headers
    },
    ...rest
  };

  if (logout && token) {
    const parsedToken = decodeToken(token);
    if (new Date().getTime() >= (parsedToken?.exp || 0) * 1000) {
      logout();
    }
  }

  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    console.log(err);
    return Promise.reject(err);
  }
};

export { client, axios };
