import { IProduct } from 'api/products';
import CategoryFilters from 'components/pages/CategorizationPage/components/CategoryFilters/CategoryFilters';
import CategoryTags from 'components/pages/CategorizationPage/components/CategoryTags/CategoryTags';
import useProductCategorization, {
  ProductCategories
} from 'components/pages/CategorizationPage/components/ProductCategorization/useProductCategorization';
import Button from 'components/ui/Button/Button';
import Select, { SelectOptions } from 'components/ui/Form/controls/Select/Select';
import Form from 'components/ui/Form/Form';
import ExtensionIcon from 'components/ui/Icons/ExtensionIcon/ExtensionIcon';

interface IProps {
  products: IProduct[] | undefined;
  categoryOptions: SelectOptions<string>[];
  handleCategoryChange: () => void;
}

const ProductCategorization = ({
  products = [],
  categoryOptions,
  handleCategoryChange
}: IProps) => {
  const {
    formMethods,
    onChangeOptions,
    setSelectedProduct,
    selectedProduct,
    toggleEdit,
    editSelected,
    filteredValues,
    setFilteredValues
  } = useProductCategorization();

  return (
    <div className="overflow-auto">
      <CategoryFilters
        data={products}
        onChange={setFilteredValues}
        categoryOptions={categoryOptions}
      />
      {filteredValues?.map((product) => (
        <div key={product.id}>
          <div
            className={`flex justify-between p-1.5 my-1.5 rounded ${
              selectedProduct?.id === product.id
                ? 'bg-lightBlue-40 bg-opacity-20'
                : 'hover:bg-lightBlue-40 hover:bg-opacity-20 hover:cursor-pointer'
            }`}
            onClick={() => setSelectedProduct(product)}
          >
            <div className="flex flex-1 mr-2 items-center">
              <span className="text-lightBlue-100">
                <ExtensionIcon className="h-6 mr-2" />
              </span>
              {product.name}
            </div>
            <div className="hidden md:block">
              {selectedProduct?.id !== product.id || !editSelected ? (
                <CategoryTags tags={product.tags} />
              ) : null}
            </div>
            {editSelected && selectedProduct?.id === product.id ? (
              <Button
                iconClassName="h-4 w-4"
                buttonClassName="px-1 py-0"
                icon="checkMark"
                variant="text"
                color="info"
                onClick={(e) => toggleEdit(e, product)}
              />
            ) : (
              <Button
                iconClassName="h-4 w-4"
                buttonClassName="px-1 py-0"
                icon="edit"
                variant="text"
                color="info"
                onClick={(e) => toggleEdit(e, product)}
              />
            )}
          </div>
          {selectedProduct?.id === product.id && editSelected ? (
            <Form<ProductCategories> formMethods={formMethods} onSubmit={() => false}>
              <div className="flex items-center">
                <Select
                  name="tags"
                  options={categoryOptions}
                  creatable
                  isMulti
                  className="flex-1"
                  onChangeOptions={(action) =>
                    onChangeOptions(action, handleCategoryChange)
                  }
                  placeholder="Írj be egy új kategóriát vagy válassz!"
                />
              </div>
            </Form>
          ) : null}
        </div>
      ))}
    </div>
  );
};

export default ProductCategorization;
