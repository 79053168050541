import { IPartner } from 'api/partners';
import { useAddPartnerTag, useRemoveAllPartnerTag, useRemovePartnerTag } from 'api/tags';
import { OptionChangeAction } from 'components/ui/Form/controls/Select/Select';
import { WithFormError } from 'components/ui/Form/Form';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

export type PartnerCategories = {
  tags: string[];
};

interface IProps {
  selectedPartner: IPartner | null;
}

const usePartnerCategorization = ({ selectedPartner }: IProps) => {
  const [filteredValues, setFilteredValues] = useState<IPartner[]>([]);
  const { mutate: addPartnerTag } = useAddPartnerTag();
  const { mutate: removePartnerTag } = useRemovePartnerTag();
  const { mutate: removeAllPartnerTag } = useRemoveAllPartnerTag();
  const formMethods = useForm<WithFormError<PartnerCategories>>({
    defaultValues: {
      tags: []
    }
  });

  useEffect(() => {
    formMethods.setValue('tags', selectedPartner?.tags ?? []);
  }, [formMethods, selectedPartner]);

  const onChangeOptions = (
    action: OptionChangeAction<string>,
    onSuccess?: () => void
  ) => {
    const tagName = action.option?.value || action.removedValue?.value;
    if (!selectedPartner) throw new Error('Invalid parameters');
    if (action.action === 'create-option' || action.action === 'select-option') {
      if (!tagName) throw new Error('Invalid parameters');
      addPartnerTag(
        { id: selectedPartner?.id, name: tagName },
        {
          onSuccess: () => onSuccess && onSuccess()
        }
      );
    }
    if (action.action === 'remove-value') {
      if (!tagName) throw new Error('Invalid parameters');
      removePartnerTag(
        { id: selectedPartner?.id, name: tagName },
        {
          onSuccess: () => onSuccess && onSuccess()
        }
      );
    }
    if (action.action === 'clear') {
      removeAllPartnerTag(
        {
          id: selectedPartner?.id
        },
        { onSuccess: () => onSuccess && onSuccess() }
      );
    }
  };

  return { formMethods, onChangeOptions, filteredValues, setFilteredValues };
};

export default usePartnerCategorization;
