import { Controller, useFormContext } from 'react-hook-form';
import ReactSelect, { ActionMeta, components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import BaseControl, {
  BASE_INPUT_CLASS,
  INVALID_INPUT_CLASS
} from 'components/ui/Form/controls/BaseControl/BaseControl';
import { IDefaultControlProps } from 'components/ui/Form/Form';
import { useMemo } from 'react';

export interface SelectOptions<T> {
  label: string;
  value: T;
  disabled?: boolean;
}

export type OptionChangeAction<T> = ActionMeta<SelectOptions<T>>;

interface ISelectProps<T> extends IDefaultControlProps {
  options: SelectOptions<T>[];
  isMulti?: boolean;
  creatable?: boolean;
  isClearable?: boolean;
  onCreateOption?: (newOption: SelectOptions<T>) => void;
  onChangeOptions?: (action: OptionChangeAction<T>) => void;
}

const Option = (props: any) => (
  <components.Option
    {...props}
    className={`cursor-pointer ${
      props.isSelected ? 'bg-lightBlue-100 text-white' : 'text-darkBlue-100'
    }`}
  />
);

const Input = (props: any) => (
  <components.Input {...props} className="focus:border-darkBlue-100" />
);

const Select = <T,>(props: ISelectProps<T>) => {
  const {
    name,
    label,
    options,
    placeholder,
    disabled,
    isMulti,
    creatable,
    className,
    isClearable = true,
    onChangeOptions
  } = props;
  const { formState, control, setValue } = useFormContext();

  const SelectComponent = useMemo(
    () => (creatable ? CreatableSelect : ReactSelect),
    [creatable]
  );

  return (
    <BaseControl name={name} label={label} className={className}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const { onChange, value, ...restRenderProps } = field;
          const selectValue = isMulti
            ? options.filter((o) => value?.includes(o.value))
            : options.find((o) => o.value === value) ?? null;

          return (
            <SelectComponent
              {...restRenderProps}
              value={selectValue}
              placeholder={placeholder ?? 'Válassz'}
              isDisabled={disabled}
              options={options}
              components={{ Option, Input }}
              className={
                BASE_INPUT_CLASS + (formState.errors[name] ? INVALID_INPUT_CLASS : '')
              }
              onChange={(changeValue, action) => {
                if (onChangeOptions) onChangeOptions(action);
                let newValue: any;
                if (Array.isArray(changeValue)) {
                  newValue = changeValue.map((o) => o.value);
                } else {
                  newValue = (changeValue as SelectOptions<T>)?.value || null;
                }
                setValue(name, newValue, {
                  shouldDirty: true,
                  shouldTouch: true,
                  shouldValidate: true
                });
              }}
              isMulti={isMulti}
              isClearable={isClearable}
              formatCreateLabel={(inputText) => `Létrehozás: ${inputText}`}
              menuPosition="fixed"
              isOptionDisabled={(option) => !!option.disabled}
              styles={{
                control: (base) => ({
                  ...base,
                  background: formState.errors[name] ? 'rgb(254 242 242)' : 'inherit',
                  padding: 0,
                  border: 'none',
                  boxShadow: 'none',
                  margin: '0 -0.5rem',
                  minHeight: 'unset',
                  ':hover': {
                    border: 'none',
                    boxShadow: 'none'
                  }
                }),
                menu: (base) => ({
                  ...base,
                  left: 0,
                  borderRadius: 0
                }),
                option: (base) => ({
                  ...base,
                  padding: '8px 12px'
                }),
                multiValue: (base) => ({
                  ...base,
                  background: '#3366ff',
                  color: 'white',
                  borderRadius: '4px',
                  padding: '0.1rem'
                }),
                multiValueLabel: (base) => ({
                  ...base,
                  color: 'white'
                }),
                valueContainer: (base) => ({
                  ...base,
                  padding: '0 8px'
                })
              }}
              menuShouldBlockScroll={true}
            />
          );
        }}
      />
    </BaseControl>
  );
};

export default Select;
