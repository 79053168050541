import {
  useGetAllOrganization,
  useGetOrganization,
  useSelectOrganization
} from 'api/organization';
import { useGetPackages } from 'api/packages';
import { useGetActiveSubscription } from 'api/subscriptions';
import { useAuth } from 'contexts/auth-context';
import { addDays, startOfDay, subMonths } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IOrganization } from 'types/organization';
import { Subscription } from 'types/subscription';
import { IContextProvider } from './app-providers';

export interface IOrgContext {
  org: IOrganization;
  orgList?: OrgList;
  handleSelectOrganization: (orgId: number) => void;
  hasValidSubscription: boolean;
  activeSubscription?: Subscription;
  minDateForOperationsWithCurrentPackage: Date | null;
}

export type OrgList = Pick<IOrganization, 'name' | 'id'>[];

const OrgContext = React.createContext<IOrgContext | undefined>(undefined);
OrgContext.displayName = 'OrgContext';

const INITIAL_STATE: IOrganization = {
  building: '',
  city: '',
  door: '',
  email: '',
  floor: '',
  houseNumber: '',
  id: 0,
  lotNumber: '',
  name: '',
  navActive: false,
  navPassword: '',
  navSharedKey: '',
  navSignKey: '',
  navUser: '',
  shortName: '',
  stairway: '',
  street: '',
  streetType: '',
  taxNumber: '',
  zip: ''
};

const OrgProvider = (props: IContextProvider) => {
  const { token, user, login } = useAuth();
  const { data: packages } = useGetPackages();
  const [org, setOrg] = useState<IOrganization>(INITIAL_STATE);
  const { data: orgList, refetch: fetchOrganizationList } = useGetAllOrganization();
  const { data: orgData, refetch: fetchOrganization } = useGetOrganization(token);
  const { mutate: selectOrganization } = useSelectOrganization();
  const { data: activeSubscription } = useGetActiveSubscription(org?.id, token);

  const handleSelectOrganization = useCallback(
    (orgId: number) => {
      selectOrganization(orgId, {
        onSuccess: (response) => login(response.token)
      });
    },
    [login, selectOrganization]
  );

  useEffect(() => {
    if (!user || !token) {
      setOrg(INITIAL_STATE);
      return;
    }
    fetchOrganizationList();
  }, [user, token, fetchOrganizationList, fetchOrganization]);

  useEffect(() => {
    if (orgData) {
      setOrg(orgData);
    }
  }, [orgData]);

  const hasValidSubscription = useMemo(
    () =>
      Boolean(
        activeSubscription &&
          startOfDay(addDays(new Date(activeSubscription.validity), 1)).getTime() >
            new Date().getTime() &&
          activeSubscription.status === 'ACTIVE'
      ),
    [activeSubscription]
  );

  const minDateForOperationsWithCurrentPackage = useMemo(() => {
    if (!activeSubscription) return null;
    const targetPackage = packages?.find(
      (pckg) => pckg.code === activeSubscription?.subType
    );
    const months = targetPackage ? targetPackage?.months || null : 6;
    return months ? startOfDay(subMonths(new Date(), months)) : null;
  }, [activeSubscription, packages]);

  const values = useMemo(
    () => ({
      org,
      orgList,
      handleSelectOrganization,
      hasValidSubscription,
      activeSubscription,
      minDateForOperationsWithCurrentPackage
    }),
    [
      org,
      orgList,
      handleSelectOrganization,
      hasValidSubscription,
      activeSubscription,
      minDateForOperationsWithCurrentPackage
    ]
  );

  return <OrgContext.Provider value={values} {...props} />;
};

const useOrg = () => {
  const context = React.useContext(OrgContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export { OrgProvider, useOrg };
