import { useGetUsers } from 'api/users';
import { APP_ROUTES } from 'constants/routes';
import { useNavigate } from 'react-router-dom';
import { createUrlWithParam } from 'utils/helpers';

const useUsersPage = () => {
  const { data, isLoading } = useGetUsers();
  const navigate = useNavigate();

  const handleAddNewUser = () => {
    navigate(APP_ROUTES.USER_MANAGEMENT_ADD);
  };

  const handleEditUser = (userId: number) => {
    navigate(createUrlWithParam(APP_ROUTES.USER_MANAGEMENT_EDIT, userId));
  };

  return {
    data: data || [],
    isLoading,
    handleAddNewUser,
    handleEditUser
  };
};

export default useUsersPage;
