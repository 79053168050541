import useSubscribedHomePage from 'components/pages/HomePage/components/SubscribedHomePage/useSubscribedHomePage';
import PageTitle from 'components/ui/PageTitle/PageTitle';
import Tile from 'components/ui/Tile/Tile';
import { APP_ROUTES } from 'constants/routes';

const SubscribedHomePage = () => {
  const { handleTileClicked, user, status } = useSubscribedHomePage();
  return (
    <div className="flex flex-col items-center justify-center min-h-full">
      <PageTitle>Üdvözöllek {user?.name}!</PageTitle>
      {user?.role?.includes('ADMIN') ? (
        <Tile
          icon={status ? 'navok' : 'settings'}
          className={status ? 'text-lightGreen-100' : 'text-lightBlue-100'}
          title={'NAV beállítás'}
          text={
            'Egyszer kell beállítani, azért, hogy az elmúlt időszak adatait elemezni tudja. Az egységesség végett szöveg.'
          }
          onClick={handleTileClicked(APP_ROUTES.NAV_MANAGEMENT)}
        />
      ) : null}
      <Tile
        icon={'categories'}
        className="text-lightBlue-100"
        title={'Kategorizálás'}
        text={
          'Adja meg, hogy melyik partner és melyik cikk milyen kategóriába tartozik. Használhat egyedi kategóriát is.'
        }
        onClick={handleTileClicked(APP_ROUTES.CATEGORIES)}
      />
      <Tile
        icon={'pricechanges'}
        className="text-lightBlue-100"
        title={'Árváltozások figyelése'}
        text={
          'A kategória kialakítás után máris elemezheti, hogy melyik cikknek vagy partnernek hogyan változott az ára.'
        }
        onClick={handleTileClicked(APP_ROUTES.PRICECHANGES)}
      />
    </div>
  );
};

export default SubscribedHomePage;
