export const API_CACHE = {
  USER: 'user',
  ORG: 'org',
  ALL_ORGS: 'allOrgs',
  USERS: 'users',
  TAGS: 'tags',
  SUBSCRIPTIONS: 'subscriptions',
  INVOICES: 'invoices',
  REFERRALS: 'referrals',
  PARTNERS: 'partners',
  ACTIVE_SUBSCRIPTION: 'activeSubscription',
  PRICE_CHANGES_BY_PRODUCT: 'priceChangesByProduct',
  PACKAGES: 'packages',
  ORG_BY_TAXNUM: 'orgByTaxnum',
  CURRENT_USER: 'currentUser',
  EXTRAS: 'extras'
} as const;
export type ApiCache = typeof API_CACHE;
