import useActivationPage from 'components/pages/ActivationPage/useActivationPage';
import Box from 'components/ui/Box/Box';
import LoadingIndicator from 'components/ui/LoadingIndicator/LoadingIndicator';
import PageTitle from 'components/ui/PageTitle/PageTitle';

const ActivationPage = () => {
  const { isLoading, isError } = useActivationPage();

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Box>
      <PageTitle>
        {isError ? 'Email cím aktiválása sikertelen' : 'Email cím aktiválva'}
      </PageTitle>
    </Box>
  );
};

export default ActivationPage;
