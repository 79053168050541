import { IPartner } from 'api/partners';
import { IProduct } from 'api/products';
import useCategoryFilter, {
  UNCATEGORIZED_VALUE
} from 'components/pages/CategorizationPage/components/CategoryFilters/useCategoryFilter';
import Select, { SelectOptions } from 'components/ui/Form/controls/Select/Select';
import TextInput from 'components/ui/Form/controls/TextInput/TextInput';
import Form from 'components/ui/Form/Form';

interface IProps<T> {
  data: T;
  onChange: (filteredData: T) => void;
  categoryOptions: SelectOptions<string>[];
}
export type PartnerOrProduct = IPartner | IProduct;

const CategoryFilters = <T extends PartnerOrProduct[]>({
  data,
  onChange,
  categoryOptions
}: IProps<T>) => {
  const { formMethods } = useCategoryFilter<T>({ data, onChange });

  if (!data.length) {
    return null;
  }

  return (
    <Form formMethods={formMethods} onSubmit={() => false}>
      <div className="sm:flex">
        <TextInput name="name" label="Keresés névre" className="sm:mr-4" />
        <Select
          name="tags"
          label="Keresés kategóriára"
          options={[
            { label: 'Kategorizálatlan', value: UNCATEGORIZED_VALUE },
            ...categoryOptions
          ]}
          isMulti
          className="flex-1"
        />
      </div>
    </Form>
  );
};

export default CategoryFilters;
