import { mergeClassNames } from 'utils/helpers';

interface IProps {
  children: React.ReactNode;
  className?: string;
}

const Box = ({ children, className }: IProps) => (
  <div className={mergeClassNames(className, 'bg-white p-4 md:p-8 rounded-lg mb-4')}>
    {children}
  </div>
);

export default Box;
