import PageTitle from 'components/ui/PageTitle/PageTitle';
import Form from 'components/ui/Form/Form';
import TextInput from 'components/ui/Form/controls/TextInput/TextInput';
import Button from 'components/ui/Button/Button';
import Box from 'components/ui/Box/Box';
import useNavPage from 'components/pages/NavPage/useNavPage';

const NavPage = () => {
  const { formMethods, onSubmit } = useNavPage();

  return (
    <>
      <PageTitle>NAV Online kapcsolódás</PageTitle>
      <Box>
        <Form formMethods={formMethods} onSubmit={onSubmit}>
          <TextInput name={'navUser'} label={'Technikai felhasználó'} />
          <TextInput name={'navPassword'} label={'Technikai jelszó'} />
          <TextInput name={'navSignKey'} label={'XML aláíró kulcs'} />
          <TextInput name={'navSharedKey'} label={'XML cserekulcs'} />
          <Button color="lightBlue" type="submit" className="md:w-24 mt-4">
            Mentés
          </Button>
        </Form>
      </Box>
    </>
  );
};

export default NavPage;
