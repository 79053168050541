import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { API_CACHE } from 'constants/cache';
import { API_ROUTES } from 'constants/routes';
import { useClient } from 'contexts/auth-context';
import { OrgList } from 'contexts/org-context';
import { IOrganization } from 'types/organization';
import { decodeToken } from 'utils/jwt';
import { client as customClient } from './api-client';

const useGetOrganization = (token: string | null) => {
  const parsed = token ? decodeToken(token) : null;

  return useQuery(
    [API_CACHE.ORG, { token }],
    () =>
      customClient<IOrganization>(API_ROUTES.GET_ORG, {
        method: 'get',
        token: token as string
      }),
    {
      enabled: !!parsed?.orgId && parsed.orgId !== 0
    }
  );
};

const useGetAllOrganization = () => {
  const client = useClient();

  return useQuery(
    [API_CACHE.ALL_ORGS],
    () => client<OrgList>(API_ROUTES.GET_ALL_ORGS, { method: 'get' }),
    {
      enabled: false
    }
  );
};

const useUpdateOrganization = () => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation<IOrganization, unknown, IOrganization>(
    (request) => client(API_ROUTES.UPDATE_ORG, { data: request, method: 'patch' }),
    {
      onSuccess: () => queryClient.invalidateQueries([API_CACHE.ORG])
    }
  );
};

const useSelectOrganization = () => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    (orgId: number) =>
      client<{ token: string }>(`${API_ROUTES.SELECT_ORG}/${orgId}`, { method: 'post' }),
    {
      onSuccess: () => queryClient.invalidateQueries([API_CACHE.ACTIVE_SUBSCRIPTION])
    }
  );
};

export {
  useGetOrganization,
  useUpdateOrganization,
  useGetAllOrganization,
  useSelectOrganization
};
