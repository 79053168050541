import { useState, useEffect } from 'react';
import { useDebounce } from 'use-debounce';

interface ISize {
  width: number | undefined;
  height: number | undefined;
}

/**
 * Returns width & height of screen
 */
export function useWindowSize(): ISize {
  const [windowSize, setWindowSize] = useState<ISize>({
    width: undefined,
    height: undefined
  });
  const debouncedSizes = useDebounce(windowSize, 200);
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return debouncedSizes[0];
}
