import { API_ROUTES } from 'constants/routes';
import { useClient } from 'contexts/auth-context';
import { format } from 'date-fns';
import { useMutation } from '@tanstack/react-query';

export interface IProduct {
  id: 0;
  code: string;
  name: string;
  unit: string;
  tags: string[];
}

export interface IUseGetPartnerRangeProductsRequest {
  partnerId: number;
  from: Date;
  to: Date;
}

const useGetPartnerRangeProducts = () => {
  const client = useClient();

  return useMutation(({ partnerId, from, to }: IUseGetPartnerRangeProductsRequest) => {
    const fromString = format(from, 'yyyy-MM-dd');
    const toString = format(to, 'yyyy-MM-dd');

    return client<IProduct[], { partner: number; from: string; to: string }>(
      API_ROUTES.GET_PARTNER_RANGE_PRODUCTS,
      {
        method: 'post',
        data: {
          partner: partnerId,
          from: fromString,
          to: toString
        }
      }
    );
  });
};

export { useGetPartnerRangeProducts };
