import { IconProp } from 'constants/icons';

const EditIcon = (props: IconProp) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 19 18" {...props}>
    <path
      fill="currentColor"
      d="m11.06 6.02.92.92L2.92 16H2v-.92l9.06-9.06ZM14.66 0c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83a.996.996 0 0 0 0-1.41L15.37.29c-.2-.2-.45-.29-.71-.29Zm-3.6 3.19L0 14.25V18h3.75L14.81 6.94l-3.75-3.75Z"
    />
  </svg>
);

export default EditIcon;
