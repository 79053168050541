import { yupResolver } from '@hookform/resolvers/yup';
import { useGetInvoices, useImportInvoices } from 'api/nav';
import { FormOnSubmit, WithFormError } from 'components/ui/Form/Form';
import { VALIDATION_MESSAGES } from 'constants/validation';
import { useNotification } from 'contexts/notification-context';
import { useOrg } from 'contexts/org-context';
import { differenceInDays, endOfMonth, startOfMonth, sub } from 'date-fns';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

export interface IInvoicesFormValues {
  from: Date;
  to: Date;
}

const validationSchema = Yup.object().shape({
  from: Yup.date().nullable().required(VALIDATION_MESSAGES.required),
  to: Yup.date()
    .nullable()
    .required(VALIDATION_MESSAGES.required)
    .test(
      'max_end_date',
      'Maximum 35 napot lehet egyszerre lekérdezni',
      function (value) {
        const { from } = this.parent;
        if (from instanceof Date && !!value) {
          return Math.abs(differenceInDays(from, value)) <= 35;
        }
        return true;
      }
    )
});

const useInvoicesPage = () => {
  const { addNotification } = useNotification();
  const { org, hasValidSubscription } = useOrg();
  const formMethods = useForm<WithFormError<IInvoicesFormValues>>({
    defaultValues: {
      from: sub(startOfMonth(new Date()), { months: 0 }),
      to: new Date()
    },
    resolver: yupResolver(validationSchema)
  });
  const { mutate: importInvoices, isLoading: isImportInvoicesLoading } =
    useImportInvoices();
  const { data, isLoading } = useGetInvoices(org.id);
  const { setValue, watch } = formMethods;

  const from = watch('from');

  useEffect(() => {
    const endOfMonthOfFrom = endOfMonth(new Date(from));
    setValue('to', endOfMonthOfFrom < new Date() ? endOfMonthOfFrom : new Date());
  }, [from, setValue]);

  const onSubmit: FormOnSubmit<IInvoicesFormValues> = (values) => {
    importInvoices(
      {
        ...values
      },
      {
        onSuccess: () =>
          addNotification({
            type: 'success',
            message: 'A számlaletöltési folyamat sikeresen elindult!'
          }),
        onError: () =>
          addNotification({
            type: 'error',
            message: 'A számlaletöltési folyamat elindítása sikertelen!'
          })
      }
    );
  };

  return {
    data: data || [],
    isLoading,
    formMethods,
    onSubmit,
    isImportInvoicesLoading,
    hasNavConnection: org.navActive,
    hasValidSubscription
  };
};

export default useInvoicesPage;
