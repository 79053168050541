import React from 'react';
import {
  FieldError,
  FormProvider,
  Path,
  UnpackNestedValue,
  UseFormReturn,
  UseFormSetError
} from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { getDeepValue, mergeClassNames } from 'utils/helpers';

export interface IDefaultControlProps {
  name: string;
  label?: React.ReactNode;
  hasError?: boolean;
  placeholder?: string;
  className?: string;
  info?: string;
  disabled?: boolean;
  rounded?: boolean;
  autoComplete?: string;
  group?: boolean;
  defaultValue?: any;
}

export type WithFormError<T> = T & { formError: string };

export type FormOnSubmit<T> = (
  data: UnpackNestedValue<T>,
  setError: UseFormSetError<WithFormError<T>>
) => void;

export const isFieldError = (error: unknown): error is FieldError =>
  typeof error === 'object' &&
  error !== null &&
  typeof (error as Record<string, unknown>).message === 'string';

export type FormErrorsWithCustomError<T> = T & { formError?: FieldError };

interface IFormProps<T> {
  formMethods: UseFormReturn<WithFormError<T>>;
  onSubmit: FormOnSubmit<T>;
  children: React.ReactNode | React.ReactNode[];
}

const Form = <T,>({ children, onSubmit, formMethods }: IFormProps<T>) => {
  const { handleSubmit, setError, clearErrors, formState } = formMethods;
  const formError = (getDeepValue(formState.errors, 'formError') as any)?.message;

  const handleFormChange = () => {
    if (formError) {
      clearErrors('formError' as Path<WithFormError<T>>); // sort of hack, but react-hook-form using string template literals internally...
    }
  };

  const handleFormSubmit = (values: UnpackNestedValue<T>) => {
    onSubmit(values, setError);
  };

  return (
    <FormProvider<WithFormError<T>> {...formMethods}>
      <form onSubmit={handleSubmit(handleFormSubmit)} onChange={handleFormChange}>
        {children}
        {formError ? (
          <div className="px-6 py-2 bg-red-100 border border-red-600 text-red-600 text-center rounded my-2">
            {formError}
          </div>
        ) : null}
      </form>
      <DevTool control={formMethods.control} />
    </FormProvider>
  );
};

interface IFormGroupProps {
  children: React.ReactNode;
}

const FormGroup = ({ children }: IFormGroupProps) => (
  <div className="flex flex-wrap -mx-1.5">
    {React.Children.map(children, (child) =>
      React.isValidElement(child)
        ? React.cloneElement(child, {
            className: mergeClassNames(child.props?.className, 'px-1.5'),
            group: true
          })
        : child
    )}
  </div>
);

Form.Group = FormGroup;
export default Form;
