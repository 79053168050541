import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import LoginPage from 'components/pages/LoginPage/LoginPage';
import { APP_ROUTES } from 'constants/routes';
import PartnerExportPage from 'components/pages/PartnerExportPage/PartnerExportPage';
import CikkExportPage from 'components/pages/CikkExportPage/CikkExportPage';
import RegisterPage from 'components/pages/RegisterPage/RegisterPage';
import AdminPage from 'components/pages/AdminPage/AdminPage';
import UsersPage from 'components/pages/UsersPage/UsersPage';
import HomePage from 'components/pages/HomePage/HomePage';
import AffiliationPage from 'components/pages/AffiliationPage/AffiliationPage';
import NavPage from 'components/pages/NavPage/NavPage';
import ProfilePage from 'components/pages/ProfilePage/ProfilePage';
import OrgPage from 'components/pages/OrgPage/OrgPage';
import UserForm from 'components/pages/UsersPage/UserForm/UserForm';
import CategorizationPage from 'components/pages/CategorizationPage/CategorizationPage';
import PriceChangesPage from 'components/pages/PriceChangesPage/PriceChangesPage';
import SubscriptionPage from 'components/pages/SubscriptionPage/SubscriptionPage';
import InvoicesPage from 'components/pages/InvoicesPage/InvoicesPage';
import ChangePasswordPage from 'components/pages/ChangePasswordPage/ChangePasswordPage';
import ActivationPage from 'components/pages/ActivationPage/ActivationPage';
import ReactGA from 'react-ga4';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS || '');

const UnauthorizedLayout = React.lazy(
  () => import('./components/layout/UnauthorizedLayout/UnauthorizedLayout')
);
const AuthorizedLayout = React.lazy(
  () => import('./components/layout/AuthorizedLayout/AuthorizedLayout')
);

const App = () => (
  <Routes>
    <Route path={APP_ROUTES.UNAUTHENTICATED_ROOT} element={<UnauthorizedLayout />}>
      <Route path={APP_ROUTES.LOGIN} element={<LoginPage />} />
      <Route path={APP_ROUTES.REGISTER} element={<RegisterPage />} />
      <Route path={APP_ROUTES.CHANGE_PASSWORD} element={<ChangePasswordPage />} />
      <Route path={APP_ROUTES.EMAIL_ACTIVATION} element={<ActivationPage />} />
    </Route>
    <Route path={APP_ROUTES.AUTHENTICATED_ROOT} element={<AuthorizedLayout />}>
      <Route index element={<HomePage />} />
      <Route path={APP_ROUTES.ADMIN} element={<AdminPage />} />
      <Route path={APP_ROUTES.INVOICES} element={<InvoicesPage />} />
      <Route path={APP_ROUTES.CATEGORIES} element={<CategorizationPage />} />
      <Route path={APP_ROUTES.PRICECHANGES} element={<PriceChangesPage />} />
      <Route path={APP_ROUTES.NAV_MANAGEMENT} element={<NavPage />} />
      <Route path={APP_ROUTES.ORG_MANAGEMENT} element={<OrgPage />} />
      <Route path={APP_ROUTES.PROFILE_MANAGEMENT} element={<ProfilePage />} />
      <Route path={APP_ROUTES.SUBSCRIPTION_MANAGEMENT} element={<SubscriptionPage />} />
      <Route path={APP_ROUTES.USER_MANAGEMENT}>
        <Route index element={<UsersPage />} />
        <Route path={APP_ROUTES.USER_MANAGEMENT_ADD} element={<UserForm />} />
        <Route path={APP_ROUTES.USER_MANAGEMENT_EDIT} element={<UserForm />} />
      </Route>
      <Route path={APP_ROUTES.CIKK_EXPORT} element={<CikkExportPage />} />
      <Route path={APP_ROUTES.PARTNER_EXPORT} element={<PartnerExportPage />} />
      <Route path={APP_ROUTES.AFFILIATION} element={<AffiliationPage />} />
    </Route>
    <Route path="*" element={<Navigate to={APP_ROUTES.LOGIN} />} />
  </Routes>
);

export default App;
