export const APP_ROUTES = {
  UNAUTHENTICATED_ROOT: '/auth',
  LOGIN: '/auth/login',
  REGISTER: '/auth/register',
  REGISTER_TRIAL: '/auth/register-trial',
  CHANGE_PASSWORD: '/auth/change-password',
  AUTHENTICATED_ROOT: '/',
  INVOICES: '/invoices',
  CATEGORIES: '/categories',
  PRICECHANGES: '/pricechanges',
  SETTINGS: '/settings',
  NAV_MANAGEMENT: '/settings/nav',
  ORG_MANAGEMENT: '/settings/org',
  PROFILE_MANAGEMENT: '/settings/profile',
  SUBSCRIPTION_MANAGEMENT: '/settings/subscription',
  USER_MANAGEMENT: '/settings/users',
  USER_MANAGEMENT_ADD: '/settings/users/add',
  USER_MANAGEMENT_EDIT: '/settings/users/edit/:userId',
  EXPORT: '/export',
  CIKK_EXPORT: '/export/cikk',
  PARTNER_EXPORT: '/export/partner',
  AFFILIATION: '/affiliation',
  ADMIN: '/admin',
  EMAIL_ACTIVATION: '/auth/activate/:token'
} as const;
export type AppRouteKeys = keyof typeof APP_ROUTES;
export type AppRoutes = typeof APP_ROUTES[AppRouteKeys];

export const API_ROUTES = {
  CREATE_ACCOUNT: '/accounts/registration',
  LOGIN: '/accounts/login',
  LOST_PASSWORD: '/accounts/lost-pass',
  SET_NEW_PASSWORD: '/accounts/ch-pass',
  ACTIVATE_ACCOUNT: '/accounts/activate',

  GET_USERS_LIST: '/admin/users/list',
  GET_USER_BY_ID: '/admin/users/get/:id',
  CREATE_USERS: '/admin/users/create',
  DELETE_USERS: '/admin/users/delete/',
  UPDATE_USERS: '/admin/users/update',

  GET_SUBSCRIPTIONS_LIST: '/admin/subscriptions/list',
  UPDATE_SUBSCRIPTIONS: '/admin/subscriptions/update',

  GET_TAGS_LIST: '/admin/tags/list',
  ADD_PARTNER_TAG: '/admin/tags/add-partner-tag',
  REMOVE_PARTNER_TAG: '/admin/tags/remove-partner-tag',
  REMOVE_ALL_PARTNER_TAG: '/admin/tags/remove-partner-all-tag',
  ADD_PRODUCT_TAG: '/admin/tags/add-product-tag',
  REMOVE_PRODUCT_TAG: '/admin/tags/remove-product-tag',
  REMOVE_ALL_PRODUCT_TAG: '/admin/tags/remove-product-all-tag',

  GET_ORG: '/admin/orgs/get',
  GET_ALL_ORGS: '/accounts/org-select-list',
  SELECT_ORG: '/accounts/select-org',
  UPDATE_ORG: '/admin/orgs/update',

  GET_REFFERALS_LIST: '/admin/referrals/list',

  GET_PARTNERS_LIST: '/admin/partners/list',
  GET_PARTNERS_LIST_RANGE: '/admin/partners/list_range',

  GET_PARTNER_RANGE_PRODUCTS: '/admin/products/list',

  GET_ACTIVE_SUBSCRIPTION: '/admin/subscriptions/active',
  UPGRADE_SUBSCRIPTION: '/admin/subscriptions/upgrade',
  DELETE_SUBSCRIPTION: '/admin/subscriptions/delete',
  RENEW_SUBSCRIPTION: '/admin/subscriptions/renew',
  REGISTER_SUBSCRIPTION: '/admin/subscriptions/register',
  REMOVE_RECURRING: '/admin/subscriptions/delete-recurring',

  GET_NAV_LIST: '/nav/list',
  IMPORT_NAV_ASYNC: '/nav/import-invoices-async',
  GET_ORG_BY_TAXNUM: '/nav/query',

  PRICE_CHANGES_BY_PRODUCT: '/admin/reports/table-1',
  PRICE_CHANGES_BAR_CHART: '/admin/reports/bar-1',
  PRICE_CHANGES_PIE_CHART: '/admin/reports/pie-1',

  GET_PACKAGES: '/packs/list',
  GET_EXTRAS: '/packs/list-extras',

  UPDATE_PROFILE: '/user/update-profile',
  GET_CURRENT_USER: '/user/get',
  PARTNERS_EXPORT: '/admin/partners/export',
  PARTNERS_PRINT: '/admin/partners/print',

  PRODUCTS_EXPORT: '/admin/products/export',
  PRODUCTS_PRINT: '/admin/products/print'
} as const;
export type ApiRouteKeys = keyof typeof API_ROUTES;
export type ApiRoutes = typeof API_ROUTES[ApiRouteKeys];
