import PageTitle from 'components/ui/PageTitle/PageTitle';
import useAffiliationPage from 'components/pages/AffiliationPage/useAffiliationPage';
import Form from 'components/ui/Form/Form';
import TextInput from 'components/ui/Form/controls/TextInput/TextInput';
import Button from 'components/ui/Button/Button';
import SectionTitle from 'components/ui/SectionTitle/SectionTitle';
import Table from 'components/ui/Table/Table';
import { useMemo } from 'react';
import { Column } from 'react-table';
import { IReferralListItem } from 'api/referrals';
import Box from 'components/ui/Box/Box';

const AffiliationPage = () => {
  const { data, isLoading, formMethods, onSubmit } = useAffiliationPage();

  const columns = useMemo<Column<IReferralListItem>[]>(
    () => [
      {
        accessor: 'refOrgs',
        Header: 'Cégnév'
      },
      {
        accessor: 'id',
        Header: 'Cím'
      },
      {
        accessor: 'affiliateCode',
        Header: 'Adószám'
      },
      {
        accessor: 'status',
        Header: 'Státusz'
      }
    ],
    []
  );

  return (
    <>
      <PageTitle>Ajánló levél kiküldése</PageTitle>
      <Box>
        <Form formMethods={formMethods} onSubmit={onSubmit}>
          <TextInput name={'name'} label={'Címzett neve'} />
          <TextInput name={'email'} label={'Címzett e-mail címe'} />
          <TextInput name={'code'} label={'Ajánlói kód'} />
          <TextInput name={'msg'} label={'Üzenet'} />
        </Form>
        <div className="flex space-x-4 mt-6">
          <Button color="lightBlue" type="submit" className="mt-4">
            Küldés
          </Button>
          <Button color="lightBlue" type="submit" className="mt-4">
            Link másolása vágólapra
          </Button>
        </div>
      </Box>
      <Box>
        <SectionTitle>Beregisztrált ajánlások</SectionTitle>
        <Table columns={columns} data={data} isLoading={isLoading} />
      </Box>
    </>
  );
};

export default AffiliationPage;
