import { useClient } from 'contexts/auth-context';
import { useQuery } from '@tanstack/react-query';
import { API_ROUTES } from 'constants/routes';
import { API_CACHE } from 'constants/cache';

export interface IRefOrg {
  id: number;
  name: string;
}

export interface IReferralListItem {
  id: number;
  affiliateCode: string;
  status: 'SENT' | 'REGISTERED' | 'PAYED' | 'DELETED';
  refOrgs: IRefOrg;
}

const useGetReferrals = () => {
  const client = useClient();

  return useQuery([API_CACHE.REFERRALS], () =>
    client<IReferralListItem[]>(API_ROUTES.GET_REFFERALS_LIST, { method: 'get' })
  );
};

export { useGetReferrals };
