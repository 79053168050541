import { IBaseFilters } from 'api/price-changes';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions
} from 'chart.js';
import usePriceChangeBarChart from 'components/pages/PriceChangesPage/components/PriceChangeBarChart/usePriceChangeBarChart';
import RadioButton from 'components/ui/Form/controls/RadioButton/RadioButton';
import Form from 'components/ui/Form/Form';
import LoadingIndicator from 'components/ui/LoadingIndicator/LoadingIndicator';
import SectionTitle from 'components/ui/SectionTitle/SectionTitle';
import { Bar } from 'react-chartjs-2';
import { formatNumberToCurrency } from 'utils/helpers';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const getOptions = (type: 'value' | 'percent'): ChartOptions<'bar'> => ({
  responsive: true,
  plugins: {
    legend: {
      display: false
    },
    title: {
      display: false
    },
    tooltip: {
      callbacks: {
        label: (item) =>
          type === 'value' ? formatNumberToCurrency(item.raw as number) : `${item.raw}%`
      }
    }
  },
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: false
      }
    },
    y: {
      ticks: {
        callback: (value, index, tick) =>
          type === 'value' ? formatNumberToCurrency(value) : `${value}%`
      }
    }
  }
});

interface IProps {
  filters: IBaseFilters;
}

const PriceChangeBarChart = ({ filters }: IProps) => {
  const { chartData, isLoading, formMethods, type } = usePriceChangeBarChart({ filters });
  return (
    <>
      <div className="flex justify-between">
        <SectionTitle>Kimutatás 1</SectionTitle>
        <Form onSubmit={() => false} formMethods={formMethods}>
          <div className="flex">
            <RadioButton name="type" value="value" label="Érték" />
            <RadioButton name="type" value="percent" label="Százalék" />
          </div>
        </Form>
      </div>
      <div className="overflow-auto">
        <div className="min-w-[800px]">
          {isLoading ? (
            <div className="flex justify-center items-center">
              <LoadingIndicator />
              <p>A kimutatás megjelenítése folyamatban.</p>
            </div>
          ) : (
            <Bar options={getOptions(type)} data={chartData} height={400} />
          )}
        </div>
      </div>
    </>
  );
};

export default PriceChangeBarChart;
