import Notification, { INotification } from 'components/ui/Notification/Notification';
import { createPortal } from 'react-dom';

export interface INotificationContainerProps {
  notifications: INotification[];
}

const NotificationContainer = ({ notifications }: INotificationContainerProps) =>
  createPortal(
    <div className="absolute top-0 right-0 text-white">
      {notifications.map((n) => (
        <Notification key={n.id} data={n} />
      ))}
    </div>,
    document.body
  );

export default NotificationContainer;
