export const LOCAL_STORAGE_KEYS = {
  LOCAL_STORAGE_AUTH_KEY: 'onktg_token',
  REFRESH_TOKEN: 'onktg_refresh_token'
} as const;
export type LocalStorageKeys = typeof LOCAL_STORAGE_KEYS[keyof typeof LOCAL_STORAGE_KEYS];

export const setItemToStorage = (key: LocalStorageKeys, item: unknown) => {
  window.localStorage.setItem(key, JSON.stringify(item));
};

export const getItemFromStorage = <T>(key: LocalStorageKeys): T | null => {
  const value = window.localStorage.getItem(key);

  return value === 'undefined' || value === null ? null : JSON.parse(value);
};

export const removeItemFromStorage = (key: LocalStorageKeys) =>
  window.localStorage.removeItem(key);
