import { IBaseFilters } from 'api/price-changes';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartOptions } from 'chart.js';
import usePriceChangePieChart from 'components/pages/PriceChangesPage/components/PriceChangePieChart/usePriceChangePieChart';
import RadioButton from 'components/ui/Form/controls/RadioButton/RadioButton';
import Form from 'components/ui/Form/Form';
import LoadingIndicator from 'components/ui/LoadingIndicator/LoadingIndicator';
import SectionTitle from 'components/ui/SectionTitle/SectionTitle';
import { Pie } from 'react-chartjs-2';
import { formatNumberToCurrency } from 'utils/helpers';

ChartJS.register(ArcElement, Tooltip, Legend);

export const getOptions = (type: 'value' | 'percent'): ChartOptions<'pie'> => ({
  responsive: true,
  plugins: {
    title: {
      display: true
    },
    tooltip: {
      callbacks: {
        label: (item) =>
          type === 'value' ? formatNumberToCurrency(item.raw as number) : `${item.raw}%`,
        title: (item) => item[0].label || ''
      }
    },
    legend: {
      position: 'left'
    }
  },
  maintainAspectRatio: false
});

interface IProps {
  filters: IBaseFilters;
}

const PriceChangePieChart = ({ filters }: IProps) => {
  const { chartData, isLoading, formMethods, type } = usePriceChangePieChart({ filters });

  return (
    <>
      <div className="flex justify-between">
        <SectionTitle>Kimutatás 2</SectionTitle>
        <Form onSubmit={() => false} formMethods={formMethods}>
          <div className="flex">
            <RadioButton name="type" value="value" label="Érték" />
            <RadioButton name="type" value="percent" label="Százalék" />
          </div>
        </Form>
      </div>
      <div className="overflow-auto">
        <div className="min-w-[800px]">
          {isLoading ? (
            <div className="flex justify-center items-center">
              <LoadingIndicator />
              <p>A kimutatás megjelenítése folyamatban.</p>
            </div>
          ) : (
            <Pie data={chartData} options={getOptions(type)} height={400} />
          )}
        </div>
      </div>
    </>
  );
};

export default PriceChangePieChart;
