import NumberInput from 'components/ui/Form/controls/NumberInput/NumberInput';
import TextInput from 'components/ui/Form/controls/TextInput/TextInput';
import Form from 'components/ui/Form/Form';
import PageTitle from 'components/ui/PageTitle/PageTitle';

const AddressForm = () => (
  <>
    <PageTitle>Cím adatok</PageTitle>
    <Form.Group>
      <NumberInput name="org.zip" label="Irányítószám" className="w-full md:w-4/12" />
      <TextInput name="org.city" label="Város" className="w-full md:w-8/12" />
    </Form.Group>
    <Form.Group>
      <TextInput name="org.street" label="Közterület neve" className="w-full md:w-7/12" />
      <TextInput
        name="org.streetType"
        label="Közterület típusa"
        className="w-full md:w-3/12"
      />
      <TextInput name="org.houseNumber" label="Házszám" className="w-full md:w-2/12" />
    </Form.Group>
    <Form.Group>
      <TextInput name="org.building" label="Épület" className="w-full md:w-1/5" />
      <TextInput name="org.stairway" label="Lépcsőház" className="w-full md:w-1/5" />
      <TextInput name="org.floor" label="Emelet" className="w-1/2 md:w-1/5" />
      <TextInput name="org.door" label="Ajtó" className="w-1/2 md:w-1/5" />
      <TextInput
        name="org.lotNumber"
        label="Helyrajzi szám"
        className="w-full md:w-1/5"
      />
    </Form.Group>
  </>
);

export default AddressForm;
