import { useMutation } from '@tanstack/react-query';
import { IPriceChangeByProducts } from 'components/pages/PriceChangesPage/components/PriceChangeByProducts/usePriceChangeByProducts';
import { API_ROUTES } from 'constants/routes';
import { useClient } from 'contexts/auth-context';
import { formatDateForApi } from 'utils/dates';

export interface IBaseFilters {
  from: Date | null;
  to: Date | null;
  tags: string[];
  price: 'GROSS' | 'NET';
}

export interface IProductsFilters extends IBaseFilters {
  type: 'INVOICE' | 'DAILY' | 'WEEKLY' | 'MONTHLY' | 'QUARTERLY' | 'YEARLY';
}

const useGetPriceChangesByProduct = () => {
  const client = useClient();

  return useMutation<IPriceChangeByProducts[], unknown, IProductsFilters>(
    (filters) =>
      client(API_ROUTES.PRICE_CHANGES_BY_PRODUCT, {
        method: 'post',
        data: {
          ...filters,
          from: formatDateForApi(filters.from),
          to: formatDateForApi(filters.to)
        }
      }),
    {}
  );
};

export type BarChartData = { name: string; value: number; percent: number }[];

const useGetPriceChangesBarChartData = () => {
  const client = useClient();

  return useMutation<BarChartData, unknown, IBaseFilters>(
    (filters) =>
      client(API_ROUTES.PRICE_CHANGES_BAR_CHART, {
        method: 'post',
        data: {
          ...filters,
          from: formatDateForApi(filters.from),
          to: formatDateForApi(filters.to)
        }
      }),
    {}
  );
};

export type PieChartData = { name: string; value: number; percent: number }[];

const useGetPriceChangesPieChartData = () => {
  const client = useClient();

  return useMutation<BarChartData, unknown, IBaseFilters>(
    (filters) =>
      client(API_ROUTES.PRICE_CHANGES_PIE_CHART, {
        method: 'post',
        data: {
          ...filters,
          from: formatDateForApi(filters.from),
          to: formatDateForApi(filters.to)
        }
      }),
    {}
  );
};

export {
  useGetPriceChangesByProduct,
  useGetPriceChangesBarChartData,
  useGetPriceChangesPieChartData
};
