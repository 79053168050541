import React from 'react';

interface IProps {
  children: React.ReactNode;
}

const SectionTitle = ({ children }: IProps) => (
  <h2 className="text-xl md:text-2xl font-bold text-darkBlue-100 my-2">{children}</h2>
);

export default SectionTitle;
