import useTrialHomePage from 'components/pages/HomePage/components/TrialHomePage/useTrialHomePage';
import Box from 'components/ui/Box/Box';
import Button from 'components/ui/Button/Button';
import NumberInput from 'components/ui/Form/controls/NumberInput/NumberInput';
import TextInput from 'components/ui/Form/controls/TextInput/TextInput';
import Form from 'components/ui/Form/Form';
import PageTitle from 'components/ui/PageTitle/PageTitle';

const TrialHomePage = () => {
  const { formMethods, isLoading, onSubmit } = useTrialHomePage();
  return (
    <>
      <PageTitle>Cég és NAV adatok</PageTitle>
      <Box>
        <Form formMethods={formMethods} onSubmit={onSubmit}>
          <NumberInput name="taxNumber" label="Adószám" format="########-#-##" mask="_" />
          <Form.Group>
            <TextInput name={'name'} label={'Cégnév'} className="w-8/12" />
            <TextInput name={'shortName'} label={'Adózó rövid neve'} className="w-4/12" />
          </Form.Group>
          <Form.Group>
            <NumberInput name="zip" label="Irányítószám" className="w-full md:w-4/12" />
            <TextInput name="city" label="Város" className="w-full md:w-8/12" />
          </Form.Group>
          <Form.Group>
            <TextInput
              name="street"
              label="Közterület neve"
              className="w-full md:w-7/12"
            />
            <TextInput
              name="streetType"
              label="Közterület típusa"
              className="w-1/2 md:w-3/12"
            />
            <TextInput name="houseNumber" label="Házszám" className="w-1/2 md:w-2/12" />
          </Form.Group>
          <Form.Group>
            <TextInput name="building" label="Épület" className="w-1/2 md:w-1/5" />
            <TextInput name="stairway" label="Lépcsőház" className="w-1/2 md:w-1/5" />
            <TextInput name="floor" label="Emelet" className="w-1/2 md:w-1/5" />
            <TextInput name="door" label="Ajtó" className="w-1/2 md:w-1/5" />
            <TextInput
              name="lotNumber"
              label="Helyrajzi szám"
              className="w-1/2 md:w-1/5"
            />
          </Form.Group>
          <TextInput name={'email'} label={'E-mail cím'} />
          <Form.Group>
            <TextInput
              name={'navUser'}
              label={'NAV technikai felhasználó'}
              className="w-1/2"
            />
            <TextInput
              name={'navPassword'}
              label={'NAV technikai jelszó'}
              className="w-1/2"
            />
          </Form.Group>
          <Form.Group>
            <TextInput
              name={'navSignKey'}
              label={'NAV XML aláíró kulcs'}
              className="w-1/2"
            />
            <TextInput
              name={'navSharedKey'}
              label={'NAV XML cserekulcs'}
              className="w-1/2"
            />
          </Form.Group>
          <Button
            color="lightBlue"
            type="submit"
            className="w-24 mt-4"
            loading={isLoading}
            disabled={Object.keys(formMethods.formState.errors).length > 0}
          >
            Mentés
          </Button>
        </Form>
      </Box>
    </>
  );
};

export default TrialHomePage;
