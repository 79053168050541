import * as Yup from 'yup';
import { FormOnSubmit, WithFormError } from 'components/ui/Form/Form';
import { VALIDATION_MESSAGES } from 'constants/validation';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLogin, useLostPassword } from 'api/accounts';
import { useAuth } from 'contexts/auth-context';
import { useWindowSize } from 'hooks/useWindowSize';
import { useNotification } from 'contexts/notification-context';

export interface ILoginForm {
  email: string;
  password: string;
}

const validationSchema = Yup.object().shape({
  password: Yup.string().required(VALIDATION_MESSAGES.required),
  email: Yup.string()
    .email(VALIDATION_MESSAGES.invalid_email)
    .required(VALIDATION_MESSAGES.required)
});

const useLoginPage = () => {
  const formMethods = useForm<WithFormError<ILoginForm>>({
    defaultValues: {
      email: '',
      password: ''
    },
    resolver: yupResolver(validationSchema)
  });

  const { mutate: loginMutation, isLoading: isLoginLoading } = useLogin();
  const { mutate: lostPassword, isLoading: isLostPassLoading } = useLostPassword();
  const { login } = useAuth();
  const { width } = useWindowSize();
  const { addNotification } = useNotification();

  const onSubmit: FormOnSubmit<ILoginForm> = (data) => {
    loginMutation(data, {
      onSuccess: (response) => {
        login(response.token);
      }
    });
  };

  const handleLostPassword = () => {
    if (!formMethods.getValues('email')) {
      formMethods.setError('formError', { message: 'Adja meg az e-mail címét!' });
      return;
    }
    lostPassword(
      {
        email: formMethods.getValues('email')
      },
      {
        onSuccess: () =>
          addNotification({
            type: 'success',
            message:
              'Ha az e-mail cím megtalálható rendszerünkben, akkor hamarosan kapni fog egy levelet az új jelszó kéréssel kapcsolatban.'
          })
      }
    );
  };

  return {
    onSubmit,
    formMethods,
    isLoginLoading,
    width,
    handleLostPassword,
    isLostPassLoading
  };
};

export default useLoginPage;
