import React, { ReactElement, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import AppProviders from 'contexts/app-providers';
import App from './App';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as Element);

declare interface Children {
  children: ReactElement<any, any>;
}

const Wrapper = ({ children }: Children) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.getElementsByTagName('main')[0]?.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

root.render(
  <React.StrictMode>
    <AppProviders>
      <Wrapper>
        <App />
      </Wrapper>
    </AppProviders>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
