import { useMutation } from '@tanstack/react-query';
import { API_ROUTES } from 'constants/routes';
import { ExtraType, PackageType, PaymentFrequency } from 'types/subscription';
import { IOrganization } from 'types/organization';
import { client } from './api-client';

export interface ICreateAccount {
  name: string;
  email: string;
  org?: Partial<IOrganization>;
  affiliateCode?: string;
  password: string;
  passwordConfirm: string;
  subType: PackageType;
  payFreq: PaymentFrequency;
  extra: ExtraType[];
}

const useCreateAccount = () =>
  useMutation((request: ICreateAccount) =>
    client<string, ICreateAccount>(API_ROUTES.CREATE_ACCOUNT, {
      data: request,
      method: 'post'
    })
  );

export interface ILoginRequest {
  email: string;
  password: string;
}

export interface ILoginResponse {
  token: string;
}

const useLogin = () =>
  useMutation((request: ILoginRequest) =>
    client<ILoginResponse, ILoginRequest>(API_ROUTES.LOGIN, {
      data: request,
      method: 'post'
    })
  );

export interface ILostPasswordRequest {
  email: string;
}

const useLostPassword = () =>
  useMutation<unknown, unknown, ILostPasswordRequest>((request) =>
    client(API_ROUTES.LOST_PASSWORD, {
      data: request,
      method: 'post'
    })
  );

export interface IChangePasswordRequest {
  code: string;
  password: string;
}

const useChangePassword = () =>
  useMutation((request: IChangePasswordRequest) =>
    client(API_ROUTES.SET_NEW_PASSWORD, {
      data: request,
      method: 'post'
    })
  );

export interface IActivateAccountRequest {
  token: string;
}

export interface IActivateAccountResponse {
  token: string;
}

const useActivateAccount = () =>
  useMutation<IActivateAccountResponse, unknown, IActivateAccountRequest>(({ token }) =>
    client(`${API_ROUTES.ACTIVATE_ACCOUNT}/${token}`, {
      method: 'post'
    })
  );
export {
  useCreateAccount,
  useLogin,
  useChangePassword,
  useActivateAccount,
  useLostPassword
};
