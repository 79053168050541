import {
  IBaseFilters,
  PieChartData,
  useGetPriceChangesPieChartData
} from 'api/price-changes';
import { ChartData } from 'chart.js';
import { WithFormError } from 'components/ui/Form/Form';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { generateColor } from 'utils/helpers';

interface IProps {
  filters: IBaseFilters;
}

const usePriceChangePieChart = ({ filters }: IProps) => {
  const [data, setData] = useState<PieChartData>([]);
  const formMethods = useForm<WithFormError<{ type: 'value' | 'percent' }>>({
    defaultValues: {
      type: 'value'
    }
  });
  const type = formMethods.watch('type');
  const chartData = useMemo<ChartData<'pie', number[], string>>(
    () =>
      data.reduce<ChartData<'pie', number[], string>>(
        (acc, curr, i) => {
          const color = generateColor(i, data.length);
          const { name, value, percent } = curr;
          if (!acc.labels) acc.labels = [];
          acc.labels.push(name);
          acc.datasets[0].data.push(type === 'value' ? value : percent);
          (acc.datasets[0].backgroundColor as string[]).push(color);
          return acc;
        },
        {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: []
            }
          ]
        }
      ),
    [data, type]
  );

  const { mutate: getPieChartData, isLoading } = useGetPriceChangesPieChartData();

  useEffect(() => {
    getPieChartData(filters, {
      onSuccess: (response) => setData(response)
    });
  }, [filters, getPieChartData]);

  return { chartData, isLoading, type, formMethods };
};

export default usePriceChangePieChart;
