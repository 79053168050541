import PageTitle from 'components/ui/PageTitle/PageTitle';
import Form from 'components/ui/Form/Form';
import TextInput from 'components/ui/Form/controls/TextInput/TextInput';
import Button from 'components/ui/Button/Button';
import Box from 'components/ui/Box/Box';
import useOrgPage from 'components/pages/OrgPage/useOrgPage';
import NumberInput from 'components/ui/Form/controls/NumberInput/NumberInput';

const OrgPage = () => {
  const { formMethods, onSubmit, isLoading } = useOrgPage();

  return (
    <>
      <PageTitle>Cégprofil</PageTitle>
      <Box>
        <Form formMethods={formMethods} onSubmit={onSubmit}>
          <TextInput name={'taxNumber'} label={'Adószám'} disabled={true} />
          <TextInput name={'name'} label={'Adózó neve'} disabled={true} />
          <TextInput name={'shortName'} label={'Adózó rövid neve'} disabled={true} />
          <Form.Group>
            <NumberInput
              name="zip"
              label="Irányítószám"
              className="w-full md:w-4/12"
              disabled={true}
            />
            <TextInput
              name="city"
              label="Város"
              className="w-full md:w-8/12"
              disabled={true}
            />
          </Form.Group>
          <Form.Group>
            <TextInput
              name="street"
              label="Közterület neve"
              className="w-full md:w-7/12"
              disabled={true}
            />
            <TextInput
              name="streetType"
              label="Közterület típusa"
              className="w-1/2 md:w-3/12"
              disabled={true}
            />
            <TextInput
              name="houseNumber"
              label="Házszám"
              className="w-1/2 md:w-2/12"
              disabled={true}
            />
          </Form.Group>
          <Form.Group>
            <TextInput
              name="building"
              label="Épület"
              className="w-1/2 md:w-1/5"
              disabled={true}
            />
            <TextInput
              name="stairway"
              label="Lépcsőház"
              className="w-1/2 md:w-1/5"
              disabled={true}
            />
            <TextInput
              name="floor"
              label="Emelet"
              className="w-1/2 md:w-1/5"
              disabled={true}
            />
            <TextInput
              name="door"
              label="Ajtó"
              className="w-1/2 md:w-1/5"
              disabled={true}
            />
            <TextInput
              name="lotNumber"
              label="Helyrajzi szám"
              className="w-1/2 md:w-1/5"
              disabled={true}
            />
          </Form.Group>
          <TextInput name={'email'} label={'Számlázási e-mail'} />
          <Button
            color="lightBlue"
            type="submit"
            className="md:w-24 mt-4"
            loading={isLoading}
          >
            Mentés
          </Button>
        </Form>
      </Box>
    </>
  );
};

export default OrgPage;
