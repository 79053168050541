import SubscribedHomePage from 'components/pages/HomePage/components/SubscribedHomePage/SubscribedHomePage';
import TrialHomePage from 'components/pages/HomePage/components/TrialHomePage/TrialHomePage';
import useHomePage from './useHomePage';

const HomePage = () => {
  const { hasNoCompanyAndSubscription, user } = useHomePage();

  if (user?.role && user.role.includes('ADMIN') && hasNoCompanyAndSubscription) {
    return <TrialHomePage />;
  }

  return <SubscribedHomePage />;
};

export default HomePage;
