import PartnerCategorization from 'components/pages/CategorizationPage/components/PartnerCategorization/PartnerCategorization';
import ProductCategorization from 'components/pages/CategorizationPage/components/ProductCategorization/ProductCategorization';
import useCategorizationPage from 'components/pages/CategorizationPage/useCategorizationPage';
import Alert from 'components/ui/Alert/Alert';
import Box from 'components/ui/Box/Box';
import Button from 'components/ui/Button/Button';
import DateRangeFilter from 'components/ui/DateRangeFilter/DateRangeFilter';
import { DateRange } from 'components/ui/DateRangeFilter/useDateRangeFilter';
import Form from 'components/ui/Form/Form';
import PageTitle from 'components/ui/PageTitle/PageTitle';
import SectionTitle from 'components/ui/SectionTitle/SectionTitle';

const CategorizationPage = () => {
  const {
    partners,
    handleSearch,
    selectedPartner,
    handleSelectPartner,
    fetchPartnersRange,
    fetchPartnerProducts,
    options,
    products,
    formMethods,
    editSelected,
    toggleEdit,
    hasNavConnection,
    hasValidSubscription,
    minDate
  } = useCategorizationPage();

  const disabled = !hasNavConnection || !hasValidSubscription;

  return (
    <div className="flex flex-col overflow-hidden min-h-full">
      <PageTitle>Kategorizálás</PageTitle>
      {!hasValidSubscription ? (
        <Alert variant="warning">
          A funkció használatához aktív előfizetés szükséges!
        </Alert>
      ) : null}
      {hasValidSubscription && !hasNavConnection ? (
        <Alert variant="warning">
          A funkció használatához aktív NAV kapcsolat szükséges!
        </Alert>
      ) : null}
      <Box>
        <Form<{ dateRange: DateRange }> formMethods={formMethods} onSubmit={handleSearch}>
          <div className="flex items-center justify-between flex-wrap">
            <SectionTitle>Dátum</SectionTitle>
            <div className="flex items-center -mx-2 flex-wrap">
              <DateRangeFilter name="dateRange" disabled={disabled} minDate={minDate} />
              <Button
                type="submit"
                variant="default"
                color="lightBlue"
                className="mx-2"
                disabled={disabled}
              >
                Szűrés
              </Button>
            </div>
          </div>
        </Form>
      </Box>
      <div className="flex-1 lg:flex flex-wrap items-start overflow-hidden">
        <Box className="overflow-auto lg:overflow-hidden flex flex-1 lg:mr-2 flex-col max-h-[400px] lg:max-h-max">
          <SectionTitle>Partnerek</SectionTitle>
          <PartnerCategorization
            categoryOptions={options}
            handleCategoryChange={fetchPartnersRange}
            handleSelectPartner={handleSelectPartner}
            partners={partners}
            selectedPartner={selectedPartner}
            editSelected={editSelected}
            toggleEdit={toggleEdit}
          />
        </Box>
        <Box className="overflow-auto lg:overflow-hidden flex flex-1 lg:ml-2 flex-col max-h-[400px] lg:max-h-max">
          <SectionTitle>
            Cikkek {selectedPartner ? `(${selectedPartner.name})` : ''}
          </SectionTitle>
          <ProductCategorization
            categoryOptions={options}
            handleCategoryChange={fetchPartnerProducts}
            products={products}
          />
        </Box>
      </div>
    </div>
  );
};

export default CategorizationPage;
