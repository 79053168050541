import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { NotificationProvider } from 'contexts/notification-context';
import { ModalProvider } from 'contexts/modal-context';
import { OrgProvider } from 'contexts/org-context';
import { AuthProvider } from './auth-context';
import ClientProvider from './query-client-context';
import { AbilityProvider } from './ability-context';

export interface IContextProvider {
  children: React.ReactNode;
}

const AppProviders = ({ children }: IContextProvider) => (
  <React.Suspense fallback={<div>loading...</div>}>
    <NotificationProvider>
      <ClientProvider>
        <AuthProvider>
          <OrgProvider>
            <AbilityProvider>
              <ModalProvider>
                <Router>{children}</Router>
              </ModalProvider>
            </AbilityProvider>
          </OrgProvider>
        </AuthProvider>
      </ClientProvider>
    </NotificationProvider>
  </React.Suspense>
);

export default AppProviders;
